import React from 'react'
import { inject, observer } from 'mobx-react'
import StorageProvider from 'providers/storage-provider'
import { Block, mountHook } from 'uikit'
import history from 'utils/history'

const Home = ({ questionaryModel, menuModel, globalPreloaderModel }) => {
  mountHook(async () => {
    menuModel.changeStep(-1)
    globalPreloaderModel.setVisible(true)

    const orderID = StorageProvider.getSetting('cache.orderID')
    if (orderID) {
      await questionaryModel.getQuestionary(orderID, true)
    } else {
      history.push('/error_order')
    }
  })

  return <Block/>
}

export default inject(store => ({
  menuModel: store.menuModel,
  questionaryModel: store.questionaryModel,
  globalPreloaderModel: store.globalPreloaderModel
}))(observer(Home))
