import _ from 'lodash'
import React from 'react'
import { Button, Block, Alert, NavBar, Title, Col, Row, num2str, mountHook } from 'uikit'
import { inject, observer } from 'mobx-react'
import history, { orderHistory } from 'utils/history'
import PropTypes from 'prop-types'
import { OTHER_DATA_HTML_ID } from 'utils/htmlID'
import Education from './Education'
import Family from './Family'
import Employment from './Employment'
import Incomes from './Incomes'
import ContactPerson from './ContactPerson'
import KeyWord from './KeyWord'

const OtherData = ({
  isNew, menuModel, pickupModel, questionaryModel, responsiveModel, applicationModel,
  ...props
}) => {
  const {
    activeOtherPanel,

    isNextOtherDataLoading
  } = questionaryModel
  mountHook(async () => {
    menuModel.changeStep(3, null, false)

    const orderID = _.get(props, 'match.params.orderID', null)

    if (orderID) {
      if (isNew) {
        if (!questionaryModel.isNextAdditionalData) {
          history.push(orderHistory('/pickup', orderID))
        }
      } else if (!questionaryModel.id) {
        await questionaryModel.getQuestionary(orderID)
      }

      if (questionaryModel.isFill) {
        history.push(orderHistory('/credit_result'))
      }

      if (pickupModel.isCallCenterCall) {
        history.push(`/form/${orderID}/call_center`)
      }
    } else {
      history.push('/error_order')
    }
  })

  const handleChangeNav = value => {
    questionaryModel.nextOtherData(value)()
  }

  const countPositive = _.filter(applicationModel.applications, a => a.isPositive).length
  const isNegative = _.filter(applicationModel.banks, a => a.isNegative).length > applicationModel.banks.length

  let Content = null
  switch (activeOtherPanel) {
    case 'education':
      Content = <Education/>
      break
    case '2':
      Content = <Family/>
      break
    case '3':
      Content = <Employment/>
      break
    case '4':
      Content = <Incomes/>
      break
    case '5':
      Content = <ContactPerson/>
      break
    case '6':
      Content = <KeyWord/>
      break
  }

  const ContentContainer = (
    <Block
      isPaddingLeft
      paddingLeft={responsiveModel.isTabletAndPhone ? 2 : 0}
      isPaddingRight
      paddingRight={responsiveModel.isTabletAndPhone ? 2 : 0}
    >
      {responsiveModel.isPhone && (questionaryModel.otherDataErrorCount + questionaryModel.otherDataEmptyCount) &&
      questionaryModel.isExistOtherDataStep ?
        <Alert
          message="Исправьте ошибки в полях формы"
          type="error"
          isClosable
          marginBottom={2}
        /> : null
      }
      {Content}
    </Block>
  )

  return (
    <Block>
      <Block
        marginTop={!responsiveModel.isPhone ? 3 : 1}
        marginBottom={!responsiveModel.isPhone ? 2 : 0}
        onClick={countPositive > 0 || isNegative ? () => history.push(orderHistory('/credit_result')) : null}
      >
        <Alert
          message={countPositive > 0 ?
            `Вы получили ${countPositive} ${num2str(countPositive, ['одобрение', 'одобрения', 'одобрений'])}! Нажмите, чтобы посмотреть, или продолжайте заполнять анкету.` :
            isNegative ? 'К сожалению, Вам отказали.' :
              'Дополнительные данные отправлены на рассмотрение. Продолжайте заполнять анкету, мы уведомим вас о результатах.'
          }
          type={countPositive > 0 ? 'success' : isNegative ? 'error' : 'info'}
          isClosable
        />
      </Block>
      {!responsiveModel.isPhone &&
      <Title
        level={3}
        marginTop={3}
        marginBottom={!responsiveModel.isTabletAndPhone ? 2 : 0}
      >
        Прочие Сведения
      </Title>
      }
      <Block
        marginLeft={responsiveModel.isTabletAndPhone ? -2 : 0}
        marginRight={responsiveModel.isTabletAndPhone ? -2 : 0}
      >
        <NavBar
          activeKey={activeOtherPanel}
          key="other-data-nav"
          onChange={handleChangeNav}
          panelWidth={20}
          options={(() => {
            const options = [
              {
                value: 'education',
                label: 'Образование',
                isSuccess: questionaryModel.isChangeOtherDataEducation &&
                  questionaryModel.otherDataEmptyCountEducation + questionaryModel.otherDataErrorCountEducation === 0,
                isError: questionaryModel.isChangeOtherDataEducation &&
                  questionaryModel.otherDataEmptyCountEducation + questionaryModel.otherDataErrorCountEducation > 0,
                isLoading: questionaryModel.activeOtherPanel === 'education' && isNextOtherDataLoading,
                isDisable: questionaryModel.activeOtherPanel !== 'education' && isNextOtherDataLoading
              },
              {
                value: '2',
                label: 'Семья',
                isSuccess: questionaryModel.isChangeOtherDataTwo &&
                  questionaryModel.otherDataEmptyCountTwo + questionaryModel.otherDataErrorCountTwo === 0,
                isError: questionaryModel.isChangeOtherDataTwo &&
                  questionaryModel.otherDataEmptyCountTwo + questionaryModel.otherDataErrorCountTwo > 0,
                isLoading: questionaryModel.activeOtherPanel === '2' && isNextOtherDataLoading,
                isDisable: questionaryModel.activeOtherPanel !== '2' && isNextOtherDataLoading
              },
              {
                value: '3',
                label: 'Занятость',
                isSuccess: questionaryModel.isChangeOtherDataThree &&
                  questionaryModel.otherDataEmptyCountThree + questionaryModel.otherDataErrorCountThree === 0,
                isError: questionaryModel.isChangeOtherDataThree &&
                  questionaryModel.otherDataEmptyCountThree + questionaryModel.otherDataErrorCountThree > 0,
                isLoading: questionaryModel.activeOtherPanel === '3' && isNextOtherDataLoading,
                isDisable: questionaryModel.activeOtherPanel !== '3' && isNextOtherDataLoading
              },
              {
                value: '4',
                label: 'Доходы',
                isSuccess: questionaryModel.isChangeOtherDataFour &&
                  questionaryModel.otherDataEmptyCountFour + questionaryModel.otherDataErrorCountFour === 0,
                isError: questionaryModel.isChangeOtherDataFour &&
                  questionaryModel.otherDataEmptyCountFour + questionaryModel.otherDataErrorCountFour > 0,
                isLoading: questionaryModel.activeOtherPanel === '4' && isNextOtherDataLoading,
                isDisable: questionaryModel.activeOtherPanel !== '4' && isNextOtherDataLoading
              }
            ]

            options.push({
              value: '5',
              label: 'Контактное лицо',
              isSuccess: questionaryModel.isChangeOtherDataFive &&
                questionaryModel.otherDataEmptyCountFive + questionaryModel.otherDataErrorCountFive === 0,
              isError: questionaryModel.isChangeOtherDataFive &&
                questionaryModel.otherDataEmptyCountFive + questionaryModel.otherDataErrorCountFive > 0,
              isLoading: questionaryModel.activeOtherPanel === '5' && isNextOtherDataLoading,
              isDisable: questionaryModel.activeOtherPanel !== '5' && isNextOtherDataLoading
            })
            options.push({
              value: '6',
              label: 'Кодовое слово',
              isSuccess: questionaryModel.isChangeOtherDataSix &&
                questionaryModel.otherDataEmptyCountSix + questionaryModel.otherDataErrorCountSix === 0,
              isError: questionaryModel.isChangeOtherDataSix &&
                questionaryModel.otherDataEmptyCountSix + questionaryModel.otherDataErrorCountFive > 0,
              isLoading: questionaryModel.activeOtherPanel === '6' && isNextOtherDataLoading,
              isDisable: questionaryModel.activeOtherPanel !== '6' && isNextOtherDataLoading
            })

            return options
          })()}
          htmlID={`${OTHER_DATA_HTML_ID}-nav-bar`}
        >
          {ContentContainer}
        </NavBar>
      </Block>
      <Row isFlex justify="space-between" align="middle" marginTop={2.5}>
        <Col>
          <Button
            tabIndex={3}
            onClick={questionaryModel.backOtherData}
            isLoading={isNextOtherDataLoading}
            htmlID={`${OTHER_DATA_HTML_ID}-back`}
          >
            Назад
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            tabIndex={2}
            onClick={(e) => {
              if (questionaryModel.isNextOtherData) {
                questionaryModel.nextOtherData()(e)
              } else {
                questionaryModel.enableOtherDataValidation()
              }
            }}
            isLoading={isNextOtherDataLoading}
            htmlID={`${OTHER_DATA_HTML_ID}-next`}
          >
            Продолжить
          </Button>
        </Col>
      </Row>
    </Block>
  )
}

OtherData.propTypes = {
  isNew: PropTypes.bool
}

OtherData.defaultProps = {
  isNew: true
}

export default inject(store => ({
  menuModel: store.menuModel,
  pickupModel: store.pickupModel,
  questionaryModel: store.questionaryModel,
  responsiveModel: store.responsiveModel,
  applicationModel: store.applicationModel
}))(observer(OtherData))
