import React from 'react'
import { Block, Title, Text, mountHook, Icon } from 'uikit'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import _ from 'lodash'

const Root = styled(Block)`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const CallCenterErrorOrder = ({ menuModel, globalPreloaderModel, questionaryModel, pickupModel, responsiveModel, ...props }) => {
  mountHook(async () => {
    const orderID = _.get(props, 'match.params.orderID', null)

    if (orderID && !questionaryModel.id) {
      await pickupModel.getOrder(orderID, true)
    }
    globalPreloaderModel.setVisible(false)
  })

  return (
    <Root
      isCenter
      style={{
        left: responsiveModel.isTabletAndPhone ? '0' : '288px',
        padding: responsiveModel.isTabletAndPhone ? '1rem 2rem' : '0',
        position: responsiveModel.isTabletAndPhone ? 'relative' : 'fixed'
      }}
    >
      <Title level={responsiveModel.isTabletAndPhone ? 3 : 2} align="center">
        <Text type="danger">Заказ передан на обработку в Контакт-Центр.</Text>
        <br/>
        <Text type="danger">Ожидайте звонка оператора.</Text>
      </Title>
    </Root>
  )
}

export default inject(store => ({
  menuModel: store.menuModel,
  globalPreloaderModel: store.globalPreloaderModel,
  questionaryModel: store.questionaryModel,
  pickupModel: store.pickupModel,
  responsiveModel: store.responsiveModel
}))(observer(CallCenterErrorOrder))
