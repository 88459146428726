import { action, computed } from 'mobx'
import { safeObject } from 'uikit'
import { mix } from 'mixwith'
import QuestionaryProvider from 'providers/api-provider/questionary-provider'
import history, { orderHistory } from 'utils/history'
import BaseModel from '../baseModel'
import MainData from './mainData'
import AdditionalData from './additionalData'
import OtherData from './otherData'
import pickupModel from '../pickupModel'
import menuModel from '../menuModel'
import applicationModel from '../applicationModel'

const fields = [
  'firstName',
  'lastName',
  'patronymic',
  'sex',
  'birthDay',
  'passport',
  'passportDay',
  'income',
  'mobilePhone',
  'confirmCode',

  'passportCode',
  'passportBy',
  'birthPlace',
  'addressRegistration',
  'registrationDate',
  'addressHome',
  'statusHome',
  'homeDate',
  'activeAdditionalPanel',

  'contactPhone',
  'contactEmail',
  'educationType',
  'familyStatus',
  'familyFirstName',
  'familyLastName',
  'familyPatronymic',
  'birthdayFamily',
  'registrationDateFamily',
  'childrenCount',
  'weeklyCount',
  'totalExperienceYear',
  'totalExperienceMonth',
  'employmentType',
  'organizationName',
  'organizationAddress',
  'havingsType',
  'activityType',
  'activityVector',
  'position',
  'workPhone',
  'workEmail',
  'workBeginDate',
  'totalExperienceWork',
  'incomes',
  'contactPersonFirstName',
  'contactPersonPatronymic',
  'contactPersonPhone',
  'contactFamilyType',
  'keyWord',
  'activeOtherPanel'
]

class QuestionaryModel extends mix(BaseModel).with(MainData, AdditionalData, OtherData) {
  constructor() {
    super(fields, 'questionaryModel')

    this.applyOnChangeHook(fields, this.handleAfterChangeFields)
  }

  @action applyQuestionaryModel = questionaryModel => {
    this.applyData(questionaryModel)
  }

  @action applyQuestinaryShort = data => {
    this.applyData(safeObject({
      confirmCodeID: data.confirmCodeID || null,
      confirmKey: data.confirmKey || null,
      firstName: data.firstName || null,
      lastName: data.lastName || null,
      isConfirmPhone: data.isConfirmPhone,
      patronymic: data.patronymic || null,
      mobilePhone: data.mobilePhone || null
    }))
  }

  @computed get isFill() {
    return (
      this.mainDataEmptyCount + this.mainDataErrorCount === 0 &&
      this.isChangeMainData &&

      this.additionalDataErrorCountOne + this.additionalDataEmptyCountOne === 0 &&
      this.additionalDataErrorCountTwo + this.additionalDataEmptyCountTwo === 0 &&
      this.isChangeAdditionalDataOne && this.isChangeAdditionalDataTwo &&

      this.otherDataErrorCountEducation + this.otherDataEmptyCountEducation === 0 &&
      this.otherDataErrorCountTwo + this.otherDataEmptyCountTwo === 0 &&
      this.otherDataErrorCountThree + this.otherDataEmptyCountThree === 0 &&
      this.otherDataErrorCountFour + this.otherDataEmptyCountFour === 0 &&
      this.otherDataErrorCountFive + this.otherDataEmptyCountFive === 0 &&
      this.otherDataErrorCountSix + this.otherDataEmptyCountSix === 0 &&
      this.isChangeOtherDataEducation && this.isChangeOtherDataTwo && this.isChangeOtherDataThree &&
      this.isChangeOtherDataFour &&
      this.isChangeOtherDataFive && this.isChangeOtherDataSix
    )
  }

  @computed get isFillMain() {
    return (
      this.mainDataEmptyCount + this.mainDataErrorCount === 0 &&
      this.isChangeMainData
    )
  }
  @computed get isFillAdditional() {
    return (
      this.additionalDataErrorCountOne + this.additionalDataEmptyCountOne === 0 &&
      this.additionalDataErrorCountTwo + this.additionalDataEmptyCountTwo === 0 &&
      this.isChangeAdditionalDataOne && this.isChangeAdditionalDataTwo
    )
  }

  handleAfterChangeFields = async state => {
    const { field } = state

    if (field) {
      if (
        field === 'firstName' || field === 'lastName' || field === 'patronymic' || field === 'sex' ||
        field === 'birthDay' || field === 'passport' || field === 'passportDay' ||
        field === 'income' || field === 'mobilePhone'
      ) {
        this.applyData({
          isChangeMainData: true
        })
        return
      }

      if (field === 'educationType') {
        this.applyData({
          isChangeOtherDataEducation: true
        })
        return
      }

      if (
        field === 'passportCode' || field === 'passportBy' || field === 'birthPlace' ||
        field === 'addressRegistration' || field === 'registrationDate'
      ) {
        this.applyData({
          isChangeAdditionalDataOne: true
        })
        return
      }

      if (
        field === 'addressHome' ||
        field === 'statusHome' || field === 'homeDate'
      ) {
        this.applyData({
          isChangeAdditionalDataTwo: true
        })
        return
      }

      if (
        field === 'familyStatus' ||
        field === 'familyFirstName' || field === 'familyLastName' || field === 'familyPatronymic' ||
        field === 'birthdayFamily' || field === 'registrationDateFamily' ||
        field === 'childrenCount' || field === 'weeklyCount'
      ) {
        this.applyData({
          isChangeOtherDataTwo: true
        })
        return
      }

      if (
        field === 'totalExperienceYear' || field === 'totalExperienceMonth' || field === 'employmentType' ||
        field === 'organizationName' || field === 'organizationAddress' || field === 'havingsType' ||
        field === 'activityType' || field === 'activityVector' ||
        field === 'position' || field === 'workPhone' || field === 'contactPhone' || field === 'workEmail' ||
        field === 'workBeginDate' || field === 'totalExperienceWork'
      ) {
        this.applyData({
          isChangeOtherDataThree: true
        })
        return
      }

      if (
        field === 'incomes'
      ) {
        this.applyData({
          isChangeOtherDataFour: true
        })
        return
      }

      if (
        field === 'contactPersonFirstName' || field === 'contactPersonPatronymic' ||
        field === 'contactPersonPhone' || field === 'contactFamilyType'
      ) {
        this.applyData({
          isChangeOtherDataFive: true
        })
      }

      if (
        field === 'keyWord'
      ) {
        this.applyData({
          isChangeOtherDataSix: true
        })
      }
    }
  }

  async getQuestionary(orderID, isFromCache = false) {
    try {
      await pickupModel.getOrder(orderID)

      if (applicationModel.current) return

      try {
        await this.getCleanQuestionary(orderID)
      } catch (e) {
        if (!isFromCache) {
          history.push(`/orders/${orderID}`)
        } else {
          menuModel.changeStep(0, orderHistory('/pickup', orderID))
        }
      }
    } catch (e) {
      history.push('/error_order')
    }
  }

  async getCleanQuestionary(orderID, defaultPath = '', defaultStep = 0) {
    const questionary = await QuestionaryProvider.getQuestionary(orderID)
    this.applyQuestionaryModel({ ...questionary })
    if (this.isFill) {
      menuModel.changeStep(4, orderHistory('/credit_result', orderID, false), false)
    }

    if (defaultPath) {
      menuModel.changeStep(defaultStep, orderHistory(defaultPath, orderID, false), false)
    }

    switch (menuModel.step) {
      case 4: {
        menuModel.changeStep(4, orderHistory('/credit_result', orderID, false), false)
        break
      }
      case 3: {
        if (!(this.validAdditionalData && this.isChangedAdditionalData)) {
          if (this.isNextMainData) {
            menuModel.changeStep(2, orderHistory('/additional_data', orderID, false), false)
          } else {
            menuModel.changeStep(1, orderHistory('/main_data', orderID, false), false)
          }
        }
        break
      }
      case 2: {
        if (!this.isNextMainData) {
          menuModel.changeStep(1, orderHistory('/main_data', orderID, false), false)
        }
        break
      }
      default: {
        if (menuModel.step >= 0 && menuModel.step < 2) return

        if (this.validAdditionalData && this.isChangedAdditionalData) {
          menuModel.changeStep(3, orderHistory('/other_data', orderID, false), false)
        } else if (this.isNextMainData) {
          menuModel.changeStep(2, orderHistory('/additional_data', orderID, false), false)
        } else {
          menuModel.changeStep(1, orderHistory('/main_data', orderID, false), false)
        }
      }
    }
  }

  continueFillQuestionary() {
    if (!this.isFillMain) {
      history.push(orderHistory('/main_data'))
      return
    }
    if (!this.isFillAdditional) {
      history.push(orderHistory('/additional_data'))
      return
    }
    history.push(orderHistory('/other_data'))
  }
}

export { QuestionaryModel }
export default new QuestionaryModel()
