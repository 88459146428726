import React from 'react'
import PropTypes from 'prop-types'
import { Block, hexToRgba, darken } from 'uikit'
import styled from 'styled-components'

const Root = styled(Block)`
  background: ${props => hexToRgba(props.theme.colors.primary, 0.08)};
  border: .4rem solid ${props => hexToRgba(darken(props.theme.colors.primary, 0.15), 0.08)};
  border-radius: 0.7rem;
  width: 18%;
  position: absolute;
  right: -0.4rem;
  top: -1rem;
  z-index: 0;
`

const AccentMonthlyPayment = ({ count }) => (
  <Root
    height={count * 100 + 110}
    isRem={false}
  />
)

AccentMonthlyPayment.propTypes = {
  count: PropTypes.number
}

AccentMonthlyPayment.defaultProps = {
  count: 0
}

export default AccentMonthlyPayment
