import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import {
  Input, Block, Text, phoneNumberNormalizer, SelectPicker, namesNormalizer,
  Tooltip, Icon,
  Paragraph
} from 'uikit'
import { OTHER_DATA_HTML_ID } from 'utils/htmlID'
import FioInput from 'components/FioInput'

const ContactPerson = ({ questionaryModel, dictModel, responsiveModel }) => {
  const {
    contactPersonFirstName, contactPersonPatronymic,
    contactPersonPhone, contactFamilyType,

    isExistOtherDataStep, contactPersonValidationEnabled
  } = questionaryModel
  const { isTabletAndPhone } = responsiveModel

  return (
    <Fragment>
      <Block
        width={!isTabletAndPhone ? 16 : '100%'}
        marginTop={-1}
      >
        <FioInput
          key="contactPersonFirstName"
          isForm
          type="text"
          value={contactPersonFirstName}
          label="Имя контактного лица"
          onChange={questionaryModel.setField('contactPersonFirstName')}
          error={questionaryModel.validContactPersonFirstName}
          tabIndex={1}
          normalizer={namesNormalizer}
          htmlID={`${OTHER_DATA_HTML_ID}-contactPersonFirstName`}
          isShowForceValidate={isExistOtherDataStep || contactPersonValidationEnabled}
          onBlur={() => {
            questionaryModel.enableIsChangeOtherDataFive()
            questionaryModel.trimField('contactPersonFirstName')()
          }}
        />
      </Block>
      <Block
        width={!isTabletAndPhone ? 16 : '100%'}
        marginTop={1.5}
      >
        <FioInput
          marginBottom={3}
          key="contactPersonPatronymic"
          isForm
          type="text"
          value={contactPersonPatronymic}
          label="Отчество контактного лица"
          onChange={questionaryModel.setField('contactPersonPatronymic')}
          error={questionaryModel.validContactPersonPatronymic}
          tabIndex={1}
          normalizer={namesNormalizer}
          htmlID={`${OTHER_DATA_HTML_ID}-contactPersonPatronymic`}
          isShowForceValidate={isExistOtherDataStep || contactPersonValidationEnabled}
          onBlur={() => {
            questionaryModel.enableIsChangeOtherDataFive()
            questionaryModel.trimField('contactPersonPatronymic')()
          }}
          help="При наличии обязательно к заполнению"
        />
      </Block>
      <Block
        width={!isTabletAndPhone ? 16 : '100%'}
        marginTop={1.5}
      >
        <Input
          marginBottom={5}
          key="contactPersonPhone"
          label="Телефон"
          prefix={<Text size={16} isRem={false} type="text">+7 </Text>}
          mask="(999) 999-9999"
          unmask={v => v.replace(/[\s()-]*/gi, '')}
          value={contactPersonPhone}
          onChange={questionaryModel.setField('contactPersonPhone')}
          error={questionaryModel.validContactPersonPhone}
          tabIndex={1}
          isShowForceValidate={isExistOtherDataStep || contactPersonValidationEnabled}
          postfix={
            <Tooltip
              tooltip={
                <Paragraph isNativeMargin={false}>
                  <Block>Должен отличаться от</Block>
                  <Block>+7 {phoneNumberNormalizer(questionaryModel.mobilePhone)}</Block>
                  {(questionaryModel.contactPhone || questionaryModel.contactPhone) &&
                  <Fragment>
                    <Block>Должен отличаться от</Block>
                    {questionaryModel.isWorkingEmploymentOtherData ?
                      <Block>
                        +7 {phoneNumberNormalizer(questionaryModel.workPhone)}
                      </Block> :
                      <Block>
                        +7 {phoneNumberNormalizer(questionaryModel.contactPhone)}
                      </Block>
                    }
                  </Fragment>
                  }
                </Paragraph>
              }
            >
              <Icon icon="question" iconType="design" color="gray4"/>
            </Tooltip>
          }
          onBlur={questionaryModel.enableIsChangeOtherDataFive}
          htmlID={`${OTHER_DATA_HTML_ID}-contactPersonPhone`}
          type="number"
        />
      </Block>
      <Block width={!isTabletAndPhone ? 16 : '100%'} marginTop={1.5}>
        <SelectPicker
          key="contactFamilyType"
          label="Степень родства"
          value={contactFamilyType}
          options={dictModel.dict.relation_types}
          onChange={questionaryModel.setField('contactFamilyType')}
          error={questionaryModel.validContactFamilyType}
          tabIndex={1}
          isShowForceValidate={isExistOtherDataStep || contactPersonValidationEnabled}
          onBlur={questionaryModel.enableIsChangeOtherDataFive}
          htmlID={`${OTHER_DATA_HTML_ID}-contactFamilyType`}
        />
      </Block>
    </Fragment>
  )
}

export default inject(store => ({
  questionaryModel: store.questionaryModel,
  dictModel: store.dictModel,
  responsiveModel: store.responsiveModel
}))(observer(ContactPerson))
