import _ from 'lodash'
import { moment, isSafe } from 'uikit'

export const mainDataMap = [
  {
    frontPath: 'id',
    backPath: ['id']
  },
  {
    frontPath: 'lastName',
    backPath: ['lastname']
  },
  {
    frontPath: 'firstName',
    backPath: ['firstname']
  },
  {
    frontPath: 'patronymic',
    backPath: ['patronymic'],
    forBack: v => v || null
  },
  {
    frontPath: 'sex',
    backPath: ['gender'],
    forFront: v => (v === 'm' || v === 'male' || v === 1 || v === '1' ? '1' : '2'),
    forBack: v => (v === '1' ? 'male' : 'female')
  },
  {
    frontPath: 'birthDay',
    backPath: ['birthday'],
    forFront: v => moment(v, 'YYYY-MM-DD').toDate(),
    forBack: v => moment(v, 'YYYY-MM-DD').format('YYYY-MM-DD')
  },
  {
    frontPath: 'mobilePhone',
    backPath: ['mobile_phone'],
    forFront: v => _.chain(v.toString().split(''))
      .map((s, index) => (index === 0 ? null : s))
      .filter(s => isSafe(s))
      .value()
      .join(''),
    forBack: v => `8${v}`
  },
  {
    frontPath: 'isConfirmPhone',
    backPath: ['mobile_phone_confirmed']
  },
  {
    frontPath: 'passport',
    backPath: ['passport.series_number', 'passport_attributes.series_number']
  },
  {
    frontPath: 'passportDay',
    backPath: ['passport.issued_on', 'passport_attributes.issued_on'],
    forFront: v => moment(v, 'YYYY-MM-DD').toDate(),
    forBack: v => moment(v, 'YYYY-MM-DD').format('YYYY-MM-DD')
  },
  {
    frontPath: 'income',
    backPath: ['monthly_income']
  },
  {
    frontPath: 'contactEmail',
    backPath: ['email']
  }
]

export const additionalDataMap = [
  {
    frontPath: 'passportCode',
    backPath: ['passport.issuer_code', 'passport_attributes.issuer_code']
  },
  {
    frontPath: 'passportBy',
    backPath: ['passport.issued_by', 'passport_attributes.issued_by']
  },
  {
    frontPath: 'birthPlace',
    backPath: ['birth_place']
  },
  {
    frontPath: 'registrationDate',
    backPath: ['registration_address_attributes.starts_on', 'registration_address.starts_on'],
    forFront: v => new Date(v),
    forBack: v => moment(v, 'YYYY-MM-DD').format('YYYY-MM-DD')
  },
  {
    frontPath: 'homeDate',
    backPath: ['residence_address_attributes.starts_on', 'residence_address.starts_on'],
    forFront: v => new Date(v),
    forBack: v => moment(v, 'YYYY-MM-DD').format('YYYY-MM-DD')
  },
  {
    frontPath: 'statusHome',
    backPath: ['residence_status']
  }

]

export const otherDataMap = [

  // Education subStep
  {
    frontPath: 'educationType',
    backPath: ['education_type']
  },

  // Two subStep
  {
    frontPath: 'familyStatus',
    backPath: ['marital_status']
  },
  {
    frontPath: 'familyFirstName',
    backPath: ['spouse_firstname']
  },
  {
    frontPath: 'familyLastName',
    backPath: ['spouse_lastname']
  },
  {
    frontPath: 'familyPatronymic',
    backPath: ['spouse_patronymic'],
    forBack: v => v || null
  },
  {
    frontPath: 'birthdayFamily',
    backPath: ['spouse_birthday'],
    forFront: v => new Date(v)
  },
  {
    frontPath: 'registrationDateFamily',
    backPath: ['married_on'],
    forFront: v => new Date(v)
  },
  {
    frontPath: 'childrenCount',
    backPath: ['child_count'],
    forFront: v => (Number(v) > 0 ? Number(v) : null),
    forBack: v => (Number(v) > 0 ? Number(v) : 0)
  },
  {
    frontPath: 'weeklyCount',
    backPath: ['dependent_count'],
    forFront: v => (Number(v) > 0 ? Number(v) : null),
    forBack: v => (Number(v) > 0 ? Number(v) : 0)
  },

  // Three subStep
  {
    frontPath: 'totalExperience',
    backPath: ['employment_attributes.common_experience_months', 'employment.common_experience_months']
  },
  {
    frontPath: 'employmentType',
    backPath: ['employment_attributes.employment_type', 'employment.employment_type']
  },
  {
    frontPath: 'organizationName',
    backPath: ['employment_attributes.organization_name', 'employment.organization_name']
  },
  {
    frontPath: 'havingsType',
    backPath: ['employment_attributes.ownership_type', 'employment.ownership_type']
  },
  {
    frontPath: 'activityType',
    backPath: ['employment_attributes.employment_activity_code', 'employment.employment_activity_code'],
    forBack: v => Number(v)
  },
  {
    frontPath: 'activityVector',
    backPath: ['employment_attributes.employment_activity_line_code', 'employment.employment_activity_line_code'],
    forBack: v => Number(v)
  },
  {
    frontPath: 'position',
    backPath: ['employment_attributes.position', 'employment.position']
  },
  {
    frontPath: 'workPhone',
    backPath: ['employment_attributes.phone', 'employment.phone'],
    forFront: v => `${v.substr(1)}`,
    forBack: v => `8${v}`
  },
  {
    frontPath: 'workEmail',
    backPath: ['employment_attributes.email', 'employment.email']
  },
  {
    frontPath: 'workBeginDate',
    backPath: ['employment_attributes.starts_on', 'employment.starts_on'],
    forFront: v => new Date(v),
    forBack: v => moment(v, 'YYYY-MM-DD').format('YYYY-MM-DD')
  },
  {
    frontPath: 'contactPhone',
    backPath: ['contact_phone'],
    forFront: v => v.substr(1),
    forBack: v => `8${v}`
  },

  // Four subStep
  {
    frontPath: 'incomes',
    backPath: ['incomes_attributes', 'incomes'],
    forFront: v => _.map(v, value => ({
      incomeType: _.get(value, 'category', null),
      sumIncome: _.get(value, 'sum', 0)
    })),
    forBack: v => _.map(v, value => ({ category: value.incomeType, sum: value.sumIncome }))
  },

  // Five subStep
  {
    frontPath: 'contactPersonFirstName',
    backPath: ['contact_person_attributes.firstname', 'contact_person.firstname']
  },
  {
    frontPath: 'contactPersonPatronymic',
    backPath: ['contact_person_attributes.patronymic', 'contact_person.patronymic'],
    forBack: v => v || null
  },
  {
    frontPath: 'contactPersonPhone',
    backPath: ['contact_person_attributes.phone', 'contact_person.phone'],
    forFront: v => `${v.substr(1)}`,
    forBack: v => `8${v}`
  },
  {
    frontPath: 'contactFamilyType',
    backPath: ['contact_person_attributes.relation_type', 'contact_person.relation_type']
  },

  // Six subStep
  {
    frontPath: 'keyWord',
    backPath: ['keyword']
  }
]

export const questionaryMap = [
  ...mainDataMap,
  ...additionalDataMap,
  ...otherDataMap
]
