import _ from 'lodash'
import store from 'store'
import { defaultSettings } from 'providers/settings-provider'
import { SETTINGS_FIELD } from './constants'

class StorageProvider {
  getAllSettings() {
    const settings = store.get(SETTINGS_FIELD)
    return _.merge({}, defaultSettings, settings)
  }

  getSetting(field) {
    return _.get(this.getAllSettings(), field, '')
  }

  setSetting(field, value) {
    const settings = store.get(SETTINGS_FIELD) ? store.get(SETTINGS_FIELD) : {}
    _.set(settings, field, value)
    store.set(SETTINGS_FIELD, settings)
  }
}

export default new StorageProvider()
