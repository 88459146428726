import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Block, Loader, Title } from 'uikit'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

const Root = styled(Block)`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 20000;
`

const GlobalPreloader = ({ size, globalPreloaderModel }) => (
  globalPreloaderModel.isVisible ?
    <Fragment>
      <Root>
        <Loader size={size} isRotate/>
        {globalPreloaderModel.title &&
          <Title marginTop={1} level={3} type="secondary" align="center">
            {globalPreloaderModel.title}
          </Title>
        }
      </Root>
    </Fragment> : null
)

GlobalPreloader.propTypes = {
  size: PropTypes.number
}

GlobalPreloader.defaultProps = {
  size: 6
}

export default inject(store => ({
  globalPreloaderModel: store.globalPreloaderModel
}))(observer(GlobalPreloader))
