import _ from 'lodash'
import fp from 'lodash/fp'
import PickupProvider from 'providers/api-provider/pickup-provider'
import { action, observable, computed, toJS } from 'mobx'
import React from 'react'
import history, { orderHistory } from 'utils/history'
import StorageProvider from 'providers/storage-provider'
import SdkProvider from 'providers/sdk-provider'
import merge from 'deepmerge'
import { notifyError, safeObject } from 'uikit'
import ActionCableProvider from 'providers/action-cable-provider'
import BaseModel from '../baseModel'
import menuModel from '../menuModel'
import dictModel from '../dictModel'
import applicationModel from '../applicationModel'
import questionaryModel from '../questionaryModel'
import globalPreloaderModel from '../globalPreloaderModel'
import templatesModel from '../templatesModel'

const fields = [
  { field: 'initialPayment', path: 'pickupInfo.initialPayment' },
  { field: 'initialPaymentPercent', path: 'pickupInfo.initialPaymentPercent' },
  { field: 'term', path: 'pickupInfo.term' },
  { field: 'slots', path: 'order.slots' }
]
const orderExpandEnum = {
  DEFAULT: 'default',
  OPENED: 'opened',
  CLOSED: 'closed'
}

class PickupModel extends BaseModel {
  constructor() {
    super(fields, 'pickupModel')
    this.applyOnChangeHook(fields, this.handleAfterChangeFields, true)
    this.getOffersAfterChangeFields = _.debounce(this.getOffersAfterChangeFields.bind(this), 1000)
    this.setIsLoading = _.debounce(this.setIsLoading.bind(this), 50)
    this.setOffers = _.debounce(this.setOffers.bind(this), 50)
    this.getOrder = _.debounce(this.getOrder.bind(this), 50)
  }

  @action applyPickupModel = pickupModel => {
    this.applyData(pickupModel)
  }

  @observable offers = []
  @observable updatingOfferID = null
  @observable isEmptyOffers = false
  @observable offersSort = {
    field: null,
    isAsc: null
  }
  @observable calculationID = null

  @observable pickupInfo = {
    pointName: '',
    addressPointName: '',
    initialPayment: '0',
    initialPaymentPercent: false,
    term: 12,

    isChanged: false,
    isInitChanged: false,
    isStartChanged: false,
    isLoading: false,
    isUpdating: false
  }

  @observable order = {
    id: '',
    pointName: '',
    pointID: '',
    addressPointName: '',
    phone: '',
    logo: '',
    ownerLogo: '',
    orderUrl: '',
    numberOrder: '',
    payment: 0,
    calculationID: null,
    state: '',
    orders: [],
    slots: [],
    target: ''
  }
  @observable isOrderExpandByUser = orderExpandEnum.DEFAULT
  @observable isOrderMoreExpand = false
  @observable isCallCenterCall = false

  @action setOrderExpand = v => {
    this.isOrderExpandByUser = v ? orderExpandEnum.OPENED : orderExpandEnum.CLOSED
  }

  @computed get isOrderExpand() {
    if (this.isOrderExpandByUser === orderExpandEnum.DEFAULT) {
      return menuModel.step === 0
    }
    return this.isOrderExpandByUser === orderExpandEnum.OPENED
  }
  @computed get isPreloading() {
    return !this.pickupInfo.isLoading &&
      ((!this.pickupInfo.isInitChanged && !this.pickupInfo.isChanged) || this.isEmptyOffers)
  }
  @computed get isTargetCallCenter() {
    return this.order.target === 'callcenter'
  }

  @action setInitialPayment = value => {
    if (value !== this.pickupInfo.initialPayment) {
      this.pickupInfo.initialPayment = value
    }
  }

  @action setInitialPaymentPercent = value => {
    if (value === 'percent') {
      let percent = Number(this.pickupInfo.initialPayment) * 100 / this.order.payment
      if (percent > 90) percent = 90
      this.pickupInfo.initialPayment = Math.floor(percent)
    } else if (Number(this.pickupInfo.initialPayment) > 0) {
      this.pickupInfo.initialPayment = Math.floor(this.order.payment * Number(this.pickupInfo.initialPayment) / 100)
    }
    this.pickupInfo.initialPaymentPercent = value === 'percent'
  }

  @action setSlot = (id, value) => {
    const slots = this.order.slots.slice()
    if (_.get(_.find(slots, s => s.id === id), 'isSelect') !== value) {
      _.set(slots, `${_.findIndex(slots, s => s.id === id)}.isSelect`, value)
      this.applyData({
        'order.slots': slots
      })
    }
  }

  async getOrder(orderID, isGetQuestionary = false) {
    try {
      if (orderID && orderID !== this.order.id) {
        globalPreloaderModel.setVisible(true)

        this.applyData({
          'pickupInfo.isUpdating': true,
          'pickupInfo.isChanged': true
        })
        const { order, term, initialPayment, selectOffer, client, templates } = await PickupProvider.getOrder(orderID)
        templatesModel.setTemplates(templates)

        if (order.target === 'callcenter') {
          history.push(`/orders/${orderID}/main_data_call_center`)
        }

        if (selectOffer) {
          applicationModel.setCurrent(selectOffer)
          this.applyData({
            'pickupInfo.initialPayment': initialPayment,
            'pickupInfo.term': term,
            order: {
              id: orderID,
              ...order
            }
          })
          SdkProvider.updatePointID(order.pointID)
          SdkProvider.updateGoods(_.map(order.orders, or => ({
            name: or.name,
            count: or.count,
            price: or.cost,
            category: or.category,
            manufacturer: or.manufacturer
          })))
          globalPreloaderModel.setVisible(false)
          StorageProvider.setSetting('cache.orderID', orderID)
          StorageProvider.setSetting('cache.jwtToken', order.token)
          history.push(`/credit_result_send/${orderID}`)
          return
        }

        ActionCableProvider.run(orderID)
        ActionCableProvider.addSubscription({ channel: 'ApplicationsChannel', order_unique_id: orderID })

        await dictModel.fetchDict(orderID)
        this.applyData({
          'pickupInfo.initialPayment': initialPayment,
          'pickupInfo.term': term,
          order: {
            id: orderID,
            ...order
          }
        })
        SdkProvider.updatePointID(order.pointID)
        SdkProvider.updateGoods(_.map(order.orders, or => ({
          name: or.name,
          count: or.count,
          price: or.cost,
          category: or.category,
          manufacturer: or.manufacturer
        })))
        StorageProvider.setSetting('cache.orderID', orderID)
        StorageProvider.setSetting('cache.jwtToken', order.token)
        if (order.calculationID) {
          await this.getMyOffers(null, { isClearGet: true, calculationID: order.calculationID })
        }

        this.applyData({
          'pickupInfo.isUpdating': false
        })

        try {
          await applicationModel.getApplications(orderID)
        } finally {
          globalPreloaderModel.setVisible(false)
        }

        try {
          questionaryModel.applyQuestinaryShort({
            ...client,
            isConfirmPhone: client.confirmKey.length > 0
          })
        } catch (e) {
          // skip the fields setting
        }

        if (isGetQuestionary) {
          switch (order.state) {
            case 'calculated':
              try {
                await questionaryModel.getCleanQuestionary(orderID, '/main_data', 1)
              } catch (e) {
                // Not need redirect to main data if form is not exist
              }
              break
            case 'form_filling':
              await questionaryModel.getCleanQuestionary(orderID, '/main_data', 1)
              break
            case 'positive':
              await questionaryModel.getCleanQuestionary(orderID, '/credit_result', 4)
              break
            case 'negative':
              await questionaryModel.getCleanQuestionary(orderID, '/credit_result', 4)
              break
          }
        }
        if (order.state === 'call_center_call' || (order.target === 'callcenter' && order.state !== 'new')) {
          this.setCallCenterCall()
        }
      }
    } catch (e) {
      notifyError('Ошибка получения заказа')

      globalPreloaderModel.setVisible(false)
      this.applyData({
        'pickupInfo.isUpdating': false,
        'pickupInfo.isChanged': false
      })
      history.push('/error_order')
    }
  }

  @action sortOffers = (field, isAsc) => {
    this.applyData({
      offers: toJS(this.offers).sort((a, b) => (a[field] > b[field] ? (isAsc ? 1 : -1) : (isAsc ? -1 : 1))),
      offersSort: {
        field,
        isAsc
      }
    })
  }

  @computed get resolveOrders() {
    return !this.isOrderMoreExpand ? this.order.orders.slice(0, 3) : this.order.orders
  }

  @computed get isShowExpandMoreOrders() {
    return this.order.orders.length !== this.resolveOrders.length
  }

  @action expandMoreOrders = () => {
    this.isOrderMoreExpand = true
  }

  @computed get isNext() {
    const { isLoading, isChanged } = this.pickupInfo
    return !isLoading && isChanged && !_.isEmpty(this.offers) && !this.pickupInfo.isStartChanged
  }

  handleAfterChangeFields = async state => {
    const { field } = state
    if (field) {
      return
    }

    if (this.pickupInfo.isUpdating) return

    if (state.initialPayment !== '' && state.term !== '') {
      this.setIsLoading(false)
    }
    this.applyData({
      'pickupInfo.isChanged': true,
      'pickupInfo.isInitChanged': true
    })
    if (state.initialPayment !== '' && state.term !== '' && Number(state.term) >= 6 && this.order.target !== 'callcenter') {
      this.applyData({ 'pickupInfo.isStartChanged': true })
      await this.getOffersAfterChangeFields({
        ...state,
        term: Number(state.term) < 6 ? 6 : state.term,
        initialPayment: Number(state.initialPayment) <= 0 ? 0 : state.initialPaymentPercent ?
          Math.floor(state.initialPayment * this.order.payment / 100) : state.initialPayment,
        orderID: this.order.id,
        payment: this.order.payment
      })
    }
  }

  @action applySingleOffer = async (slotID, isSelect, offerID) => {
    try {
      this.applyData({ updatingOfferID: offerID })
      const offerIndex = _.findIndex(this.offers, f => f.id === offerID)

      let slots = _.get(merge([], toJS(this.offers)), `${offerIndex}.slots`, [])
      if (!isSelect) {
        const slotIndex = _.findIndex(slots, s => s === slotID)
        slots.splice(slotIndex, 1)
      } else {
        slots.push(slotID)
        slots = _.uniq(slots)
      }

      const offer = await PickupProvider.applySingleSlot({
        slots,
        offerID,
        calculationID: this.order.calculationID,
        orderID: this.order.id
      })

      if (offerIndex !== -1) {
        const offers = merge([], toJS(this.offers))
        if (offer.state !== 'failed') {
          _.set(offers, `${offerIndex}`, offer)
        } else {
          offers.splice(offerIndex, 1)
        }

        this.setOffers(offers)
        this.applyData({ isEmptyOffers: _.isEmpty(offers) })
      }
    } catch (e) {
      notifyError('Ошибка получения кредитных продуктов заказа')
    } finally {
      this.applyData({ updatingOfferID: null })
    }
  }

  async getOffersAfterChangeFields(prepare) {
    try {
      this.applyData({
        'pickupInfo.isInitChanged': true
      })
      await this.getMyOffers(prepare)
    } finally {
      this.applyData({
        'pickupInfo.isStartChanged': false
      })
    }
  }

  setIsLoading(isLoading) {
    this.applyData({ 'pickupInfo.isLoading': isLoading })
  }

  setOffers(offers) {
    this.applyData({ offers })
  }

  @action getMyOffers = async (data = null, options) => {
    const isClearGet = _.get(options, 'isClearGet', false)
    let calculationID = _.get(options, 'calculationID', null)
    const prepare = data || {
      orderID: this.order.id,
      initialPayment: Number(this.pickupInfo.initialPayment) <= 0 ? 0 : this.pickupInfo.initialPaymentPercent ?
        Math.floor(this.pickupInfo.initialPayment * this.order.payment / 100) : this.pickupInfo.initialPayment,
      term: this.pickupInfo.term,
      slots: this.order.slots
    }

    this.setIsLoading(true)
    this.applyData({
      'pickupInfo.isChanged': true,
      isEmptyOffers: false
    })

    let intervalID = null
    try {
      if (!isClearGet) {
        const { id } = await PickupProvider.applyOffersParams(prepare)
        calculationID = id
        this.applyData({ 'order.calculationID': id })
      }

      const r = await new Promise(async (resolve, reject) => {
        try {
          if (!isClearGet) {
            intervalID = setInterval(async () => {
              const res = await PickupProvider.getMyOffers(this.order.id, calculationID)
              if (res.isFinished) {
                clearInterval(intervalID)
                resolve(res)
              }
            }, 750)
            setTimeout(() => {
              if (_.isEmpty(this.offers)) {
                clearInterval(intervalID)
                resolve({
                  offers: [],
                  initialPayment: null,
                  term: null
                })
              }
            }, 30000)
          } else {
            const res = await PickupProvider.getMyOffers(this.order.id, calculationID)
            resolve(res)
          }
        } catch (e) {
          if (intervalID) clearInterval(intervalID)
          reject(e)
        }
      })

      this.setOffers(r.offers)
      if (isClearGet) {
        this.applyData(safeObject({
          'pickupInfo.initialPayment': r.initialPayment,
          'pickupInfo.term': r.term
        }))
      }
      this.applyData({
        isEmptyOffers: _.isEmpty(r.offers)
      })
    } catch (e) {
      notifyError('Ошибка получения кредитных продуктов заказа')

      if (intervalID) clearInterval(intervalID)
      this.setOffers([])
      this.applyData({
        isEmptyOffers: true
      })
    } finally {
      this.setIsLoading(false)
    }
  }

  @action setOrder = order => {
    this.applyData({
      order
    })
  }

  @action setPickupInfo = ({ initialPayment, term }) => {
    this.applyData({
      'pickupInfo.initialPayment': initialPayment,
      'pickupInfo.term': term
    })
  }

  back = () => {
    window.location = this.order.orderUrl
  }

  @computed get isSelectOffers() {
    return this.order.state === 'new'
  }

  selectOffers = async () => {
    if (!this.isSelectOffers) return
    await PickupProvider.selectOffer(this.order.id, this.order.calculationID, fp.map(o => o.id)(_.uniqBy(this.offers, 'bankName')))
    this.applyData({
      'order.state': 'calculated'
    })
  }

  next = async () => {
    try {
      await this.selectOffers()
      menuModel.changeStep(1, orderHistory('/main_data'))
    } catch (e) {
      notifyError('Ошибка выбора предложения. Попробуйте еще раз.')
    }
  }

  setCallCenterCall = () => {
    this.applyData({ isCallCenterCall: true })
    history.push(`/form/${this.order.id}/call_center`)
  }
}

export { PickupModel }
export default new PickupModel()
