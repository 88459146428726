import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { Input, Block } from 'uikit'
import { OTHER_DATA_HTML_ID } from 'utils/htmlID'

const KeyWord = ({ questionaryModel, responsiveModel }) => {
  const {
    keyWord,

    isExistOtherDataStep, keyWordValidationEnabled
  } = questionaryModel
  const { isTabletAndPhone } = responsiveModel

  return (
    <Fragment>
      <Block
        width={!isTabletAndPhone ? 16 : '100%'}
        marginTop={-1}
        marginBottom={17}
      >
        <Input
          key="keyWord"
          label="Кодовое слово"
          value={keyWord}
          onChange={questionaryModel.setTrimField('keyWord')}
          error={questionaryModel.validKeyword}
          help="Девичья фамилия матери"
          tabIndex={1}
          isShowForceValidate={isExistOtherDataStep || keyWordValidationEnabled}
          onBlur={questionaryModel.enableIsChangeOtherDataEight}
          htmlID={`${OTHER_DATA_HTML_ID}-keyWord`}
        />
      </Block>
    </Fragment>
  )
}

export default inject(store => ({
  questionaryModel: store.questionaryModel,
  responsiveModel: store.responsiveModel
}))(observer(KeyWord))
