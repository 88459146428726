import _ from 'lodash'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import { inject, observer } from 'mobx-react'
import { Block, Image, Loader, Badge, Text, Icon, num2str } from 'uikit'
import { orderHistory } from 'utils/history'
import Orders from './Orders'
import Item from './Item'

const animationFunc = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
`

const animation = css`
  animation: ${animationFunc} 0.3s ease-in-out;
`

const Root = styled(props => {
  const resolveProps = { ...props }
  _.unset(resolveProps, 'isOffCanvas')
  return <Block {...resolveProps}/>
})`
  ${props => (props.isOffCanvas ? animation : 'animation: none;')}
  position: fixed;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100%;
  z-index: ${props => (props.isOffCanvas ? 1000 : 'auto')};
`

const ResultDescription = styled(Text)`
  font-weight: 500;
`

const Menu = ({ menuModel, pickupModel, questionaryModel, applicationModel, isOffCanvas }) => {
  const { step } = menuModel
  const { order } = pickupModel

  const creditLetter = num2str(pickupModel.offers.length, ['предложение', 'предложения', 'предложений'])

  const emptyField = (count, key = 'empty') => (
    <Text key={key} marginRight={0.2} type="danger">
      {count} не заполнено
    </Text>
  )

  const notCallCenter = () => !pickupModel.isTargetCallCenter

  return (
    <Root
      marginRight={288}
      width={288}
      background="#ebedef"
      isRem={false}
      isOffCanvas={isOffCanvas}
    >
      {!_.isEmpty(order.orders) ?
        <Fragment>
          <Block isCenter padding={24} isRem={false}>
            <Image src={pickupModel.order.logo} height={2.85}/>
          </Block>
          <Orders/>
          {notCallCenter() && <Item
            title="Расчёт кредита"
            description={(() => {
              if (pickupModel.pickupInfo.isLoading) {
                return <Text type="secondary"><Loader size={1} isColor={false}/> Расчёт...</Text>
              }
              if (!_.isEmpty(pickupModel.offers)) return `Подобрано ${pickupModel.offers.length} ${creditLetter}`

              return 'Расчёт не выполнен'
            })()
            }
            isActive={step === 0}
            isTopDivider
            onClick={!questionaryModel.isFill ? () => menuModel.changeStep(0, orderHistory('/pickup')) : null}
            htmlID="menu-pickup"
          />}
          <Item
            title="Основные данные"
            isActive={step === 1}
            description={(() => {
              if (questionaryModel.isNextMainDataLoading) {
                return <Text type="secondary"><Loader size={1} isColor={false}/> Сохранение...</Text>
              }

              if (questionaryModel.isChangeMainData && step === 1) {
                const errors = []
                if (questionaryModel.mainDataEmptyCount) errors.push(emptyField(questionaryModel.mainDataEmptyCount))

                if (!_.isEmpty(errors)) {
                  return errors
                }

                return !questionaryModel.mainDataErrorCount ? (
                  <Text type="secondary">
                    Указаны верно <Icon iconType="design" icon="check-circle" color="successLight"/>
                  </Text>
                ) : null
              }

              if (step !== 1 && questionaryModel.isChangeMainData && (questionaryModel.mainDataEmptyCount + questionaryModel.mainDataErrorCount) === 0) {
                return (
                  <Text type="secondary">
                    Полностью заполнено <Icon iconType="design" icon="check-circle" color="successLight"/>
                  </Text>
                )
              }

              return 'Не указаны'
            })()}
            onClick={(pickupModel.isNext || step > 1 || questionaryModel.isExist)
            && !questionaryModel.isFill && !pickupModel.isSelectOffers ?
              () => menuModel.changeStep(1, orderHistory('/main_data')) : null
            }
            htmlID="menu-main-data"
          />
          {notCallCenter() && <Item
            title="Дополнительные данные"
            isActive={step === 2}
            description={(() => {
              if (questionaryModel.isNextAdditionalDataLoading) {
                return <Text type="secondary"><Loader size={1} isColor={false}/> Сохранение...</Text>
              }

              if (questionaryModel.isChangedAdditionalData && step === 2) {
                const errors = []
                if (questionaryModel.additionalDataEmptyCount) errors.push(emptyField(questionaryModel.additionalDataEmptyCount))

                if (!_.isEmpty(errors)) {
                  return errors
                }

                return !questionaryModel.additionalDataErrorCount ? (
                  <Text type="secondary">
                    Указаны верно <Icon iconType="design" icon="check-circle" color="successLight"/>
                  </Text>
                ) : null
              }

              if (step !== 2 &&
                (questionaryModel.isChangeAdditionalDataOne || questionaryModel.isChangeAdditionalDataTwo) &&
                questionaryModel.additionalDataProgress > 0
              ) {
                return questionaryModel.additionalDataProgress < 100 ? (
                  <Text type="secondary">
                    Заполнено {questionaryModel.additionalDataProgress}%
                  </Text>
                ) : (
                  <Text type="secondary">
                    Полностью заполнено <Icon iconType="design" icon="check-circle" color="successLight"/>
                  </Text>
                )
              }

              return 'Не указаны'
            })()}
            onClick={(step > 2 || questionaryModel.isNextMainData) && !questionaryModel.isFill ?
              () => menuModel.changeStep(2, orderHistory('/additional_data')) :
              null
            }
            htmlID="menu-additional-data"
          />}
          {notCallCenter() && <Item
            title="Прочие сведения"
            description={(() => {
              if (questionaryModel.isNextOtherDataLoading) {
                return <Text type="secondary"><Loader size={1} isColor={false}/> Сохранение...</Text>
              }

              if (questionaryModel.isChangedOtherData && step === 3) {
                const errors = []
                if (questionaryModel.otherDataEmptyCount) errors.push(emptyField(questionaryModel.otherDataEmptyCount))

                if (!_.isEmpty(errors)) {
                  return errors
                }

                return !questionaryModel.otherDataErrorCount ? (
                  <Text type="secondary">
                    Указаны верно <Icon iconType="design" icon="check-circle" color="successLight"/>
                  </Text>
                ) : null
              }

              if (step !== 3 && (questionaryModel.isChangeOtherDataTwo ||
                  questionaryModel.isChangeOtherDataThree || questionaryModel.isChangeOtherDataFour ||
                  questionaryModel.isChangeOtherDataFive || questionaryModel.isChangeOtherDataSix
              ) &&
                questionaryModel.otherDataProgress > 0
              ) {
                return questionaryModel.otherDataProgress < 100 ? (
                  <Text type="secondary">
                    Заполнено {questionaryModel.otherDataProgress}%
                  </Text>
                ) : (
                  <Text type="secondary">
                    Полностью заполнено <Icon iconType="design" icon="check-circle" color="successLight"/>
                  </Text>
                )
              }

              return 'Не указаны'
            })()}
            isActive={step === 3}
            onClick={questionaryModel.isNextAdditionalData && questionaryModel.isNextMainData && !questionaryModel.isFill ?
              () => menuModel.changeStep(3, orderHistory('/other_data')) :
              null
            }
            htmlID="menu-other-data"
          />}
          {notCallCenter() && <Item
            title="Результаты рассмотрения"
            isActive={step === 4}
            description={(() => {
              if (_.isEmpty(applicationModel.banks)) return <Text type="secondary">Отсутствуют</Text>

              const countPositive = _.filter(applicationModel.applications, a => a.isPositive)
              if (countPositive > 0) {
                return (
                  <Fragment>
                    <Text type="secondary">Вы получили </Text>
                    <Block isInline>
                      <Badge label={countPositive} background="successLight"/>
                    </Block>
                    <Text type="secondary"> {num2str(countPositive, ['одобрение', 'одобрения', 'одобрений'])}!</Text>
                  </Fragment>
                )
              }

              return null
            })()}
            subItems={_.map(applicationModel.banks, bank => ({
              title: bank.bankName,
              description: (
                <ResultDescription
                  type={(() => {
                    if (bank.isPositive) return 'successBase'
                    if (bank.isNegative) return 'danger'

                    return 'secondary'
                  })()}
                >
                  {(() => {
                    if (bank.isPositive) return 'Одобрено!'
                    if (bank.isNegative) return 'Отказ'

                    return 'Ожидание'
                  })()}
                </ResultDescription>
              )
            }))}
            onClick={applicationModel.isExist ?
              () => menuModel.changeStep(4, orderHistory('/credit_result', order.id)) : null}
            htmlID="menu-result-credit"
          />}
        </Fragment> :
        <Block isCenter height={7}>
          <Loader isColor={false}/>
        </Block>
      }
    </Root>
  )
}

Menu.propTypes = {
  isOffCanvas: PropTypes.bool
}

Menu.defaultProps = {
  isOffCanvas: false
}

export default inject(store => ({
  menuModel: store.menuModel,
  pickupModel: store.pickupModel,
  questionaryModel: store.questionaryModel,
  applicationModel: store.applicationModel
}))(observer(Menu))
