import fp from 'lodash/fp'
import _ from 'lodash'
import { bankLogoMap, bankNameMap } from 'providers/helpers/bankLogo'
import BaseHttpProvider from '../base-http-provider'

class ApiProvider extends BaseHttpProvider {
  defaultApiPrefix = '/api/v1'

  async getApplications(orderID) {
    const { data } = await this.execute({
      uri: `/orders/${orderID}/applications`
    })
    return {
      banks: fp.flow(
        fp.map(d => ({
          id: _.get(d, 'bank.id', ''),
          bankLogo: bankLogoMap(_.get(d, 'bank.system_name', '')),
          bankName: bankNameMap(_.get(d, 'bank.system_name', '')),
          statusText: _.get(d, 'human_common_state', ''),
          isPositive: _.get(d, 'common_state') === 'positive',
          isNegative: _.get(d, 'common_state') === 'negative',
          isProgress: _.get(d, 'common_state') !== 'positive' &&
            _.get(d, 'common_state') !== 'negative',
          issueID: _.get(d, 'issue_id', '')
        }))
      )(data),
      applications: _.flatten(_.map(data, d => _.map(_.get(d, 'offers', []), application => ({
        id: _.get(application, 'id', ''),
        bankLogo: bankLogoMap(_.get(d, 'bank.system_name', '')),
        bankName: bankNameMap(_.get(d, 'bank.system_name', '')),
        calculationResultID: _.get(d, 'calculation_result_id', null),
        initialPayment: Number(_.get(application, 'initial_payment', 0)),
        code: _.get(application, 'credit_product.code', null),
        initialPaymentPercent: Number(_.get(application, 'initial_payment_percent', 0)),
        term: Number(_.get(application, 'term', 0)),
        monthlyPayment: Number(_.get(application, 'monthly_payment', 0)),
        paymentsSum: Number(_.get(application, 'payments_sum', 0)),
        slots: _.get(application, 'enabled_slot_ids', []).slice(0, 6),
        disabledSlots: _.get(application, 'disabled_slot_ids', []).slice(0, 6),
        forcedSlots: _.get(application, 'forced_slot_ids', []).slice(0, 6),
        enabledProviderSlots: _.map(_.get(application, 'enabled_provider_slots', []).slice(0, 6), s => ({
          id: _.get(s, 'id', ''),
          name: _.get(s, 'name', ''),
          description: _.get(s, 'description', ''),
          icon: _.get(s, 'icon', '')
        })),
        statusText: _.get(d, 'human_common_state', ''),
        isPositive: _.get(d, 'common_state') === 'positive',
        isNegative: _.get(d, 'common_state') === 'negative',
        isProgress: _.get(d, 'common_state') !== 'positive' &&
          _.get(d, 'common_state') !== 'negative',
        issueID: _.get(d, 'issue_id', '')
      }))))
    }
  }

  async sendCreditApplication(orderID, offerID) {
    const { data } = await this.execute({
      uri: `/orders/${orderID}/offers/${offerID}/select`,
      method: 'PUT'
    })
    return {
      selectedAt: new Date(_.get(data, 'selected_at', '') || new Date()),
      issueID: _.get(data, 'issue_id', '')
    }
  }
}

export { ApiProvider }
export default new ApiProvider()
