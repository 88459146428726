import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Block, Button, Col, hexToRgba, Modal, moneyRubNormalizer, Paragraph, Row, Text, Title
} from 'uikit'
import { CREDIT_RESULT_HTML_ID } from 'utils/htmlID'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

const Tarif = styled(Block)`
  padding: 1.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 8px;
  width: 100%;
  background: ${props => hexToRgba(props.theme.colors.darkSkyBlue, 0.10)};
`

const TarifLabel = styled(Paragraph)`
  line-height: 1;
`

const Hint = styled(Block)`
  background: ${props => props.theme.colors.gray1};
  margin-top: 2rem;
  margin-left: -48px;
  margin-right: -48px;
  padding-top: 1rem;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  
  ${props => props.theme.viewPorts.mobile} {
    margin-left: 0;
    margin-right: 0;
    padding-left: 24px;
    padding-right: 24px;
  }
`

const ModalSelect = ({
  applicationModel, responsiveModel,
  isOpenDescription, setIsOpenDescription, application
}) => {
  const isExistAlternate = !!application.alternate

  return (
    <Modal
      isOpen={isOpenDescription}
      isCenter
      title={
        <Block isCenter width="100%">
          <Title
            level={4} marginBottom={!responsiveModel.isTabletAndPhone ? -1.5 : -1}
          >
            {!responsiveModel.isTabletAndPhone ? 'Подтверждение условий' :
              <Fragment>
                <Paragraph isNativeMargin={false} align="center" style={{ fontWeight: 500 }}>
                  Подтверждение
                </Paragraph>
                <Paragraph isNativeMargin={false} align="center" style={{ fontWeight: 500 }}>
                  условий
                </Paragraph>
              </Fragment>
            }
          </Title>
        </Block>
      }
      width="40rem"
      onCancel={() => setIsOpenDescription(false)}
      footer={
        <Block isCenter>
          <Block>
            <Button
              type="primary"
              onClick={async () => {
                setIsOpenDescription(false)
                await applicationModel.sendApplication(application.id)
              }}
              marginBottom={1}
              htmlID={`${CREDIT_RESULT_HTML_ID}-application-send-${application.id}`}
            >
              Подтвердить выбор условий
            </Button>
            <Block
              onClick={() => setIsOpenDescription(false)}
              isCenter
              marginBottom={1}
              htmlID={`${CREDIT_RESULT_HTML_ID}-application-accept-${application.id}`}
            >
              <Text type="darkBlue">Отменить</Text>
            </Block>
          </Block>
        </Block>
      }
    >
      <Block isCenter marginBottom={2}>
        <Text type="secondary">{application.bankName}</Text>
      </Block>
      <Row isFlex justify="space-between">
        <Col
          width={!responsiveModel.isTabletAndPhone ? '33%' : '50%'}
          height={!isExistAlternate ? 11 : 12}
        >
          <Tarif height={!isExistAlternate ? 8 : 12}>
            <Block>
              <TarifLabel marginBottom={1}>
                <Paragraph marginBottom={0.2} isStrong size={1.2}>
                  Срок
                </Paragraph>
                <Paragraph isNativeMargin={false} isStrong size={1.2}>
                  кредита
                </Paragraph>
              </TarifLabel>
              <Paragraph isNativeMargin={false}>
                <Paragraph marginBottom={isExistAlternate ? 0.1 : 0} size={1.2}>
                  {application.term} мес.
                </Paragraph>
                {isExistAlternate &&
                <Fragment>
                  <Paragraph marginBottom={0.1} type="secondary" >или</Paragraph>
                  <Paragraph isNativeMargin={false} size={1.2}>рассрочка {application.alternate.term} мес.</Paragraph>
                </Fragment>
                }
              </Paragraph>
            </Block>
          </Tarif>
        </Col>
        <Col width={!responsiveModel.isTabletAndPhone ? '33%' : '50%'} height={!isExistAlternate ? 11 : 12}>
          <Tarif height={!isExistAlternate ? 8 : 12}>
            <Block>
              <TarifLabel marginBottom={1}>
                <Paragraph marginBottom={0.2} isStrong size={1.2}>
                  Первонач.
                </Paragraph>
                <Paragraph isNativeMargin={false} isStrong size={1.2}>
                  взнос
                </Paragraph>
              </TarifLabel>
              <Paragraph isNativeMargin={false} size={1.2}>
                <Text>{moneyRubNormalizer(application.initialPayment)} </Text>
                <Text>₽</Text>
              </Paragraph>
            </Block>
          </Tarif>
        </Col>
        <Col
          width={!responsiveModel.isTabletAndPhone ? '33%' : '100%'}
          height={!responsiveModel.isTabletAndPhone ? !isExistAlternate ? 8 : 12 : !isExistAlternate ? 6 : 9.5}
          marginTop={responsiveModel.isTabletAndPhone ? 1 : 0}
        >
          <Tarif height={!responsiveModel.isTabletAndPhone ? !isExistAlternate ? 8 : 12 : !isExistAlternate ? 6 : 9.5}>
            <Block>
              <TarifLabel marginBottom={1}>
                {!responsiveModel.isTabletAndPhone ?
                  <Fragment>
                    <Paragraph marginBottom={0.2} isStrong size={1.2}>
                      Ежемес.
                    </Paragraph>
                    <Paragraph isNativeMargin={false} isStrong size={1.2}>
                      платеж
                    </Paragraph>
                  </Fragment> :
                  <Fragment>
                    <Paragraph isNativeMargin={false} isStrong size={1.2}>
                      Ежемесячный платеж
                    </Paragraph>
                  </Fragment>
                }
              </TarifLabel>
              <Paragraph isNativeMargin={false}>
                <Paragraph marginBottom={isExistAlternate ? 0.1 : 0} size={1.2}>
                  {moneyRubNormalizer(Math.floor(application.monthlyPayment))} <Text type="secondary">₽</Text>
                </Paragraph>
                {isExistAlternate &&
                <Fragment>
                  <Paragraph marginBottom={0.1} type="secondary">или</Paragraph>
                  <Paragraph isNativeMargin={false} size={1.2}>
                    {moneyRubNormalizer(Math.floor(application.alternate.monthlyPayment))} <Text type="secondary">₽</Text>
                  </Paragraph>
                </Fragment>
                }
              </Paragraph>
            </Block>
          </Tarif>
        </Col>
      </Row>
      <Hint>
        <Paragraph isNativeMargin={false}>
          После подтверждения условий кредита отменить выбор можно будет только на стороне магазина.
        </Paragraph>
      </Hint>
    </Modal>
  )
}

ModalSelect.propTypes = {
  application: PropTypes.object,
  isOpenDescription: PropTypes.bool,
  setIsOpenDescription: PropTypes.func
}

export default inject(store => ({
  responsiveModel: store.responsiveModel,
  applicationModel: store.applicationModel
}))(observer(ModalSelect))
