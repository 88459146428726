import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTheme } from 'styled-components'

export const DesktopMinContent = withTheme(inject('responsiveModel')(observer(({ responsiveModel, theme, children }) => {
  const { width } = responsiveModel

  return width >= theme.breakPoints.tablet && width < theme.breakPoints.desktop ? children : null
})))

export const DesktopMaxContent = withTheme(inject('responsiveModel')(observer(({ responsiveModel, theme, children }) => {
  const { width } = responsiveModel

  return width >= theme.breakPoints.desktop ? children : null
})))
