import _ from 'lodash'
import React, { Fragment } from 'react'
import { Block, Paragraph, Title, Text, Collapse, CollapsePanel, moneyRubNormalizer } from 'uikit'
import styled, { withTheme } from 'styled-components'
import { inject, observer } from 'mobx-react'

const Order = styled(props => {
  const resolveProps = { ...props }
  _.unset(resolveProps, 'isLast')
  return <Block {...resolveProps}/>
})`
  border-bottom: ${props => (!props.isLast ? `.07rem solid ${props.theme.colors.gray3}` : 'none')};
  display: flex;
  justify-content: space-between;
  padding: 1rem 24px 1rem 0;
`

const Orders = ({ pickupModel, theme }) => {
  const { order, resolveOrders } = pickupModel

  const handleExpand = v => {
    pickupModel.setOrderExpand(!_.isEmpty(v))
  }

  return (
    <Block paddingLeft={24} isRem={false}>
      <Collapse
        activeKey={pickupModel.isOrderExpand ? '1' : []}
        onChange={handleExpand}
        arrowPlacement="right"
        isDivider={false}
        isPadding={false}
        expandIconProps={{
          style: { marginRight: '.5rem', marginTop: '0.1rem' },
          color: theme.colors.gray4,
          size: 1.2
        }}
        htmlID="menu-orders"
      >
        <CollapsePanel
          header={
            <Fragment>
              <Paragraph type="secondary" marginBottom={0.1}>
                Заказ № {order.numberOrder}
              </Paragraph>
              <Title level={4} marginTop={0} marginBottom={1}>
                Итого {moneyRubNormalizer(order.payment)} ₽
              </Title>
            </Fragment>
          }
          key="1"
        >
          {_.map(resolveOrders, (r, index) => (
            <Order
              isFlex
              justify="space-between"
              isLast={index === resolveOrders.length - 1}
              key={index}
              marginTop={index === 0 ? -1 : 0}
            >
              <Paragraph width="59%" isNativeMargin={false}>{r.name}</Paragraph>
              <Block>
                <Paragraph isNativeMargin={false} align="right">
                  {moneyRubNormalizer(r.cost * r.count)}  ₽
                </Paragraph>
                {r.count > 1 &&
                <Paragraph isNativeMargin={false} type="secondary" align="right">
                  {r.count} × {moneyRubNormalizer(r.cost)} ₽
                </Paragraph>
                }
              </Block>
            </Order>
          ))}
          {!pickupModel.isOrderMoreExpand && pickupModel.order.orders.length > 2 &&
          <Block
            isCenter
            isRem={false}
            marginBottom={14}
            marginLeft={-24}
            onClick={() => pickupModel.expandMoreOrders()}
          >
            <Text type="secondary" size={1.2} isStrong>...</Text>
          </Block>
          }
        </CollapsePanel>
      </Collapse>
    </Block>
  )
}

export default withTheme(inject('pickupModel')(observer(Orders)))
