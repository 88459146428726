import applicationModel from 'models/applicationModel'
import pickupModel from 'models/pickupModel'

export default {
  onReceived: async ({ data, options, close }) => {
    if (pickupModel.order.id) {
      await applicationModel.getApplications(pickupModel.order.id)
    }
  }
}
