import QuestionaryProvider from 'providers/api-provider/questionary-provider'
import menuModel from 'models/menuModel'
import { Mixin } from 'mixwith'
import history, { orderHistory } from 'utils/history'
import { notifyError, getDateByAge, validRequired, isSafe } from 'uikit'
import { action, computed, observable } from 'mobx'
import _ from 'lodash'
import { validRussianWord } from 'utils/validators/russian'
import { validPassportDate } from 'utils/validators/passport'
import validator from 'validator'
import SdkProvider from 'providers/sdk-provider'
import pickupModel from '../pickupModel'

const MainData = Mixin(Super => class extends Super {
  confirmCodeInterval = 30

  @observable id = null
  @observable firstName = ''
  @observable lastName = ''
  @observable patronymic = ''
  @observable sex = null
  @observable birthDay = null
  @observable passport = ''
  @observable passportDay = null
  @observable income = 0
  @observable mobilePhone = ''
  @observable confirmCode = ''
  @observable contactEmail = ''

  @observable confirmCodeID = ''

  @observable isSendConfirmCode = false
  @observable isGetConfirmCodeLoading = false
  @observable isTrySendConfirmCode = true
  @observable confirmCodeTime = this.confirmCodeInterval
  @observable isConfirmPhone = false
  @observable isErrorConfirmCode = false
  @observable isChangeMainData = false
  @observable existMainDataStep = false

  @observable isNextMainDataLoading = false
  @observable isCallMeLoading = false
  @observable mainDataValidationEnabled = false


  @computed get isExist() {
    return !!this.id
  }

  @action enableIsChangeMainData = () => {
    this.isChangeMainData = true
  }

  setFio = field => action(value => {
    this[field] = value
  })

  trimField = field => action(() => {
    this[field] = this[field] ? this[field].trim() : ''
  })

  @action setMobilePhone = v => {
    if (v === this.mobilePhone) return
    this.mobilePhone = v
    SdkProvider.updatePhone(v)
    this.confirmCode = ''
    this.isSendConfirmCode = false
    this.isTrySendConfirmCode = false
    this.confirmCodeTime = this.confirmCodeInterval
    this.isConfirmPhone = false
    this.isErrorConfirmCode = false
  }

  @action setConfirmCode = v => {
    this.confirmCode = v
    this.isErrorConfirmCode = false
  }

  @action enableMainDataValidation = () => {
    this.mainDataValidationEnabled = true
  }

  @computed get validFirstName() {
    if (this.firstName === '') return 'Обязательное поле'
    if (validRussianWord(this.firstName)) return validRussianWord(this.firstName)

    return null
  }

  @computed get validLastName() {
    if (this.lastName === '') return 'Обязательное поле'
    if (validRussianWord(this.lastName)) return validRussianWord(this.lastName)

    return null
  }

  @computed get validPatronymic() {
    if (validRussianWord(this.patronymic)) return validRussianWord(this.patronymic)

    return null
  }

  @computed get validSex() {
    return validRequired(this.sex)
  }

  @computed get validBirthDay() {
    if (validRequired(this.birthDay)) return validRequired(this.birthDay)
    if (+this.birthDay > +getDateByAge(18) ||
      +this.birthDay < +getDateByAge(100) ||
    +this.birthDay >= +new Date()) return 'Неподходящий возраст'

    return null
  }

  @computed get validPassport() {
    if (this.passport === '') return 'Обязательное поле'

    if (this.passport.length < 4) return 'Неверная серия'
    if (this.passport.length < 10) return 'Неверный номер'

    return null
  }

  @computed get validPassportDay() {
    if (validRequired(this.passportDay)) return validRequired(this.passportDay)
    if (+this.passportDay >= +new Date()) return 'Некорректная дата'
    if (validPassportDate(this.passportDay, this.birthDay)) return validPassportDate(this.passportDay, this.birthDay)

    return null
  }

  @computed get validIncome() {
    if (this.income === '') return 'Обязательное поле'
    if (this.income <= 0) return 'Должно быть больше нуля'
    return null
  }

  @computed get validMobilePhone() {
    if (!this.mobilePhone) return 'Обязательное поле'
    if (this.mobilePhone.length !== 10) return 'Неверный формат'
    if (_.get(this.mobilePhone.replace(/[\s()-]*/gi, ''), '0') !== '9') return 'Начинается не с 9'

    return null
  }

  @computed get validContactEmail() {
    if (this.contactEmail === '') return 'Обязательное поле'
    if (!validator.isEmail(this.contactEmail)) return 'Некорректный e-mail'

    return null
  }

  @computed get mainDataEmptyCountCallCenter() {
    let count = 0
    if (this.firstName === '') count += 1
    if (this.lastName === '') count += 1
    if (!this.mobilePhone) count += 1
    return count
  }

  @computed get mainDataEmptyCount() {
    if (pickupModel.isTargetCallCenter) {
      return this.mainDataEmptyCountCallCenter
    }
    let count = 0
    if (this.firstName === '') count += 1
    if (this.lastName === '') count += 1
    if (!isSafe(this.sex)) count += 1
    if (!isSafe(this.birthDay)) count += 1
    if (this.passport === '') count += 1
    if (!isSafe(this.passportDay)) count += 1
    if (this.income === '') count += 1
    if (!this.mobilePhone) count += 1
    if (this.contactEmail === '') count += 1
    return count
  }

  @computed get mainDataErrorCountCallCenter() {
    let count = 0
    if (this.validFirstName) count += 1
    if (this.validLastName) count += 1
    if (this.validPatronymic) count += 1
    if (this.validMobilePhone) count += 1
    if (!this.isConfirmPhone) count += 1

    const res = count - this.mainDataEmptyCountCallCenter
    return res > 0 ? res : 0
  }

  @computed get mainDataErrorCount() {
    if (pickupModel.isTargetCallCenter) {
      return this.mainDataErrorCountCallCenter
    }
    let count = 0
    if (this.validFirstName) count += 1
    if (this.validLastName) count += 1
    if (this.validPatronymic) count += 1
    if (this.validSex) count += 1
    if (this.validBirthDay) count += 1
    if (this.validPassport) count += 1
    if (this.validPassportDay) count += 1
    if (this.validIncome) count += 1
    if (this.validMobilePhone) count += 1
    if (!this.isConfirmPhone) count += 1
    if (this.validContactEmail) count += 1

    const res = count - this.mainDataEmptyCount
    return res > 0 ? res : 0
  }

  @computed get isCanCallMe() {
    return this.mainDataEmptyCountCallCenter === 0 && this.mainDataErrorCountCallCenter === 0
  }

  @computed get isNextMainData() {
    return this.mainDataEmptyCount === 0 && this.mainDataErrorCount === 0
  }

  @computed get isExistMainDataStep() {
    return (
      this.isChangeMainData && this.existMainDataStep
    )
  }

  @action addToExistMainDataStep = () => {
    this.existMainDataStep = true
  }

  runConfirmCodeTimer = () => {
    if (this.interval) {
      clearInterval(this.interval)
    }
    this.applyData({
      confirmCodeTime: this.confirmCodeInterval
    })

    this.interval = setInterval(() => {
      if (this.confirmCodeTime - 1 <= 0) {
        clearInterval(this.interval)
        this.applyData({
          confirmCodeTime: this.confirmCodeInterval,
          isTrySendConfirmCode: true
        })
      } else {
        this.applyData({
          confirmCodeTime: this.confirmCodeTime - 1
        })
      }
    }, 1000)
  }

  sendConfirmPhone = async () => {
    this.applyData({ isGetConfirmCodeLoading: true })

    try {
      const { confirmCodeID } = await QuestionaryProvider.sendConfirmPhone(this.mobilePhone, pickupModel.order.id)
      this.applyData({
        isSendConfirmCode: true,
        isTrySendConfirmCode: false,
        confirmCodeID
      })
      this.runConfirmCodeTimer()
    } catch (e) {
      notifyError('Ошибка отправки кода подтверждения')
    } finally {
      this.applyData({ isGetConfirmCodeLoading: false })
    }
  }

  trySendConfirmPhone = async () => {
    this.applyData({
      isConfirmPhone: false,
      isTrySendConfirmCode: false,
      confirmCode: false,
      confirmCodeID: '',
      isErrorConfirmCode: false
    })
    await this.sendConfirmPhone()
  }

  sendConfirmCode = async () => {
    this.applyData({ isGetConfirmCodeLoading: true })
    try {
      const confirmKey = await QuestionaryProvider.sendConfirmCode(this.confirmCode, this.mobilePhone, pickupModel.order.id, this.confirmCodeID)
      this.applyData({
        isConfirmPhone: true
      })
      SdkProvider.setConfirm(confirmKey)
    } catch (e) {
      this.applyData({ isErrorConfirmCode: true })
    } finally {
      this.applyData({ isGetConfirmCodeLoading: false })
    }
  }

  backMainData = () => {
    menuModel.changeStep(0, orderHistory('/pickup'))
  }

  nextMainData = async () => {
    try {
      this.applyData({ isNextMainDataLoading: true })
      const prepare = {
        lastName: this.lastName,
        firstName: this.firstName,
        patronymic: this.patronymic,
        sex: this.sex,
        birthDay: this.birthDay,
        mobilePhone: this.mobilePhone,
        passport: this.passport,
        passportDay: this.passportDay,
        income: this.income,
        confirmCodeID: this.confirmCodeID,
        contactEmail: this.contactEmail
      }
      if (!this.isExist) {
        const { id } = await QuestionaryProvider.createQuestionary({ ...prepare, orderID: pickupModel.order.id })
        this.applyData({ id })
      } else {
        await QuestionaryProvider.updateQuestionary({
          questionary: prepare,
          orderID: pickupModel.order.id,
          subStep: 'main_data'
        })
      }
      menuModel.changeStep(2, orderHistory('/additional_data'))
    } catch (e) {
      notifyError('Ошибка сохранения основных данных')
    } finally {
      this.applyData({ isNextMainDataLoading: false })
    }
  }

  sendCallMe = async () => {
    try {
      this.applyData({ isCallMeLoading: true })
      const params = {
        lastName: this.lastName,
        firstName: this.firstName,
        patronymic: this.patronymic,
        mobilePhone: this.mobilePhone,
        confirmCodeID: this.confirmCodeID
      }
      await QuestionaryProvider.sendCallMe({
        orderID: pickupModel.order.id,
        params
      })
      history.push(`/form/${pickupModel.order.id}/call_center`)
    } catch (e) {
      notifyError('Ошибка отправки в контактный центр')
    } finally {
      this.applyData({ isCallMeLoading: false })
    }
  }
})

export default MainData
