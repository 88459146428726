import _ from 'lodash'
import fp from 'lodash/fp'
import React from 'react'
import { inject, observer } from 'mobx-react'
import { Image, Block, Row, Col, Divider, Paragraph, Title, safeObject } from 'uikit'

const Progress = ({ applicationModel, responsiveModel, questionaryModel }) => (
  <Block marginTop={!responsiveModel.isPhone ? 3 : 2}>
    <Title level={3} marginBottom={2}>
      Ожидание
    </Title>
    {fp.map(application => (
      <Block
        height={!responsiveModel.isTabletAndPhone ? 101 : 'auto'}
        isRem={false}
        key={application.id}
        borderRadius={responsiveModel.isTabletAndPhone ? 8 : 0}
        padding={responsiveModel.isTabletAndPhone ? 16 : 0}
        marginBottom={responsiveModel.isTabletAndPhone ? '0.7rem' : 0}
        background={responsiveModel.isTabletAndPhone ? 'gray1' : 'transparent'}
      >
        <Row
          align="middle"
          isRem={false}
          height={!responsiveModel.isTabletAndPhone ? 88 : 'auto'}
          {...safeObject({
            isFlex: !responsiveModel.isTabletAndPhone || null
          })}
        >
          <Col md={!responsiveModel.isTabletAndPhone ? 3 : 12} marginTop={1}>
            <Image src={application.bankLogo} width="8rem"/>
          </Col>
          <Col md={!responsiveModel.isTabletAndPhone ? 8 : 12} marginTop={1}>
            <Paragraph isNativeMargin={false} type="secondary">
              {!questionaryModel.isFill ? 'Требуется больше данных.' : 'Запрос направлен.'}
            </Paragraph>
            {!questionaryModel.isFill ?
              <Paragraph
                isNativeMargin={false}
                type="darkBlue"
                isStrong
                onClick={() => questionaryModel.continueFillQuestionary()}
              >
                Продолжайте заполнять анкету
              </Paragraph> :
              <Paragraph isNativeMargin={false} type="secondary">
                Ожидайте решения
              </Paragraph>
            }
          </Col>
        </Row>
        {!responsiveModel.isTabletAndPhone && <Divider marginBottom={0} marginTop={1}/>}
      </Block>
    ))(applicationModel.progressApplications)}
  </Block>
)

export default inject(store => ({
  applicationModel: store.applicationModel,
  responsiveModel: store.responsiveModel,
  questionaryModel: store.questionaryModel
}))(observer(Progress))
