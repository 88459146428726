import _ from 'lodash'
import fp from 'lodash/fp'
import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import {
  Block, Image, Row, Col, Divider, Paragraph, Title, darken, hexToRgba, safeObject
} from 'uikit'
import { withTheme } from 'styled-components'

const Negative = ({ applicationModel, responsiveModel, theme }) => (
  <Block marginTop={!responsiveModel.isPhone ? 3 : 2}>
    <Title level={3} marginBottom={2}>
      Отказ
    </Title>
    {fp.map(application => (
      <Fragment>
        <Block
          key={application.id}
          height={!responsiveModel.isTabletAndPhone ? 101 : 'auto'}
          isRem={false}
          background={hexToRgba(darken(theme.colors.danger, 0.15), 0.08)}
          borderRadius={responsiveModel.isTabletAndPhone ? 8 : 0}
          padding={responsiveModel.isTabletAndPhone ? 16 : 0}
          marginBottom={responsiveModel.isTabletAndPhone ? '0.7rem' : 0}
        >
          <Row
            align="middle"
            isRem={false}
            height={!responsiveModel.isTabletAndPhone ? 88 : 'auto'}
            {...safeObject({
              isFlex: !responsiveModel.isTabletAndPhone || null
            })}
          >
            <Col md={!responsiveModel.isTabletAndPhone ? 3 : 12} marginTop={1}>
              <Image src={application.bankLogo} width="8rem"/>
            </Col>
            <Col md={!responsiveModel.isTabletAndPhone ? 8 : 12} marginTop={1}>
              <Paragraph isNativeMargin={false} type="danger">
                Банк отклонил заявку
              </Paragraph>
            </Col>
          </Row>
          {!responsiveModel.isTabletAndPhone && <Divider marginBottom={0} marginTop={1}/>}
        </Block>
      </Fragment>
    ))(applicationModel.negativeApplications)}
  </Block>
)

export default withTheme(inject(store => ({
  applicationModel: store.applicationModel,
  responsiveModel: store.responsiveModel
}))(observer(Negative)))
