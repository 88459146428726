import React, { Fragment, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Block, Input, DatePicker } from 'uikit'
import AddressInput from 'components/AddressInput'
import { ADDITIONAL_DATA_HTML_ID } from 'utils/htmlID'
import FmsInput from 'components/FmsInput'

const Passport = ({ questionaryModel, responsiveModel }) => {
  const [fmsTarget, setFmsTarget] = useState(null)
  const {
    passportCode, passportBy, birthPlace, addressRegistration,
    registrationDate,

    isExistAdditionalDataStep,
    passportValidationEnabled
  } = questionaryModel
  const { isTabletAndPhone } = responsiveModel

  return (
    <Fragment>
      <Block width={!isTabletAndPhone ? 16 : '100%'} marginTop={-1}>
        <Input
          key="passportCode"
          label="Код подразделения"
          mask="999-999"
          unmask={v => v.replace(/-*/gi, '')}
          value={passportCode}
          onChange={async v => {
            await fmsTarget.searchFms(v)
            questionaryModel.setField('passportCode')(v)
          }}
          error={questionaryModel.validPassportCode}
          tabIndex={1}
          isShowForceValidate={isExistAdditionalDataStep || passportValidationEnabled}
          onBlur={questionaryModel.enableIsChangeAdditionalDataOne}
          htmlID={`${ADDITIONAL_DATA_HTML_ID}-passportCode`}
          type="number"
        />
      </Block>
      <Block width="100%" marginTop={1.5}>
        <FmsInput
          key="passportBy"
          label="Кем выдан паспорт"
          value={passportBy}
          onChange={questionaryModel.setField('passportBy')}
          onMount={t => setFmsTarget(t)}
          error={questionaryModel.validPassportBy}
          isShowForceValidate={isExistAdditionalDataStep || passportValidationEnabled}
          tabIndex={1}
          onBlur={questionaryModel.enableIsChangeAdditionalDataOne}
          htmlID={`${ADDITIONAL_DATA_HTML_ID}-passportBy`}
        />
      </Block>
      <Block width="100%" marginTop={1.5}>
        <Input
          key="birthPlace"
          label="Место рождения"
          value={birthPlace}
          onChange={questionaryModel.setTrimField('birthPlace')}
          error={questionaryModel.validBirthPlace}
          tabIndex={1}
          isShowForceValidate={isExistAdditionalDataStep || passportValidationEnabled}
          onBlur={questionaryModel.enableIsChangeAdditionalDataOne}
          htmlID={`${ADDITIONAL_DATA_HTML_ID}-birthPlace`}
        />
      </Block>
      <Block width="100%" marginTop={1.5}>
        <AddressInput
          key="addressRegistration"
          label="Адрес регистрации"
          value={addressRegistration}
          onChange={questionaryModel.setAddressRegistration}
          error={questionaryModel.validAddressRegistration}
          tabIndex={1}
          isShowForceValidate={isExistAdditionalDataStep || passportValidationEnabled}
          onBlur={questionaryModel.enableIsChangeAdditionalDataOne}
          htmlID={`${ADDITIONAL_DATA_HTML_ID}-addressRegistration`}
        />
      </Block>
      <Block width={!isTabletAndPhone ? 16 : '100%'} marginTop={1.5}>
        <DatePicker
          key="registrationDate"
          label="Дата регистрации"
          tabIndex={1}
          value={registrationDate}
          onChange={questionaryModel.setRegistrationDate}
          error={questionaryModel.validRegistrationDate}
          isShowForceValidate={isExistAdditionalDataStep || passportValidationEnabled}
          onBlur={questionaryModel.enableIsChangeAdditionalDataOne}
          htmlID={`${ADDITIONAL_DATA_HTML_ID}-registrationDate`}
        />
      </Block>
    </Fragment>
  )
}

export default inject(store => ({
  questionaryModel: store.questionaryModel,
  responsiveModel: store.responsiveModel
}))(observer(Passport))
