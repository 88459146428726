import React from 'react'
import { Title } from 'uikit'

const MobileTitleOffer = () => (
  <Title
    level={3}
    marginTop={2}
    marginBottom={2}
  >
    Результат подбора кредитных продуктов
  </Title>
)

export default MobileTitleOffer
