import _ from 'lodash'
import fp from 'lodash/fp'
import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Col, Row, Divider, Paragraph, Text, Image, Button, Block,
  ResponsiveDesktop, ResponsiveTabletAndPhone,
  moneyRubNormalizer,
  darken, hexToRgba, Icon, Tooltip, num2str
} from 'uikit'
import { inject, observer } from 'mobx-react'
import styled, { withTheme } from 'styled-components'
import { CREDIT_RESULT_HTML_ID, PICKUP_HTML_ID } from 'utils/htmlID'
import Service from 'components/Service'
import ModalSelect from './ModalSelect'

const Root = styled(Block)`
  transition: background-color .175s ease-in-out;
  z-index: 1;
`

const Application = ({ application, isMonth, pickupModel, theme }) => {
  const orderSlots = fp.flow(
    fp.filter(s => _.findIndex(application.disabledSlots, f => f === s.id) === -1),
    fp.map(s => ({
      ...s,
      isSelect: _.findIndex(application.slots, f => f === s.id) !== -1
    }))
  )(pickupModel.order.slots)
  const enabledProviderSlots = fp.flow(
    fp.map(s => ({
      ...s,
      isSelect: true
    }))
  )(application.enabledProviderSlots)
  const slots = [...orderSlots, ...enabledProviderSlots]

  const [isOver, setIsOver] = useState(false)
  const [isOpenDescription, setIsOpenDescription] = useState(false)
  const isExistAlternate = !!application.alternate
  const termAlternateLetter = num2str(_.get(application, 'alternate.term', []), ['месяц', 'месяца', 'месяцев'])

  return (
    <Fragment>
      <ResponsiveDesktop>
        <Root
          background={isOver ? hexToRgba(darken(theme.colors.primary, 0.15), 0.08) : 'transparent'}
          height={101}
          isRem={false}
        >
          <Row
            isFlex
            align="middle"
            isRem={false}
            height={88}
            onMouseOver={() => setIsOver(true)}
            onMouseLeave={() => setIsOver(false)}
          >
            <Col md={3}>
              <Image src={application.bankLogo} width="8rem"/>
            </Col>
            <Col md={3}>
              {slots.length ? <>
                <Block horizontalPlacement="left" marginBottom={0.57}>
                  {fp.map(slot => (
                    <Service
                      key={slot.id}
                      icon={slot.icon}
                      iconProps={{ size: 1 }}
                      isDisable={!slot.isSelect}
                      name={slot.name}
                      description={slot.description}
                      isMarginRight
                    />
                  ))(slots.slice(0, 3))}
                </Block>
                <Block horizontalPlacement="left">
                  {fp.map(slot => (
                    <Service
                      key={slot.id}
                      icon={slot.icon}
                      iconProps={{ size: 1 }}
                      isDisable={!slot.isSelect}
                      name={slot.name}
                      description={slot.description}
                      isMarginRight
                    />
                  ))(slots.slice(3))}
                </Block>
              </> : <Text>—</Text>}
            </Col>
            <Col md={2}>
              <Paragraph marginBottom={isExistAlternate ? 0.1 : 0}>
                {application.term} мес.
              </Paragraph>
              {isExistAlternate &&
              <Fragment>
                <Paragraph marginBottom={0.1} isStrong>или</Paragraph>
                <Paragraph isNativeMargin={false} isStrong>
                  рассрочка {application.alternate.term} мес.
                  <Tooltip
                    isInline
                    tooltip={`Продукт с льготным периодом ${application.alternate.term} ${termAlternateLetter}. При погашении за ${application.alternate.term} ${termAlternateLetter} переплата за товар отсутствует.`}
                  >
                    <Icon marginLeft={0.3} icon="question" iconType="design" color="secondary"/>
                  </Tooltip>
                </Paragraph>
              </Fragment>
              }
            </Col>
            <Col md={2}>
              {moneyRubNormalizer(application.initialPayment)} ₽
            </Col>
            <Col md={2}>
                <Button
                  type="primary"
                  width="85%"
                  height="auto"
                  onClick={() => setIsOpenDescription(true)}
                  htmlID={`${CREDIT_RESULT_HTML_ID}-application-select-${application.id}`}
                >
                  <Block>
                    <Block>
                      Выбрать
                    </Block>
                    <Block>
                      {moneyRubNormalizer(Math.floor(isMonth ? application.monthlyPayment : application.paymentsSum))} <Text type="#fff">₽</Text>
                    </Block>
                  </Block>
                </Button>
            </Col>
          </Row>
          <Divider margintTop={1} marginBottom={0}/>
        </Root>
      </ResponsiveDesktop>
      <ResponsiveTabletAndPhone>
        <Block
          background="gray1"
          marginBottom={0.7}
          padding={1}
          borderRadius={0.57}
        >
          <Image src={application.bankLogo} width="10rem" height={5} marginBottom={1}/>

          {slots.length > 0 && <Row isFlex justify="space-between">
            <Col width="30%">
              <Paragraph isNativeMargin={false} isStrong>
                Дополнительные услуги
              </Paragraph>
            </Col>
            <Col width="40%">
              <Block>
                <Block horizontalPlacement="left" marginBottom={0.57}>
                  {fp.map(slot => (
                    <Service
                      key={slot.id}
                      icon={slot.icon}
                      iconProps={{ size: 1 }}
                      isDisable={!slot.isSelect}
                      htmlID={`${PICKUP_HTML_ID}-service-${application.id}`}
                      name={slot.name}
                      description={slot.description}
                      isMarginRight
                    />
                  ))(slots.slice(0, 3))}
                </Block>
                <Block horizontalPlacement="left">
                  {fp.map(slot => (
                    <Service
                      key={slot.id}
                      icon={slot.icon}
                      iconProps={{ size: 1 }}
                      isDisable={!slot.isSelect}
                      htmlID={`${PICKUP_HTML_ID}-service-${application.id}`}
                      name={slot.name}
                      description={slot.description}
                      isMarginRight
                    />
                  ))(slots.slice(3))}
                </Block>
              </Block>
            </Col>
          </Row>}

          <Divider/>

          <Row isFlex justify="space-between">
            <Col width="30%">
              <Paragraph isNativeMargin={false} isStrong>
                Срок кредита
              </Paragraph>
            </Col>
            <Col width="40%">
              <Paragraph marginBottom={isExistAlternate ? 0.1 : 0}>
                {application.term} мес.
              </Paragraph>
              {isExistAlternate &&
              <Fragment>
                <Paragraph marginBottom={0.1} isStrong>или</Paragraph>
                <Paragraph isNativeMargin={false} isStrong>
                  рассрочка {application.alternate.term} мес.
                  <Tooltip
                    isInline
                    tooltip={`Продукт с льготным периодом ${application.alternate.term} ${termAlternateLetter}. При погашении за ${application.alternate.term} ${termAlternateLetter} переплата за товар отсутствует.`}
                  >
                    <Icon marginLeft={0.3} icon="question" iconType="design" color="secondary"/>
                  </Tooltip>
                </Paragraph>
              </Fragment>
              }
            </Col>
          </Row>

          <Divider/>

          <Row isFlex justify="space-between">
            <Col width="30%">
              <Paragraph isNativeMargin={false} isStrong>
                Первонач. взнос
              </Paragraph>
            </Col>
            <Col width="40%">
              <Paragraph isNativeMargin={false}>
                {moneyRubNormalizer(application.initialPayment)} ₽
              </Paragraph>
            </Col>
          </Row>

          <Divider/>

          <Row isFlex justify="space-between">
            <Col width="30%">
              <Paragraph isNativeMargin={false} isStrong>
                Ежемесячный платеж
              </Paragraph>
            </Col>
            <Col width="40%">
              <Paragraph level={4} size={1.2} marginBottom={isExistAlternate ? 1 : 0}>
                {moneyRubNormalizer(Math.floor(application.monthlyPayment))} <Text type="secondary">₽</Text>
              </Paragraph>
              {isExistAlternate &&
              <Paragraph level={4} isNativeMargin={false} size={1.2} isStrong>
                {moneyRubNormalizer(Math.floor(application.alternate.monthlyPayment))} <Text type="secondary">₽</Text>
              </Paragraph>
              }
            </Col>
          </Row>

          <Button
            style={{ width: '100%' }}
            onClick={() => setIsOpenDescription(true)}
            marginTop={1}
            type="primary"
          >
            Выбрать
          </Button>
        </Block>
      </ResponsiveTabletAndPhone>
      <ModalSelect
        application={application}
        isOpenDescription={isOpenDescription}
        setIsOpenDescription={setIsOpenDescription}
      />
    </Fragment>
  )
}

Application.propTypes = {
  application: PropTypes.object,
  isMonth: PropTypes.bool
}

export default withTheme(inject(store => ({
  pickupModel: store.pickupModel,
  applicationModel: store.applicationModel,
  responsiveModel: store.responsiveModel
}))(observer(Application)))
