import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Switch, Block, Text, Icon } from 'uikit'
import { inject, observer } from 'mobx-react'
import { PICKUP_HTML_ID } from 'utils/htmlID'

const Service = ({ htmlID, label, value, isDescription, onChange, onClickHelp, responsiveModel }) => (
    <Switch
      isChecked={value}
      onChange={onChange}
      marginBottom={1.5}
      tabIndex={1}
      htmlID={`${PICKUP_HTML_ID}-service-${htmlID}-switch`}
    >
      <Block horizontalPlacement="left" verticalPlacement="center">
        <Text>{label}</Text>
        {isDescription &&
        <Icon
          iconType="design"
          icon="question"
          color="secondary"
          marginLeft={responsiveModel.isDesktop ? 0.3 : 0.5}
          onClick={onClickHelp}
          htmlID={`${PICKUP_HTML_ID}-service-${htmlID}-help`}
        />
        }
      </Block>
    </Switch>
)

Service.propTypes = {
  htmlID: PropTypes.any,
  label: PropTypes.string,
  value: PropTypes.bool,
  isDescription: PropTypes.bool,

  onChange: PropTypes.func,
  onClickHelp: PropTypes.func
}

export default inject(store => ({
  responsiveModel: store.responsiveModel
}))(observer(Service))
