import _ from 'lodash'
import fp from 'lodash/fp'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { InputSelect, withMargin, mountHook } from 'uikit'
import DadataProvider from 'providers/dadata-provider'

const handleSearch = _.debounce(async (v, onChangeOptions, onChangeIsLoading) => {
  if (v) {
    try {
      onChangeIsLoading(true)
      const fms = await DadataProvider.getFMS(v)
      onChangeOptions(fms)
    } finally {
      onChangeIsLoading(false)
    }
  }
}, 450)

const FmsInput = ({
  className, style, htmlID,

  tabIndex, size, isDisable, placeholder,
  onFocus, onBlur,

  value, onChange, onMount,
  error = null, warn = null, label = null, help = null, isShowForceValidate = false
}) => {
  const [fms, setFms] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const handleChange = v => {
    if (onChange) onChange(v)
  }

  mountHook(() => {
    if (onMount) {
      onMount({
        searchFms: v => handleSearch(v, setFms, setIsLoading)
      })
    }
  })

  return (
    <InputSelect
      className={className}
      style={style}
      isSelect={false}
      options={fp.map(a => ({
        value: a.value,
        label: a.value
      }))(fms)}
      onChange={handleChange}
      onChangeText={v => {
        if (onChange) onChange(v)
        handleSearch(v, setFms, setIsLoading)
      }}
      isFilterOption={false}
      tabIndex={tabIndex}
      size={size}
      isDisable={isDisable}
      placeholder={placeholder}
      onFocus={onFocus}
      onBlur={onBlur}
      isLoading={isLoading}
      htmlID={htmlID}
      value={value}
      label={label}
      error={error}
      warn={warn}
      help={help}
      isShowForceValidate={isShowForceValidate}
      dropdownStyle={{
        minWidth: '800px'
      }}
    />
  )
}

FmsInput.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  htmlID: PropTypes.string,

  value: PropTypes.any,
  size: PropTypes.oneOf(['xs', 'sm', 'lg']),
  tabIndex: PropTypes.any,
  isDisable: PropTypes.bool,
  placeholder: PropTypes.string,
  onMount: PropTypes.func,

  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
}

FmsInput.defaultProps = {
  value: '',
  isDisable: false,
  size: 'lg',
  tabIndex: null
}

export default withMargin({ displayName: 'FmsInput' })(FmsInput)
