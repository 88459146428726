import _ from 'lodash'
import { observable, action, computed, toJS } from 'mobx'
import MobileDetect from 'mobile-detect'
import { breakPoints } from 'styles'
import BaseModel from '../baseModel'

class ResponsiveModel extends BaseModel {
  constructor() {
    super(null)
    this.isTouch = this.calculateIsTouch()
    this.setExistHeader = _.debounce(this.setExistHeader.bind(this), 50)
  }

  @observable width = window.innerWidth
  @observable scrollY = window.scrollY
  @observable isExistHeader = true
  @observable isTouch = false

  calculateIsTouch = () => {
    const userAgent = window?.navigator?.userAgent ||
      window?.navigator?.vendor || window?.navigator?.opera
    const detect = new MobileDetect(userAgent)
    let isTouch = false
    try {
      document.createEvent('TouchEvent')
      isTouch = true
    } catch (e) {}

    return !!(detect.mobile() || detect.phone() || detect.tablet() || isTouch)
  }

  @action setWidth = width => {
    this.width = width
    this.isTouch = this.calculateIsTouch()
  }

  @action setScrollY = scrollY => {
    this.scrollY = scrollY
    if (scrollY - 94 >= 0) {
      this.isExistHeader = false
    } else {
      this.isExistHeader = true
    }
  }

  @computed get isPhone() {
    return this.width < breakPoints.mobile
  }

  @computed get isTablet() {
    return this.width >= breakPoints.mobile && this.width < breakPoints.tablet
  }

  @computed get isTabletAndPhone() {
    return this.width < breakPoints.tablet
  }

  @computed get isBeforeDesktop() {
    return this.width < breakPoints.desktop
  }

  @computed get isDesktop() {
    return this.width >= breakPoints.tablet
  }

  @computed get isMiddleDesktop() {
    return this.width >= breakPoints.desktop &&
      this.width < breakPoints.largeDesktop
  }

  @computed get isLargeDesktop() {
    return this.width >= breakPoints.largeDesktop
  }

  @action setExistHeader = isExistHeader => {
    this.isExistHeader = isExistHeader
  }
}

export { ResponsiveModel }
export default new ResponsiveModel()
