import React from 'react'
import PropTypes from 'prop-types'
import { Block } from 'uikit'
import styled, { withTheme } from 'styled-components'

const Root = styled(Block)`
  position: relative;
  & svg {
    max-width: 3rem;
    max-height: 3rem;
  }
`

const MainNumber = styled(Block)`
  position: absolute;
  font-size: 20px;
  top: 6px;
  left: 11px;
`

const SubNumber = styled(Block)`
  position: absolute;
  top: 15px;
  left: 21px;
  color: ${props => props.theme.colors.gray5};
  font-size: 12px;
`

const Progress = ({ step, theme }) => (
  <Root width={3} height={3} marginRight={1}>
    <MainNumber>{step + 1}</MainNumber>
    <SubNumber>/5</SubNumber>
    <svg
      width="83.9822401675"
      height="83.3343848944"
      viewBox="0, 0, 83.9822401675, 83.3343848944"
      preserveAspectRatio="none"
  >
      <g
        style={{
          fill: '#000000',
          fillOpacity: 1,
          stroke: '#000000',
          strokeOpacity: 1,
          fillRule: 'nonzero',
          strokeLinecap: 'square',
          strokeLinejoin: 'miter',
          strokeWidth: 2.5,
          strokeMiterlimit: 10
        }}
      >
        <g>
          <path
            style={{
              fill: 'none',
              strokeWidth: 10,
              stroke: step >= 0 ? theme.colors.primary : theme.colors.gray3
            }}
            d="M73.7981559667,23.7630054241 C68.1634244061,14.1079837202 58.4851856311,7.499083714132 47.4415089602,5.76505676739"
          />
          <path
            style={{
              fill: 'none',
              strokeWidth: 10,
              stroke: step >= 1 ? theme.colors.primary : theme.colors.gray3
            }}
            d="M70.7867629072,64.9893921983 C76.7441902949,57.3408541089 79.4800694859,47.6686307695 78.4116942264,38.032778431"
          />
          <path
            style={{
              fill: 'none',
              strokeWidth: 10,
              stroke: step >= 2 ? theme.colors.primary : theme.colors.gray3
            }}
            d="M25.3064044455,74.8309462941 C35.9825226085,79.8368084806 48.3998035183,79.4842961673 58.7747347456,73.8808189174"
          />
          <path
            style={{
              fill: 'none',
              strokeWidth: 10,
              stroke: step >= 3 ? theme.colors.primary : theme.colors.gray3
            }}
            d="M5.25,42.1014980385 C5.121384309218,50.8513383804 8.081705144638,59.3664058322 13.6104277833,66.1494342111"
          />
          <path
            style={{
              fill: 'none',
              strokeWidth: 10,
              stroke: step >= 4 ? theme.colors.primary : theme.colors.gray3
            }}
            d="M31.8218330051,6.801819232383 C21.2523441384,9.832033272067 12.5650003693,17.3831057601 8.092338914819,27.427586411"
          />
        </g>
      </g>
    </svg>
  </Root>
)

Progress.propTypes = {
  step: PropTypes.number
}

export default withTheme(Progress)
