import { Mixin } from 'mixwith'
import menuModel from 'models/menuModel'
import { orderHistory } from 'utils/history'
import { action, computed, observable, toJS } from 'mobx'
import _ from 'lodash'
import { validRequired, notifyError, isSafe } from 'uikit'
import QuestionaryProvider from 'providers/api-provider/questionary-provider'

import pickupModel from 'models/pickupModel'
import { withoutHours } from '../../utils/date'

const AdditionalData = Mixin(Super => class extends Super {
  // One subStep
  @observable passportCode = ''
  @observable passportBy = ''
  @observable birthPlace = ''
  @observable addressRegistration = null
  @observable registrationDate = null

  // Two subStep
  @observable homeRepeat = true
  @observable addressHome = null
  @observable statusHome = null
  @observable homeDate = null

  @observable activeAdditionalPanel = '1'
  @observable isChangeAdditionalDataOne = false
  @observable isChangeAdditionalDataTwo = false
  @observable existAdditionalDataSteps = []
  @observable isNextAdditionalDataLoading = false
  @observable passportValidationEnabled = false
  @observable livingValidationEnabled = false

  @action enableIsChangeAdditionalDataOne = () => {
    this.isChangeAdditionalDataOne = true
  }

  @action enableIsChangeAdditionalDataTwo = () => {
    this.isChangeAdditionalDataTwo = true
  }

  @action setAddressRegistration = v => {
    if (this.homeRepeat) {
      const existAdditionalDataSteps = this.existAdditionalDataSteps.slice()
      if (!v && _.findIndex(existAdditionalDataSteps, s => s !== '2')) {
        existAdditionalDataSteps.push('2')
      }

      this.applyData({
        addressRegistration: v,
        addressHome: v || null,
        homeDate: this.registrationDate && v ? new Date(this.registrationDate) : null,
        homeRepeat: !!v,
        existAdditionalDataSteps
      })
    } else {
      this.addressRegistration = v
    }
  }

  @action setRegistrationDate = v => {
    if (this.homeRepeat) {
      const existAdditionalDataSteps = this.existAdditionalDataSteps.slice()
      if (!v && _.findIndex(existAdditionalDataSteps, s => s !== '2')) {
        existAdditionalDataSteps.push('2')
      }

      this.applyData({
        registrationDate: v,
        addressHome: this.addressRegistration && v ? this.addressRegistration : null,
        homeDate: v ? new Date(v) : null,
        homeRepeat: !!v,
        existAdditionalDataSteps
      })
    } else {
      this.registrationDate = v
    }
  }

  @action setAddressHomeRepeat = isRepeat => {
    if (isRepeat) {
      this.addressHome = this.addressRegistration
      this.homeDate = new Date(this.registrationDate)
      this.homeRepeat = true
    } else {
      this.addressHome = null
      this.homeDate = null
      this.homeRepeat = false
    }
  }

  @action enableAdditionalDataValidation = () => {
    if (this.additionalDataErrorCountOne + this.additionalDataEmptyCountOne > 0) {
      this.applyData({
        activeAdditionalPanel: '1'
      })
    }
    switch (this.activeAdditionalPanel) {
      case '1':
        this.passportValidationEnabled = true
        break
      case '2':
        this.livingValidationEnabled = true
        break
    }
  }

  @computed get validPassportCode() {
    if (this.passportCode === '') return 'Обязательное поле'
    if (this.passportCode.length !== 6) return 'Неверный формат'

    return null
  }

  @computed get validPassportBy() {
    if (this.passportBy === '') return 'Обязательное поле'

    return null
  }

  @computed get validBirthPlace() {
    if (!this.birthPlace) return 'Обязательное поле'
    if (!/^[\d\s,-.ЁА-яё]+$/.test(this.birthPlace)) return 'Только цифры и русские буквы'

    return null
  }

  @computed get validAddressRegistration() {
    if (!this.addressRegistration) return 'Обязательное поле'

    return null
  }

  @computed get validRegistrationDate() {
    if (validRequired(this.registrationDate)) return validRequired(this.registrationDate)
    if (withoutHours(this.registrationDate) < withoutHours(this.birthDay)) return 'Не может быть раньше даты рождения'
    if (+this.registrationDate > +new Date()) return 'Не может быть больше текущей даты'

    return null
  }

  @computed get validAddressHome() {
    if (!this.addressHome) return 'Обязательное поле'

    return null
  }

  @computed get validStatusHome() {
    if (!this.statusHome) return 'Обязательное поле'

    return null
  }

  @computed get validHomeDate() {
    if (validRequired(this.homeDate)) return validRequired(this.homeDate)
    if (withoutHours(this.homeDate) < withoutHours(this.birthDay)) return 'Не может быть раньше даты рождения'
    if (+this.homeDate > +new Date()) return 'Не может быть больше текущей даты'

    return null
  }

  @computed get isChangedAdditionalData() {
    switch (this.activeAdditionalPanel) {
      case '1':
        return this.isChangeAdditionalDataOne
      case '2':
        return this.isChangeAdditionalDataTwo
      default:
        return false
    }
  }

  @computed get additionalDataEmptyCountOne() {
    if (this.isChangeAdditionalDataOne) {
      let count = 0
      if (this.passportCode === '') count += 1
      if (this.passportBy === '') count += 1
      if (!isSafe(this.birthPlace)) count += 1
      if (!this.addressRegistration) count += 1
      if (!isSafe(this.registrationDate)) count += 1
      return count
    }

    return 0
  }

  @computed get additionalDataErrorCountOne() {
    if (this.isChangeAdditionalDataOne) {
      let count = 0
      if (this.validPassportCode) count += 1
      if (this.validPassportBy) count += 1
      if (this.validBirthPlace) count += 1
      if (this.validAddressRegistration) count += 1
      if (this.validRegistrationDate) count += 1

      const res = count - this.additionalDataEmptyCountOne
      return res > 0 ? res : 0
    }

    return 0
  }

  @computed get additionalDataEmptyCountTwo() {
    if (this.isChangeAdditionalDataTwo) {
      let count = 0
      if (!this.addressHome) count += 1
      if (!isSafe(this.statusHome)) count += 1
      if (!isSafe(this.homeDate)) count += 1
      return count
    }

    return 0
  }

  @computed get additionalDataErrorCountTwo() {
    if (this.isChangeAdditionalDataTwo) {
      let count = 0
      if (this.validAddressHome) count += 1
      if (this.validStatusHome) count += 1
      if (this.validHomeDate) count += 1

      const res = count - this.additionalDataEmptyCountTwo
      return res > 0 ? res : 0
    }

    return 0
  }

  @computed get additionalDataEmptyCount() {
    switch (this.activeAdditionalPanel) {
      case '1':
        return this.additionalDataEmptyCountOne
      case '2':
        return this.additionalDataEmptyCountTwo
      default:
        return 0
    }
  }

  @computed get additionalDataErrorCount() {
    switch (this.activeAdditionalPanel) {
      case '1':
        return this.additionalDataErrorCountOne
      case '2':
        return this.additionalDataErrorCountTwo
      default:
        return 0
    }
  }

  @computed get validAdditionalData() {
    return this.additionalDataErrorCountOne + this.additionalDataEmptyCountOne +
      this.additionalDataErrorCountTwo + this.additionalDataEmptyCountTwo === 0
  }

  @computed get isNextAdditionalData() {
    let isChange = false

    switch (this.activeAdditionalPanel) {
      case '1':
        isChange = this.isChangeAdditionalDataOne
        break
      case '2':
        isChange = this.isChangeAdditionalDataTwo && this.isChangeAdditionalDataOne
        break
    }

    return isChange && this.additionalDataErrorCount + this.additionalDataEmptyCount === 0 &&
      (this.activeAdditionalPanel === '2' ? (this.additionalDataErrorCountOne + this.additionalDataEmptyCountOne === 0) : true)
  }

  @computed get additionalDataProgress() {
    const errorCount = (this.isChangeAdditionalDataOne ? this.additionalDataErrorCountOne + this.additionalDataEmptyCountOne > 0 ? 1 : 0 : 1) +
      (this.isChangeAdditionalDataTwo ? this.additionalDataErrorCountTwo + this.additionalDataEmptyCountTwo > 0 ? 1 : 0 : 1)

    switch (errorCount) {
      case 2: return 0
      case 1: return 50
      default: return 100
    }
  }

  @computed get isExistAdditionalDataStep() {
    let isChange = false

    switch (this.activeAdditionalPanel) {
      case '1':
        isChange = this.isChangeAdditionalDataOne
        break
      case '2':
        isChange = this.isChangeAdditionalDataTwo
        break
    }

    return _.findIndex(this.existAdditionalDataSteps, s => s === this.activeAdditionalPanel) !== -1 &&
      isChange
  }

  @action addToExistAdditionalDataSteps = step => {
    const existAdditionalDataSteps = toJS(this.existAdditionalDataSteps)
    if (_.findIndex(existAdditionalDataSteps, s => s === step) === -1) {
      existAdditionalDataSteps.push(step)
      this.existAdditionalDataSteps = existAdditionalDataSteps
    }
  }

  backAdditionalData = e => {
    if (e && e.target) {
      e.target.blur()
    }

    if (this.activeAdditionalPanel === '2') {
      this.applyData({
        activeAdditionalPanel: '1'
      })
    } else {
      menuModel.changeStep(1, orderHistory('/main_data'))
    }
  }

  nextAdditionalData = (activeAdditionalPanel = null) => async e => {
    if (e && e.target) {
      e.target.blur()
    }

    const existAdditionalDataSteps = toJS(this.existAdditionalDataSteps)
    try {
      if (this.isNextAdditionalData && activeAdditionalPanel !== this.activeAdditionalPanel) {
        this.applyData({ isNextAdditionalDataLoading: true })

        let prepare = {}
        let subStep = ''

        switch (this.activeAdditionalPanel) {
          case '1':
            prepare = {
              passportCode: this.passportCode,
              passportBy: this.passportBy,
              birthPlace: this.birthPlace,
              registrationDate: this.registrationDate,
              addressRegistration: this.addressRegistration
            }
            subStep = 'passport'
            break
          case '2':
            prepare = {
              addressHome: this.addressHome,
              statusHome: this.statusHome,
              homeDate: this.homeDate
            }
            subStep = 'residence'
            break
        }

        await QuestionaryProvider.updateQuestionary({
          orderID: pickupModel.order.id,
          questionary: prepare,
          subStep
        })
      }
    } catch (err) {
      notifyError('Ошибка сохранения дополнительных данных')
    } finally {
      if (_.findIndex(existAdditionalDataSteps, s => this.activeOtherPanel === s) === -1 &&
        this.isChangedAdditionalData) {
        existAdditionalDataSteps.push(this.activeOtherPanel)
      }
      this.applyData({
        isNextAdditionalDataLoading: false,
        existAdditionalDataSteps
      })
    }

    if (activeAdditionalPanel) {
      this.applyData({
        activeAdditionalPanel
      })
    } else if (this.activeAdditionalPanel === '1') {
      this.applyData({
        activeAdditionalPanel: '2'
      })
    } else {
      menuModel.changeStep(3, orderHistory('/other_data'))
    }
  }
})

export default AdditionalData
