import React, { PureComponent } from 'react'
import * as Sentry from '@sentry/browser'

class ErrorBoundary extends PureComponent {
  state = { isError: false }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo)
      Sentry.captureException(error)
    })
  }

  render() {
    if (this.state.isError) {
      return <h1>ЧТо-то пошло не так...</h1>
    }
    return this.props.children
  }
}

export default ErrorBoundary
