import React from 'react'
import { Button, Block, NavBar, Alert, Title, Col, Row, mountHook, num2str } from 'uikit'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'
import history, { orderHistory } from 'utils/history'
import PropTypes from 'prop-types'
import { ADDITIONAL_DATA_HTML_ID } from 'utils/htmlID'
import Passport from './Passport'
import Living from './Living'

const AdditionalData = ({
  isNew, menuModel, questionaryModel, pickupModel, responsiveModel, applicationModel,
  ...props
}) => {
  const {
    activeAdditionalPanel, isNextAdditionalDataLoading
  } = questionaryModel
  mountHook(async () => {
    menuModel.changeStep(2, null, false)

    const orderID = _.get(props, 'match.params.orderID', null)

    if (orderID) {
      if (isNew) {
        if (!questionaryModel.isNextMainData) {
          history.push(orderHistory('/pickup', orderID))
        }
      } else if (!questionaryModel.id) {
        await questionaryModel.getQuestionary(orderID)
      }

      if (questionaryModel.isFill) {
        history.push(orderHistory('/credit_result'))
      }

      if (pickupModel.isCallCenterCall) {
        history.push(`/form/${orderID}/call_center`)
      }
    } else {
      history.push('/error_order')
    }
  })

  const handleChangeNav = value => {
    questionaryModel.nextAdditionalData(value)()
  }

  const countPositive = _.filter(applicationModel.applications, a => a.isPositive).length
  const isNegative = _.filter(applicationModel.banks, a => a.isNegative).length > applicationModel.banks.length

  let Content = null
  switch (activeAdditionalPanel) {
    case '1':
      Content = <Passport/>
      break
    case '2':
      Content = <Living/>
      break
  }

  const ContentContainer = (
    <Block
      isPaddingLeft
      paddingLeft={responsiveModel.isTabletAndPhone ? 2 : 0}
      isPaddingRight
      paddingRight={responsiveModel.isTabletAndPhone ? 2 : 0}
    >
      {responsiveModel.isPhone && (questionaryModel.additionalDataErrorCount + questionaryModel.additionalDataEmptyCount) &&
      questionaryModel.isExistAdditionalDataStep ?
        <Alert
          message="Исправьте ошибки в полях формы"
          type="error"
          isClosable
          marginBottom={2}
        /> : null
      }
      {Content}
    </Block>
  )

  return (
    <Block>
      <Block
        onClick={countPositive > 0 || isNegative ? () => history.push(orderHistory('/credit_result')) : null}
        marginTop={!responsiveModel.isPhone ? 3 : 1}
        marginBottom={!responsiveModel.isPhone ? 2 : 0}
        htmlID={`${ADDITIONAL_DATA_HTML_ID}-alert`}
      >
        <Alert
          message={countPositive > 0 ?
            `Вы получили ${countPositive} ${num2str(countPositive, ['одобрение', 'одобрения', 'одобрений'])}! Нажмите, чтобы посмотреть, или продолжайте заполнять анкету.` :
            isNegative ? 'К сожалению, Вам отказали.' :
              'Основные данные отправлены на рассмотрение. Продолжайте заполнять анкету, мы уведомим вас о результатах.'
          }
          type={countPositive > 0 ? 'success' : isNegative ? 'error' : 'info'}
          isClosable
        />
      </Block>
      {!responsiveModel.isPhone &&
      <Title
        level={3}
        marginTop={3}
        marginBottom={!responsiveModel.isTabletAndPhone ? 2 : 0}
      >
        Дополнительные данные
      </Title>
      }
      <Block
        marginLeft={responsiveModel.isTabletAndPhone ? -2 : 0}
        marginRight={responsiveModel.isTabletAndPhone ? -2 : 0}
      >
         <NavBar
          key="additional-data-nav"
          activeKey={activeAdditionalPanel}
          onChange={handleChangeNav}
          options={[
            {
              value: '1',
              label: 'Паспортные данные',
              isSuccess: questionaryModel.isChangeAdditionalDataOne &&
                questionaryModel.additionalDataErrorCountOne + questionaryModel.additionalDataEmptyCountOne === 0,
              isError: questionaryModel.isChangeAdditionalDataOne &&
                questionaryModel.additionalDataErrorCountOne + questionaryModel.additionalDataEmptyCountOne > 0,
              isLoading: questionaryModel.activeAdditionalPanel === '1' && isNextAdditionalDataLoading,
              isDisable: questionaryModel.activeAdditionalPanel !== '1' && isNextAdditionalDataLoading
            },
            {
              value: '2',
              label: 'Проживание',
              isSuccess: questionaryModel.isChangeAdditionalDataTwo &&
                questionaryModel.additionalDataErrorCountTwo + questionaryModel.additionalDataEmptyCountTwo === 0,
              isError: questionaryModel.isChangeAdditionalDataTwo &&
                questionaryModel.additionalDataErrorCountTwo + questionaryModel.additionalDataEmptyCountTwo > 0,
              isLoading: questionaryModel.activeAdditionalPanel === '2' && isNextAdditionalDataLoading,
              isDisable: questionaryModel.activeAdditionalPanel !== '2' && isNextAdditionalDataLoading
            }
          ]}
          htmlID={`${ADDITIONAL_DATA_HTML_ID}-nav-bar`}
         >
           {ContentContainer}
         </NavBar>
      </Block>
      <Row isFlex justify="space-between" align="middle" marginTop={2.5}>
        <Col>
          <Button
            tabIndex={3}
            onClick={questionaryModel.backAdditionalData}
            isLoading={isNextAdditionalDataLoading}
            htmlID={`${ADDITIONAL_DATA_HTML_ID}-back`}
          >
            Назад
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            tabIndex={2}
            onClick={(e) => {
              if (questionaryModel.isNextAdditionalData) {
                questionaryModel.nextAdditionalData()(e)
              } else {
                questionaryModel.enableAdditionalDataValidation()
              }
            }}
            isLoading={isNextAdditionalDataLoading}
            htmlID={`${ADDITIONAL_DATA_HTML_ID}-next`}
          >
            Продолжить
          </Button>
        </Col>
      </Row>
    </Block>
  )
}

AdditionalData.propTypes = {
  isNew: PropTypes.bool
}

AdditionalData.defaultProps = {
  isNew: true
}

export default inject(store => ({
  menuModel: store.menuModel,
  questionaryModel: store.questionaryModel,
  pickupModel: store.pickupModel,
  responsiveModel: store.responsiveModel,
  applicationModel: store.applicationModel
}))(observer(AdditionalData))
