import React from 'react'
import ReactDom from 'react-dom'
import * as Sentry from '@sentry/browser'
import ConfigProvider from 'providers/config-provider'
import Root from './containers/Root'
import ErrorBoundary from './components/ErrorBoundary'

if (PRODUCTION && ConfigProvider.config.sentryDsn) {
  Promise.resolve(Sentry.init({ dsn: ConfigProvider.config.sentryDsn }))
}

const preloader = document.getElementById('preloader')
if (preloader) preloader.remove()

const dest = document.getElementById('app')
ReactDom.render(PRODUCTION
  ? <ErrorBoundary>
      <Root />
    </ErrorBoundary>
  : <Root />, dest)
