import { observable } from 'mobx'
import DictProvider from 'providers/api-provider/dict-provider'
import { notifyError } from 'uikit'
import BaseModel from '../baseModel'

class DictModel extends BaseModel {
  @observable dict = {}

  fetchDict = async (orderID) => {
    try {
      const dict = await DictProvider.getDict(orderID)
      this.applyData({
        dict
      })
    } catch (e) {
      notifyError('Ошика получения словарей')
    }
  }
}

export { DictModel }
export default new DictModel()
