import React from 'react'
import { hot } from 'react-hot-loader/root'
import { ThemeProvider } from 'styled-components'
import { theme, breakPoints } from 'styles'
import 'moment/locale/ru'
import { UIKitProvider } from 'uikit'
import { Link } from 'react-router-dom'
import { Provider, inject, observer } from 'mobx-react'
import models from 'models'
import Container from './Container'

const Root = inject('responsiveModel')(observer(({ responsiveModel }) => (
  <UIKitProvider
    width={responsiveModel.width}
    responsiveModel={{
      isTouch: responsiveModel.isTouch,
      isMobile: responsiveModel.isPhone,
      isTablet: responsiveModel.isTablet,
      isTabletAndPhone: responsiveModel.isTabletAndPhone,
      isDesktop: responsiveModel.isDesktop,
      isBeforeDesktop: responsiveModel.isBeforeDesktop,
      isLargeDesktop: responsiveModel.isLargeDesktop,
      isMiddleDesktop: responsiveModel.isMiddleDesktop,
      breakPoints
    }}
    remPoint={14}
    Link={props => <Link to={props.to}>{props.children}</Link>}
    label={{ weight: 400 }}
  >
    <Container/>
  </UIKitProvider>
)))

const App = () => (
    <ThemeProvider theme={theme}>
      <Provider {...models}>
        <Root/>
      </Provider>
    </ThemeProvider>
)

export default hot(() => (
    <App />
))
