import React, { Fragment, useState } from 'react'
import { Modal, Text, Block, Button } from 'uikit'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import { MAIN_DATA_HTML_ID } from 'utils/htmlID'

const RootMarkdown = styled(Block)`
  text-align: justify;
`

const personalDataText = `
Общество с ограниченной ответственностью «поссистем», адрес: 117105, РФ, г. Москва, Варшавское ш., д. 1, стр. 1 – 2, эт. 4, комн. 38, оф. В412 (далее – «Оператор»), являясь администратором сайта в информационно-телекоммуникационной сети «Интернет»: [https://possystem.pro](https://possystem.pro) (далее – «Сайт»), организует совместно с АО «ОТП Банк», адрес: 125171, г. Москва, Ленинградское шоссе, д.16А, стр. 2 (далее – «Партнер Оператора») обработку персональных данных Пользователя, предоставленных таким Пользователем на Сайте при заполнении анкеты.

СМС-код, направленный Оператором Пользователю на номер мобильного телефона, указанный в анкете на Сайте, используется в соответствии с Федеральным законом от 06.04.2011 г. № 63-ФЗ «Об электронной подписи» в качестве простой электронной подписи Пользователя, для формирования им каждого электронного документа. В случае идентичности СМС-кода, направленного Оператором, и СМС-кода, введенного Пользователем в специальном разделе анкеты на Сайте для подтверждения номера мобильного телефона, указанного Пользователем в анкете на Сайте, такая электронная подпись считается подлинной и предоставленной Пользователем, а документ, подписанный такой электронной подписью рассматривается как электронный документ, подписанный простой электронной подписью Пользователя.

Совершая конклюдентные действия по вводу СМС-кода подтверждения, направленного Оператором Пользователю на номер мобильного телефона, указанный в анкете на Сайте, Пользователь, в качестве субъекта персональных данных, свободно, своей волей и в своем интересе выражает согласие в соответствии с Федеральным законом от 27.07.2006 г. № 152-ФЗ «О персональных данных» Оператору и Партнеру Оператора на обработку персональных данных, указанных Пользователем в анкете на Сайте, путем совершения с использованием средств автоматизации сбора, записи, систематизации, накопления, хранения, уточнения (обновления, изменения), извлечения, использования, передачи (распространения, предоставления, доступа), обезличивания, блокирования, удаления, уничтожения таких персональных данных.

Согласие на обработку персональных данных предоставляется Пользователем в целях рассмотрения заявления Пользователя о предоставлении потребительского кредита (займа), а также дополнительных услуг, оказываемых Оператором и/или Партнером Оператора, заключения по инициативе Пользователя договора потребительского кредита (займа), заключения и исполнения договора страхования, договора об оказании иных дополнительных услуг.

Пользователь подтверждает достоверность сведений, указанных таким Пользователем в анкете на Сайте.

Также Пользователь выражает, в качестве субъекта кредитной истории, согласие Партнеру Оператора, как пользователю кредитной истории, на предоставление информации и получение кредитного отчета по Пользователю в любом бюро кредитных историй и Центральном каталоге кредитных историй, в объеме и порядке, предусмотренными Федеральным законом от 30.12.2004 г. № 218-ФЗ «О кредитных историях».

Пользователь в соответствии с Федеральным законом от 07.07.2003 г. № 126-ФЗ «О связи» выражает согласие Оператору, Партнеру Оператора осуществлять взаимодействие с Пользователем посредством его информирования по телефону, рассылкой по сети подвижной радиотелефонной связи коротких текстовых сообщений, в том числе рекламного характера, на номер мобильного телефона, указанный Пользователем в анкете на Сайте.

Согласие на обработку персональных данных предоставляется Пользователем на срок 5 (пять) лет, но не дольше, чем этого требуют цели обработки и может быть отозвано Пользователем посредством направления письменного уведомления об отзыве согласия на почтовый адрес Оператора.

Порядок обработки и защиты персональных данных Пользователей, предоставленных Пользователями на сайте в информационно-телекоммуникационной сети «Интернет»: [https://possystem.pro](https://possystem.pro) регламентируется [Политикой Оператора в отношении персональных данных, обрабатываемых в рамках деятельности по предоставлению финансового посредничества.](https://posbroker.ru/privacy-policy)
`

const PersonalDataAccess = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Fragment>
      <Text
        style={{ cursor: 'pointer' }}
        onClick={() => setIsOpen(true)}
        htmlID={`${MAIN_DATA_HTML_ID}-personal-data`}
      >
        <Text type="darkBlue"> с условиями обработки персональных данных</Text>
      </Text>
      <Modal
        isOpen={isOpen}
        title="Согласие на обработку персональных данных"
        onCancel={() => setIsOpen(false)}
        width="50rem"
        isCenter
        footer={
          <Block isCenter>
            <Block>
              <Button
                type="primary"
                onClick={() => setIsOpen(false)}
                htmlID={`${MAIN_DATA_HTML_ID}-personal-data-accept`}
                marginBottom={1}
              >
                Закрыть
              </Button>
            </Block>
          </Block>
        }
      >
        <RootMarkdown>
          <ReactMarkdown
            source={personalDataText}
            escapeHtml={false}
            style={{ textAlign: 'justify' }}
          />
        </RootMarkdown>
      </Modal>
    </Fragment>
  )
}

export default PersonalDataAccess
