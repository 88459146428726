import { configure } from 'mobx'
import globalPreloaderModel from './globalPreloaderModel'
import responsiveModel from './responsiveModel'
import menuModel from './menuModel'
import dictModel from './dictModel'
import pickupModel from './pickupModel'
import questionaryModel from './questionaryModel'
import applicationModel from './applicationModel'
import templatesModel from './templatesModel'
import helpModalModel from './helpModalModel'

configure({ enforceActions: 'observed' })

export default {
  globalPreloaderModel,
  responsiveModel,
  menuModel,
  dictModel,
  pickupModel,
  questionaryModel,
  applicationModel,
  templatesModel,
  helpModalModel
}
