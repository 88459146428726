import _ from 'lodash'
import React, { Fragment, useState } from 'react'
import { Col, Row, Button, Modal, ResponsiveDesktop, ResponsiveTabletAndPhone, Block } from 'uikit'
import { inject, observer } from 'mobx-react'
import { withTheme } from 'styled-components'
import { DesktopMaxContent, DesktopMinContent } from 'ui/Responsive'
import ReactMarkdown from 'react-markdown'
import { PICKUP_HTML_ID } from 'utils/htmlID'
import Service from './Service'

const Services = ({ responsiveModel, pickupModel, theme }) => {
  const {
    slots
  } = pickupModel.order

  const [isOpen, setIsOpen] = useState(false)
  const [currentID, setCurrentID] = useState(null)

  const handleClose = () => {
    setIsOpen(false)
    setCurrentID(null)
  }

  const handleSelect = () => {
    pickupModel.setSlot(currentID, true)
    handleClose()
  }

  const handleOpen = field => e => {
    e.stopPropagation()
    e.preventDefault()

    setIsOpen(true)
    setCurrentID(field)
  }

  const generateService = (s, isSelect) => (
    <Service
      key={s.id}
      label={s.name}
      isDescription={!!s.description}
      value={isSelect}
      onChange={v => pickupModel.setSlot(s.id, v)}
      onClickHelp={handleOpen(s.id)}
      htmlID={s.id}
    />
  )

  const isMaxDesktopContent = responsiveModel.width >= theme.breakPoints.desktop

  return (
    <Fragment>
      <ResponsiveDesktop>
        <Row
          marginTop={2.5}
          isFlex={isMaxDesktopContent}
          justify={isMaxDesktopContent ? 'space-between' : 'start'}
        >
          <ResponsiveDesktop>
            <DesktopMaxContent>
              <Col>
                {_.chain(slots)
                  .filter((s, index) => index < 2)
                  .map(s => generateService(s, s.isSelect))
                  .value()
                }
              </Col>
              <Col>
                {_.chain(slots)
                  .filter((s, index) => index >= 2 && index < 4)
                  .map(s => generateService(s, s.isSelect))
                  .value()
                }
              </Col>
              <Col>
                {_.chain(slots)
                  .filter((s, index) => index >= 4)
                  .map(s => generateService(s, s.isSelect))
                  .value()
                }
              </Col>
            </DesktopMaxContent>
            <DesktopMinContent>
              <Col md={6}>
                {_.chain(slots)
                  .filter((s, index) => index < 3)
                  .map(s => generateService(s, s.isSelect))
                  .value()
                }
              </Col>
              <Col
                md={6}
                marginLeft={2.5}
                marginRight={-2.5}
              >
                {_.chain(slots)
                  .filter((s, index) => index >= 3)
                  .map(s => generateService(s, s.isSelect))
                  .value()
                }
              </Col>
            </DesktopMinContent>
          </ResponsiveDesktop>
        </Row>
      </ResponsiveDesktop>
      <ResponsiveTabletAndPhone>
        <Block marginTop={1}>
          {_.map(slots, s => generateService(s, s.isSelect))}
        </Block>
      </ResponsiveTabletAndPhone>
      <Modal
        isOpen={isOpen}
        onCancel={handleClose}
        isCenter
        title={_.get(_.find(slots, s => s.id === currentID), 'name', '')}
        footer={
          <Row isFlex justify="center" marginBottom={1}>
            <Button type="primary" onClick={handleSelect} htmlID={`${PICKUP_HTML_ID}-service-select-${currentID}`}>Выбрать услугу</Button>
          </Row>
        }
      >
        <ReactMarkdown
          source={_.get(_.find(slots, s => s.id === currentID), 'description', '')}
        />
      </Modal>
    </Fragment>
  )
}

export default withTheme(inject(store => ({
  responsiveModel: store.responsiveModel,
  pickupModel: store.pickupModel
}))(observer(Services)))
