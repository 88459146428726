import _ from 'lodash'
import fp from 'lodash/fp'
import ConfigProvider from 'providers/config-provider'
import StorageProvider from 'providers/storage-provider'
import BaseHttpProvider from '../base-http-provider'
import { dadataAddressMap, dadataFioMap, dadataFmsMap } from '../helpers/dadata'

class DadataProvider extends BaseHttpProvider {
  isGate = () => ConfigProvider.config.dadata.type.toLowerCase() === 'gate'
  host = this.isGate() ? ConfigProvider.config.api.uri : 'https://suggestions.dadata.ru'
  token = () => ConfigProvider.config.token || StorageProvider.getSetting('cache.jwtToken')
  auth = () => (this.isGate() ? `Bearer ${this.token()}` : `Token ${ConfigProvider.config.dadata.token}`)

  async getAddress(search) {
    const { data } = await this.execute({
      uri: this.isGate() ? '/dadata-gate/address' : '/suggestions/api/4_1/rs/suggest/address',
      data: {
        query: search
      },
      method: 'POST',
      headers: {
        Authorization: this.auth()
      }
    })
    return fp.flow(
      fp.map(r => dadataAddressMap(r)),
      fp.filter(r => !!r.id)
    )(_.get(data, 'suggestions', []))
  }

  async getFMS(search) {
    const { data } = await this.execute({
      uri: this.isGate() ? '/dadata-gate/fms_unit' : '/suggestions/api/4_1/rs/suggest/fms_unit',
      data: {
        query: search
      },
      headers: {
        Authorization: this.auth()
      },
      method: 'POST'
    })
    return _.uniqBy(fp.map(dadataFmsMap)(_.get(data, 'suggestions', [])), v => v.value)
  }

  async getFio(search) {
    const { data } = await this.execute({
      uri: this.isGate() ? '/dadata-gate/fio' : '/suggestions/api/4_1/rs/suggest/fio',
      data: {
        query: search
      },
      headers: {
        Authorization: this.auth()
      },
      method: 'POST'
    })
    return _.uniqBy(fp.map(dadataFioMap)(_.get(data, 'suggestions', [])), v => v.value)
  }
}

export { DadataProvider }
export default new DadataProvider()
