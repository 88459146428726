import _ from 'lodash'
import validator from 'validator'
import { Mixin } from 'mixwith'
import menuModel from 'models/menuModel'
import deepMerge from 'deepmerge'
import history, { orderHistory } from 'utils/history'
import { action, computed, observable, toJS } from 'mobx'
import { validRussianWord } from 'utils/validators/russian'
import QuestionaryProvider from 'providers/api-provider/questionary-provider'
import pickupModel from 'models/pickupModel'
import { notifyError, getDateByAge, phoneNumberNormalizer, getAge, safeObject, validRequired, isSafe } from 'uikit'

const OtherData = Mixin(Super => class extends Super {
  // One subStep
  @observable birthDay = null

  // Education subStep
  @observable educationType = null

  // Two subStep
  @observable familyStatus = null
  @observable familyFirstName = null
  @observable familyLastName = null
  @observable familyPatronymic = null
  @observable birthdayFamily = null
  @observable registrationDateFamily = null
  @observable childrenCount = '0'
  @observable weeklyCount = '0'

  // Three subStep
  @observable totalExperienceYear = ''
  @observable totalExperienceMonth = ''
  @observable employmentType = null
  @observable organizationName = ''
  @observable organizationAddress = null
  @observable havingsType = null
  @observable activityType = null
  @observable activityVector = null
  @observable position = null
  @observable workPhone = ''
  @observable workEmail = ''
  @observable workBeginDate = null
  @observable contactPhone = ''

  // Four subStep
  @observable incomes = [
    { incomeType: null, sumIncome: '' }
  ]

  // Five subStep
  @observable contactPersonFirstName = ''
  @observable contactPersonPatronymic = ''
  @observable contactPersonPhone = ''
  @observable contactFamilyType = null

  // Six subStep
  @observable keyWord = ''

  @observable activeOtherPanel = 'education'
  @observable isChangeOtherDataEducation = false
  @observable isChangeOtherDataTwo = false
  @observable isChangeOtherDataThree = false
  @observable isChangeOtherDataFour = false
  @observable isChangeOtherDataFive = false
  @observable isChangeOtherDataSix = false
  @observable existOtherDataSteps = []
  @observable isNextOtherDataLoading = false
  @observable contactDataValidationEnabled = false
  @observable contactPersonValidationEnabled = false
  @observable educationValidationEnabled = false
  @observable employmentValidationEnabled = false
  @observable familyValidationEnabled = false
  @observable incomesValidationEnabled = false
  @observable keyWordValidationEnabled = false

  @action enableIsChangeOtherDataEducation = () => {
    this.isChangeOtherDataEducation = true
  }

  @action enableIsChangeOtherDataTwo = () => {
    this.isChangeOtherDataTwo = true
  }

  @action enableIsChangeOtherDataThree = () => {
    this.isChangeOtherDataThree = true
  }

  @action enableIsChangeOtherDataFour = () => {
    this.isChangeOtherDataFour = true
  }

  @action enableIsChangeOtherDataFive = () => {
    this.isChangeOtherDataFive = true
  }

  @action enableIsChangeOtherDataSix = () => {
    this.isChangeOtherDataSix = true
  }

  @action enableOtherDataValidation = () => {
    this.setErrorSubStep()
    switch (this.activeOtherPanel) {
      case 'education':
        this.educationValidationEnabled = true
        break
      case '2':
        this.familyValidationEnabled = true
        break
      case '3':
        this.employmentValidationEnabled = true
        this.enableIsChangeOtherDataThree()
        break
      case '4':
        this.incomesValidationEnabled = true
        break
      case '5':
        this.contactPersonValidationEnabled = true
        break
      case '6':
        this.keyWordValidationEnabled = true
        break
    }
  }

  @action setErrorSubStep = () => {
    if (this.otherDataErrorCountEducation + this.otherDataEmptyCountEducation > 0) {
      this.applyData({
        activeOtherPanel: 'education'
      })
      return
    }
    if (this.otherDataErrorCountTwo + this.otherDataEmptyCountTwo > 0) {
      this.applyData({
        activeOtherPanel: '2'
      })
      return
    }
    if (this.otherDataErrorCountThree + this.otherDataEmptyCountThree > 0) {
      this.applyData({
        activeOtherPanel: '3'
      })
      return
    }
    if (this.otherDataErrorCountFour + this.otherDataEmptyCountFour > 0) {
      this.applyData({
        activeOtherPanel: '4'
      })
      return
    }
    if (this.otherDataErrorCountFive + this.otherDataEmptyCountFive > 0) {
      this.applyData({
        activeOtherPanel: '5'
      })
    }
  }

  @computed get validEducationType() {
    if (validRequired(this.educationType)) return validRequired(this.educationType)
    return null
  }

  @computed get validFamilyStatus() {
    if (validRequired(this.familyStatus)) return validRequired(this.familyStatus)

    return null
  }

  @computed get validFamilyFirstName() {
    if (this.familyFirstName === '' || !isSafe(this.familyFirstName)) return 'Обязательное поле'
    if (validRussianWord(this.familyFirstName)) return validRussianWord(this.familyFirstName)

    return null
  }

  @computed get validFamilyLastName() {
    if (this.familyLastName === '' || !isSafe(this.familyLastName)) return 'Обязательное поле'
    if (validRussianWord(this.familyLastName)) return validRussianWord(this.familyLastName)

    return null
  }

  @computed get validFamilyPatronymic() {
    if (validRussianWord(this.familyPatronymic)) return validRussianWord(this.familyPatronymic)

    return null
  }

  @computed get validRegistrationDateFamily() {
    if (this.familyStatus !== 'civil_marriage') {
      if (validRequired(this.registrationDateFamily)) return validRequired(this.registrationDateFamily)
    }
    if (+this.registrationDateFamily < +getDateByAge(100) ||
      +this.registrationDateFamily >= +new Date() ||
      +this.registrationDateFamily >= +new Date()) return 'Некорректная дата'

    return null
  }

  @computed get validBirthdayFamily() {
    if (validRequired(this.birthdayFamily)) return validRequired(this.birthdayFamily)
    if (+this.birthdayFamily < +getDateByAge(100) ||
      +this.birthdayFamily >= +new Date() ||
      +this.birthdayFamily >= +new Date()) return 'Некорректная дата'

    return null
  }

  @computed get validChildrenCount() {
    if (this.childrenCount === '') return 'Обязательное поле'
    if (this.childrenCount > 20 || this.childrenCount < 0) return 'Некорректное количество'

    return null
  }

  @computed get validWeeklyCount() {
    if (this.weeklyCount === '') return 'Обязательное поле'
    if (this.weeklyCount > 20 || this.weeklyCount < 0) return 'Некорректное количество'

    return null
  }

  @computed get validTotalExperienceYears() {
    if (this.isChangeOtherDataThree) {
      if (this.totalExperienceYear === '' && this.totalExperienceMonth === '') return 'Укажите хотя бы одно из значений'
      if ((Number(this.totalExperienceYear) + Number(this.totalExperienceMonth)) > getAge({ date: this.birthDay })) {
        return 'Стаж работы не может превышать Ваш возраст'
      }
    }

    return null
  }

  @computed get totalExperienceMonths() {
    if (this.isChangeOtherDataThree) {
      if (this.totalExperienceYear === '' && this.totalExperienceMonth === '') return ' '
      if ((Number(this.totalExperienceYear) + Number(this.totalExperienceMonth)) > getAge({ date: this.birthDay })) {
        return ' '
      }
    }

    return null
  }

  @computed get validEmploymentType() {
    if (validRequired(this.employmentType)) return validRequired(this.employmentType)

    return null
  }

  @computed get validOrganizationName() {
    if (this.organizationName === '') return 'Обязательное поле'
    if (validRussianWord(this.organizationName)) return validRussianWord(this.organizationName)
    if (this.organizationName.length < 2) return 'Должно быть больше 2 символов'

    return null
  }

  @computed get validOrganizationAddress() {
    if (validRequired(this.organizationAddress)) return validRequired(this.organizationAddress)
    return null
  }

  @computed get validHavingsType() {
    if (validRequired(this.havingsType)) return validRequired(this.havingsType)

    return null
  }

  @computed get validActivityType() {
    if (validRequired(this.activityType)) return validRequired(this.activityType)

    return null
  }

  @computed get validActivityVector() {
    if (validRequired(this.activityVector)) return validRequired(this.activityVector)

    return null
  }

  @computed get validPosition() {
    if (validRequired(this.position)) return validRequired(this.position)
    if (validRussianWord(this.position)) return validRussianWord(this.position)

    return null
  }

  @computed get validWorkPhone() {
    if (this.workPhone === '') return 'Обязательное поле'
    if (this.workPhone.length < 10) return 'Неверный формат'
    if (this.mobilePhone === this.workPhone) return `Должен отличаться от +7 ${phoneNumberNormalizer(`${this.mobilePhone}`)}`

    return null
  }

  @computed get validContactPhone() {
    if (this.contactPhone === '') return 'Обязательное поле'
    if (this.contactPhone.length < 10) return 'Неверный формат'
    if (this.contactPhone === this.mobilePhone) return `Должен отличаться от +7 ${phoneNumberNormalizer(this.mobilePhone)}`

    return null
  }

  @computed get validWorkEmail() {
    if (this.workEmail === '') return null
    if (!validator.isEmail(this.workEmail)) return 'Некорректный e-mail'

    return null
  }

  @computed get validWorkBeginDate() {
    if (+this.workBeginDate < +getDateByAge(100) ||
      +this.workBeginDate >= +new Date() ||
      !isSafe(this.workBeginDate)) return 'Обязательное поле'

    return null
  }

  @action setIncomes = (field, index) => action(v => {
    const resolve = this.incomes.slice()
    _.set(resolve, `${index}.${field}`, v)
    this.applyData({
      incomes: resolve
    })
  })

  @action removeIncomes = index => action(() => {
    this.incomes.splice(index, 1)
  })

  @action addIncome = () => {
    this.incomes.push({
      incomeType: null,
      sumIncome: ''
    })
  }

  @computed get validContactPersonFirstName() {
    if (this.contactPersonFirstName === '' || !isSafe(this.contactPersonFirstName)) return 'Обязательное поле'
    if (validRussianWord(this.contactPersonFirstName)) return validRussianWord(this.contactPersonFirstName)

    return null
  }

  @computed get validContactPersonPatronymic() {
    if (validRussianWord(this.contactPersonPatronymic)) return validRussianWord(this.contactPersonPatronymic)

    return null
  }

  @computed get validContactPersonPhone() {
    if (this.contactPersonPhone === '' || !isSafe(this.contactPersonPhone)) return 'Обязательное поле'
    if (this.contactPersonPhone.length < 10) return 'Неверный формат'
    if (this.contactPersonPhone === this.mobilePhone) return `Должен отличаться от +7 ${phoneNumberNormalizer(this.mobilePhone)}`
    if (this.contactPersonPhone === this.contactPhone) return `Должен отличаться от +7 ${phoneNumberNormalizer(this.contactPhone)}`

    return null
  }

  @computed get validContactFamilyType() {
    if (validRequired(this.contactFamilyType)) return validRequired(this.contactFamilyType)

    return null
  }

  @computed get validKeyword() {
    if (this.keyWord === '') return 'Обязательное поле'
    if (!/^[ЁА-яё]+$/.test(this.keyWord)) return 'Только русские буквы'
    if (this.keyWord.length < 5) return 'Должно быть больше 5 символов'
    if (this.keyWord.length > 35) return 'Должно быть меньше 35 символов'

    return null
  }

  @computed get isChangedOtherData() {
    switch (this.activeOtherPanel) {
      case 'education':
        return this.isChangeOtherDataEducation
      case '2':
        return this.isChangeOtherDataTwo
      case '3':
        return this.isChangeOtherDataThree
      case '4':
        return this.isChangeOtherDataFour
      case '5':
        return this.isChangeOtherDataFive
      case '6':
        return this.isChangeOtherDataSix
      default:
        return false
    }
  }

  @computed get otherDataEmptyCountEducation() {
    if (this.isChangeOtherDataEducation) {
      let count = 0
      if (!isSafe(this.educationType)) count += 1
      return count
    }

    return 0
  }

  @computed get otherDataErrorCountEducation() {
    if (this.isChangeOtherDataEducation) {
      let count = 0
      if (this.validEducationType) count += 1

      const res = count - this.otherDataEmptyCountEducation
      return res > 0 ? res : 0
    }

    return 0
  }

  @computed get otherDataEmptyCountTwo() {
    if (this.isChangeOtherDataTwo) {
      let count = 0
      if (!isSafe(this.familyStatus)) count += 1

      if (this.familyStatus !== 'single' && !!this.familyStatus) {
        if (this.isMarriedOtherData) {
          if (this.familyFirstName === '' || !isSafe(this.familyFirstName)) count += 1
          if (this.familyLastName === '' || !isSafe(this.familyLastName)) count += 1
          if (!isSafe(this.birthdayFamily)) count += 1
          if (this.familyStatus !== 'civil_marriage' && !isSafe(this.registrationDateFamily)) count += 1
        }
      }
      if (this.childrenCount === '') count += 1
      if (this.weeklyCount === '') count += 1
      return count
    }

    return 0
  }

  @computed get isMarriedOtherData() {
    return this.familyStatus === 'married' || this.familyStatus === 'civil_marriage'
  }

  @computed get otherDataErrorCountTwo() {
    if (this.isChangeOtherDataTwo) {
      let count = 0
      if (this.validFamilyStatus) count += 1

      if (this.familyStatus !== 'single' && !!this.familyStatus) {
        if (this.isMarriedOtherData) {
          if (this.validFamilyFirstName) count += 1
          if (this.validFamilyLastName) count += 1
          if (this.validFamilyPatronymic) count += 1
          if (this.validBirthdayFamily) count += 1
          if (this.validRegistrationDateFamily) count += 1
        }
      }

      if (this.validChildrenCount) count += 1
      if (this.validWeeklyCount) count += 1

      const res = count - this.otherDataEmptyCountTwo
      return res > 0 ? res : 0
    }

    return 0
  }

  @computed get otherDataEmptyCountThree() {
    if (this.isChangeOtherDataThree) {
      let count = 0
      if (this.totalExperienceYear === '' && this.totalExperienceMonth === '') count += 1
      if (!isSafe(this.employmentType)) count += 1
      if (this.isWorkingEmploymentOtherData) {
        if (this.organizationName === '') count += 1
        if (!this.organizationAddress) count += 1
        if (!isSafe(this.havingsType)) count += 1
        if (!isSafe(this.activityType)) count += 1
        if (!isSafe(this.activityVector)) count += 1
        if (this.position === '') count += 1
        if (this.workPhone === '') count += 1
        if (!isSafe(this.workBeginDate)) count += 1
      } else if (this.contactPhone === '') count += 1
      return count
    }

    return 0
  }

  @computed get otherDataErrorCountThree() {
    if (this.isChangeOtherDataThree) {
      let count = 0
      if (this.validTotalExperience) count += 1
      if (this.validEmploymentType) count += 1
      if (this.isWorkingEmploymentOtherData) {
        if (this.validOrganizationName) count += 1
        if (this.validOrganizationAddress) count += 1
        if (this.validHavingsType) count += 1
        if (this.validActivityType) count += 1
        if (this.validPosition) count += 1
        if (this.validWorkPhone) count += 1
        if (this.validWorkEmail) count += 1
        if (this.validWorkBeginDate) count += 1
      } else if (this.validContactPhone) count += 1

      const res = count - this.otherDataEmptyCountThree
      return res > 0 ? res : 0
    }

    return 0
  }

  @computed get isWorkingEmploymentOtherData() {
    return this.employmentType === 'permanent' || this.employmentType === 'temporary' ||
      this.employmentType === 'working_retiree' || this.employmentType === 'businessman'
  }

  @computed get otherDataEmptyCountFour() {
    if (this.isChangeOtherDataFour) {
      let count = 0

      _.each(this.incomes, income => {
        if (!isSafe(income.incomeType)) count += 1
        if (income.sumIncome === '') count += 1
      })

      if (_.isEmpty(this.incomes)) count += 1

      return count
    }

    return 0
  }

  @computed get otherDataErrorCountFour() {
    if (this.isChangeOtherDataFour) {
      let count = 0

      _.each(this.incomes, income => {
        if (!isSafe(income.incomeType)) count += 1
        if (income.sumIncome === '') count += 1
        else if (income.sumIncome <= 0) count += 1
      })

      if (_.isEmpty(this.incomes)) count += 1

      const res = count - this.otherDataEmptyCountFour
      return res > 0 ? res : 0
    }

    return 0
  }

  @computed get otherDataEmptyCountFive() {
    if (this.isChangeOtherDataFive) {
      let count = 0

      if (this.contactPersonFirstName === '' || !isSafe(this.contactPersonFirstName)) count += 1
      if (this.contactPersonPhone === '' || !isSafe(this.contactPersonPhone)) count += 1
      if (!isSafe(this.contactFamilyType)) count += 1

      return count
    }

    return 0
  }

  @computed get otherDataErrorCountFive() {
    if (this.isChangeOtherDataFive) {
      let count = 0
      if (this.validContactPersonFirstName) count += 1
      if (this.validContactPersonPatronymic) count += 1
      if (this.validContactPersonPhone) count += 1
      if (this.validContactFamilyType) count += 1

      const res = count - this.otherDataEmptyCountFive
      return res > 0 ? res : 0
    }

    return 0
  }

  @computed get otherDataEmptyCountSix() {
    if (this.isChangeOtherDataSix) {
      let count = 0

      if (this.keyWord === '') count += 1

      return count
    }

    return 0
  }

  @computed get otherDataErrorCountSix() {
    if (this.isChangeOtherDataSix) {
      let count = 0
      if (this.validKeyword) count += 1

      const res = count - this.otherDataEmptyCountFive
      return res > 0 ? res : 0
    }

    return 0
  }

  @computed get otherDataEmptyCount() {
    switch (this.activeOtherPanel) {
      case 'education':
        return this.otherDataEmptyCountEducation
      case '2':
        return this.otherDataEmptyCountTwo
      case '3':
        return this.otherDataEmptyCountThree
      case '4':
        return this.otherDataEmptyCountFour
      case '5':
        return this.otherDataEmptyCountFive
      case '6':
        return this.otherDataEmptyCountSix
      default:
        return 0
    }
  }

  @computed get otherDataErrorCount() {
    switch (this.activeOtherPanel) {
      case 'education':
        return this.otherDataErrorCountEducation
      case '2':
        return this.otherDataErrorCountTwo
      case '3':
        return this.otherDataErrorCountThree
      case '4':
        return this.otherDataErrorCountFour
      case '5':
        return this.otherDataErrorCountFive
      case '6':
        return this.otherDataErrorCountSix
      default:
        return 0
    }
  }

  @computed get isNextOtherData() {
    let isChange = false

    switch (this.activeOtherPanel) {
      case 'education':
        isChange = this.isChangeOtherDataEducation
        break
      case '2':
        isChange = this.isChangeOtherDataTwo
        break
      case '3':
        isChange = this.isChangeOtherDataThree
        break
      case '4':
        isChange = this.isChangeOtherDataFour
        break
      case '5':
        isChange = this.isChangeOtherDataFive
        break
      case '6':
        isChange = (this.isChangeOtherDataSix)
        break
    }

    return isChange && this.otherDataErrorCount + this.otherDataEmptyCount === 0 &&
      (this.activeOtherPanel === '6' ? (
        (this.otherDataErrorCountOne + this.otherDataEmptyCountOne === 0) ||
        (this.otherDataErrorCountEducation + this.otherDataEmptyCountEducation === 0) ||
        (this.otherDataErrorCountTwo + this.otherDataEmptyCountTwo === 0) ||
        (this.otherDataErrorCountThree + this.otherDataEmptyCountThree === 0) ||
        (this.otherDataErrorCountFour + this.otherDataEmptyCountFour === 0) ||
        (this.otherDataErrorCountFive + this.otherDataEmptyCountFive === 0) ||
        (this.otherDataErrorCountSix + this.otherDataEmptyCountSix === 0)
      ) : true)
  }

  @computed get otherDataProgress() {
    const errorCount = (this.isChangeOtherDataEducation ? this.otherDataErrorCountEducation + this.otherDataEmptyCountEducation > 0 ? 1 : 0 : 1) +
      (this.isChangeOtherDataTwo ? this.otherDataErrorCountTwo + this.otherDataEmptyCountTwo > 0 ? 1 : 0 : 1) +
      (this.isChangeOtherDataThree ? this.otherDataErrorCountThree + this.otherDataEmptyCountThree > 0 ? 1 : 0 : 1) +
      (this.isChangeOtherDataFour ? this.otherDataErrorCountFour + this.otherDataEmptyCountFour > 0 ? 1 : 0 : 1) +
      (this.isChangeOtherDataFive ? this.otherDataErrorCountFive + this.otherDataEmptyCountFive > 0 ? 1 : 0 : 1) +
      (this.isChangeOtherDataSix ? this.otherDataErrorCountSix + this.otherDataEmptyCountSix > 0 ? 1 : 0 : 1)

    switch (errorCount) {
      case 7: return 0
      case 6: return 14
      case 5: return 29
      case 4: return 43
      case 3: return 57
      case 2: return 71
      case 1: return 86
      default: return 100
    }
  }

  @computed get isExistOtherDataStep() {
    let isChange = false

    switch (this.activeOtherPanel) {
      case 'education':
        isChange = this.isChangeOtherDataEducation
        break
      case '2':
        isChange = this.isChangeOtherDataTwo
        break
      case '3':
        isChange = this.isChangeOtherDataThree
        break
      case '4':
        isChange = this.isChangeOtherDataFour
        break
      case '5':
        isChange = this.isChangeOtherDataFive
        break
      case '6':
        isChange = this.isChangeOtherDataSix
        break
    }

    return _.findIndex(this.existOtherDataSteps, s => s === this.activeOtherPanel) !== -1 &&
      isChange
  }

  @action addToExistOtherDataSteps = step => {
    const existOtherDataSteps = this.existOtherDataSteps.slice()
    if (_.findIndex(existOtherDataSteps, s => s === step) === -1) {
      existOtherDataSteps.push(step)
      this.existOtherDataSteps = existOtherDataSteps
    }
  }

  backOtherData = e => {
    if (e && e.target) {
      e.target.blur()
    }

    switch (this.activeOtherPanel) {
      case 'education':
        menuModel.changeStep(2, orderHistory('/additional_data'))
        break
      case '2':
        this.applyData({ activeOtherPanel: 'education' })
        break
      case '3':
        this.applyData({ activeOtherPanel: '2' })
        break
      case '4':
        this.applyData({ activeOtherPanel: '3' })
        break
      case '5':
        this.applyData({ activeOtherPanel: '4' })
        break
      case '6':
        this.applyData({ activeOtherPanel: '5' })
        break
    }
  }

  nextOtherData = (activeOtherPanel = null) => async e => {
    if (e && e.target) {
      e.target.blur()
    }

    let isSaveError = false
    const existOtherDataSteps = this.existOtherDataSteps.slice()
    try {
      if (this.isNextOtherData && activeOtherPanel !== this.activeOtherPanel) {
        this.applyData({ isNextOtherDataLoading: true })

        let prepare = {}
        let subStep = ''

        switch (this.activeOtherPanel) {
          case 'education':
            prepare = {
              educationType: this.educationType
            }
            subStep = 'education'
            break
          case '2':
            prepare = {
              familyStatus: this.familyStatus,
              familyFirstName: this.isMarriedOtherData ? this.familyFirstName : null,
              familyLastName: this.isMarriedOtherData ? this.familyLastName : null,
              familyPatronymic: this.isMarriedOtherData ? this.familyPatronymic : null,
              birthdayFamily: this.isMarriedOtherData ? this.birthdayFamily : null,
              registrationDateFamily: this.isMarriedOtherData ? this.registrationDateFamily : null,
              childrenCount: this.childrenCount,
              weeklyCount: this.weeklyCount
            }
            subStep = 'family'
            break
          case '3':
            prepare = {
              totalExperience: (this.totalExperienceYear * 12) + Number(this.totalExperienceMonth),
              employmentType: this.employmentType,
              organizationName: this.isWorkingEmploymentOtherData ? this.organizationName : null,
              havingsType: this.isWorkingEmploymentOtherData ? this.havingsType : null,
              activityType: this.isWorkingEmploymentOtherData ? this.activityType : null,
              activityVector: this.isWorkingEmploymentOtherData ? this.activityVector : null,
              position: this.isWorkingEmploymentOtherData ? this.position : null,
              workPhone: this.isWorkingEmploymentOtherData ? this.workPhone : null,
              workEmail: this.isWorkingEmploymentOtherData ? this.workEmail : null,
              workBeginDate: this.isWorkingEmploymentOtherData ? this.workBeginDate : null,
              totalExperienceWork: this.isWorkingEmploymentOtherData ? this.totalExperienceWork : null,
              organizationAddress: this.isWorkingEmploymentOtherData ? this.organizationAddress : null,
              contactPhone: !this.isWorkingEmploymentOtherData ? this.contactPhone : null
            }
            subStep = 'employment'
            break
          case '4':
            prepare = {
              incomes: this.incomes
            }
            subStep = 'incomes'
            break
          case '5':
            prepare = {
              contactPersonFirstName: this.contactPersonFirstName,
              contactPersonPatronymic: this.contactPersonPatronymic,
              contactPersonPhone: this.contactPersonPhone,
              contactFamilyType: this.contactFamilyType
            }
            subStep = 'contact_person'
            break
          case '6':
            prepare = {
              keyWord: this.keyWord
            }
            subStep = 'keyword'
            break
        }

        await QuestionaryProvider.updateQuestionary({
          orderID: pickupModel.order.id,
          questionary: prepare,
          subStep
        })
      }
    } catch (err) {
      notifyError('Ошибка сохранения прочих данных')
      isSaveError = true
    } finally {
      if (_.findIndex(existOtherDataSteps, s => this.activeOtherPanel === s) === -1 &&
        this.isChangedOtherData) {
        existOtherDataSteps.push(this.activeOtherPanel)
      }
      this.applyData({
        isNextOtherDataLoading: false,
        existOtherDataSteps
      })
    }

    if (isSaveError) return

    if (activeOtherPanel) {
      if (activeOtherPanel === '4') {
        let incomes = null
        if (this.incomes.length === 1 && !isSafe(_.get(this.incomes, '0.incomeType', null)) && _.get(this.incomes, '0.sumIncome', '') === '') {
          incomes = toJS(deepMerge([], this.incomes))
          _.set(incomes, '0.sumIncome', this.income)
        }
        this.applyData(safeObject({
          activeOtherPanel: '4',
          incomes
        }))
      } else {
        this.applyData({ activeOtherPanel })
      }
    } else {
      switch (this.activeOtherPanel) {
        case 'education':
          this.applyData({ activeOtherPanel: '2' })
          break
        case '2':
          this.applyData({ activeOtherPanel: '3' })
          break
        case '3': {
          let incomes = null
          if (this.incomes.length === 1 && !isSafe(_.get(this.incomes, '0.incomeType', null)) && _.get(this.incomes, '0.sumIncome', '') === '') {
            incomes = toJS(deepMerge([], this.incomes))
            _.set(incomes, '0.sumIncome', this.income)
          }
          this.applyData(safeObject({
            activeOtherPanel: '4',
            incomes
          }))
          break
        }
        case '4':
          this.applyData({ activeOtherPanel: '5' })
          break
        case '5':
          this.applyData({ activeOtherPanel: '6' })
          break
        case '6':
          history.push(orderHistory('/credit_result', pickupModel.order.id))
          break
      }
    }
  }
})

export default OtherData
