import React from 'react'
import _ from 'lodash'
import axios from 'axios'
import { safeObject } from 'uikit'
import ConfigProvider from 'providers/config-provider'

const axiosCleint = axios.create({
  responseType: 'json',
  maxRedirects: 0
})

class BaseHttpProvider {
  defaultApiPrefix = ''
  host = ConfigProvider.config.api.uri
  defaultHeaders = {}

  async execute({
    uri,
    method,
    data,
    isPrefix = true,
    prefix,
    responseType,
    params,
    headers,
    host
  }) {
    const currentHost = host || this.host
    const prepareRequest = safeObject({
      method: method || 'GET',
      url: `${currentHost === '/' ? '' : currentHost}${isPrefix ? prefix || this.defaultApiPrefix : ''}${uri}`,
      data: data || null,
      responseType: responseType || 'json',
      params: params || null,
      headers: safeObject({
        ...this.defaultHeaders,
        ...headers,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Expose-Headers': '*',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Methods': 'GET, HEAD, POST, PUT, PATCH, DELETE, OPTIONS'
      })
    })
    return await axiosCleint(prepareRequest)
  }
}

export default BaseHttpProvider
