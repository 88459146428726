import _ from 'lodash'
import fp from 'lodash/fp'
import BaseHttpProvider from '../base-http-provider'

class ApiProvider extends BaseHttpProvider {
  defaultApiPrefix = '/api/v1'

  async getDict(orderID) {
    const { data } = await this.execute({
      uri: `/orders/${orderID}/dictionaries`
    })

    const resolve = {}
    _.each(data, (value, key) => {
      resolve[key] = fp.map(d => ({
        value: `${_.get(d, 'value', '')}`,
        label: `${_.get(d, 'text', '')}`
      }))(value)
    })
    return resolve
  }
}

export { ApiProvider }
export default new ApiProvider()
