import { action, computed, observable } from 'mobx'
import history, { orderHistory } from 'utils/history'
import QuestionaryProvider from 'providers/api-provider/questionary-provider'
import { notifyError } from 'uikit'
import BaseModel from '../baseModel'
import questionaryModel from '../questionaryModel'
import pickupModel from '../pickupModel'

class HelpModalModel extends BaseModel {
  @observable firstName = ''
  @observable lastName = ''
  @observable patronymic = ''
  @observable mobilePhone = ''
  @observable confirmCode = ''

  @observable title = 'Заявка на кредит'
  @observable subTitle = 'Для продолжения заполните контактные данные:'
  @observable successText = 'Заявка передана в Контакт-Центр! Ожидайте звонка оператора.'

  @observable isOpen = false
  @observable isSendPhoneCode = false
  @observable isConfirmCode = false
  @observable confirmKey = ''
  @observable isSendConfirmKey = false
  @observable isSend = false
  @observable isLoading = false
  @observable isLoadingConfirmCode = false
  @observable isShowDetails = false

  @action applyHelpModalModel = () => {
    this.firstName = questionaryModel.firstName
    this.lastName = questionaryModel.lastName
    this.patronymic = questionaryModel.patronymic
    this.mobilePhone = questionaryModel.mobilePhone
    this.confirmCode = questionaryModel.confirmCode
    this.confirmKey = questionaryModel.confirmCodeID
    this.isConfirmCode = questionaryModel.isConfirmPhone
    this.isOpen = true
  }
  @action setMobilePhone = v => {
    if (v === this.mobilePhone) return
    this.mobilePhone = v
    this.confirmCode = ''
    this.isConfirmCode = false
    this.isSendPhoneCode = false
  }
  @action setFirstName = v => {
    this.firstName = v
  }
  @action setLastName = v => {
    this.lastName = v
  }
  @action setPatronymic = v => {
    this.patronymic = v
  }
  @action setConfirmCode = v => {
    this.confirmCode = v
  }
  @action setIsOpen = v => {
    this.isOpen = v
  }
  @action setIsShowDetails = v => {
    this.isShowDetails = v
  }
  @action setIsSend = v => {
    this.isSend = v
  }
  @action handleSendConfirmCode = async () => {
    try {
      this.isSendConfirmKey = true
      const { confirmCodeID } = await QuestionaryProvider.sendConfirmPhone(this.mobilePhone, pickupModel.order.id)
      this.applyData({
        confirmKey: confirmCodeID,
        isSendPhoneCode: true
      })
    } catch (e) {
      notifyError('Не удается отправить код подтверждения.')
    } finally {
      this.applyData({ isSendConfirmKey: false })
    }
  }
  @action handleSend = async () => {
    try {
      this.isLoading = true
      const params = {
        lastName: this.lastName,
        firstName: this.firstName,
        patronymic: this.patronymic,
        mobilePhone: this.mobilePhone,
        confirmCodeID: this.confirmKey
      }
      if (!questionaryModel.id) {
        await QuestionaryProvider.sendCallMe({
          orderID: pickupModel.order.id,
          params
        })
      } else {
        await QuestionaryProvider.sendCallMe({
          orderID: pickupModel.order.id
        })
      }
      pickupModel.setCallCenterCall()
      this.applyData({
        lastName: '',
        firstName: '',
        patronymic: '',
        mobilePhone: '',
        isConfirmCode: false,
        isSendPhoneCode: false,
        confirmCode: '',
        confirmKey: '',
        isSend: true,
        isOpen: false
      })
    } catch (e) {
      notifyError('Не удается отправить заявку.')
    } finally {
      this.applyData({
        isLoading: false
      })
    }
  }
  @action handleConfirm = async () => {
    try {
      this.isLoadingConfirmCode = true
      await QuestionaryProvider.sendConfirmCode(this.confirmCode, this.mobilePhone, pickupModel.order.id, this.confirmKey)
      this.applyData({
        isConfirmCode: true,
        confirmCode: ''
      })
      if (this.lastName && this.firstName) await this.handleSend()
    } catch (e) {
      notifyError('Не удается подтвердить код.')
    } finally {
      this.applyData({
        isLoadingConfirmCode: false
      })
    }
  }
}

export { HelpModalModel }
export default new HelpModalModel()
