import _ from 'lodash'
import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import history from 'utils/history'
import PropTypes from 'prop-types'
import { Icon, Alert, mountHook } from 'uikit'
import Applications from './Applications'
import Progress from './Progress'
import Negative from './Negative'

const Pickup = ({ menuModel, pickupModel, questionaryModel, applicationModel, responsiveModel, isNew, ...props }) => {
  mountHook(async () => {
    menuModel.changeStep(4)

    const orderID = _.get(props, 'match.params.orderID', null)

    if (orderID) {
      if (isNew) {
        await pickupModel.getOrder(orderID, true)
      } else if (!questionaryModel.id) {
        await questionaryModel.getQuestionary(orderID)
      }
    } else {
      history.push('/error_order')
    }
  })

  return (
    <Fragment>
      {applicationModel.isExistPositive && <Applications/>}
      {applicationModel.isExistProgress && <Progress/>}
      {applicationModel.isExistNegative && <Negative/>}
      {!applicationModel.isExistPositive && !applicationModel.isExistProgress && !applicationModel.isExistNegative &&
      <Alert
        message="Мы отправили дополнительные данные в банки для рассмотрения заявок на кредит.  Продолжайте заполнять анкету, чтобы получить результаты одобрений!"
        type="info"
        icon={<Icon iconType="design" icon="info-circle" color="primary"/>}
        isClosable
        marginTop={!responsiveModel.isPhone ? 3 : 1}
      />
      }
    </Fragment>
  )
}

Pickup.propTypes = {
  isNew: PropTypes.bool
}

Pickup.defaultProps = {
  isNew: true
}

export default inject(store => ({
  menuModel: store.menuModel,
  questionaryModel: store.questionaryModel,
  pickupModel: store.pickupModel,
  applicationModel: store.applicationModel,
  responsiveModel: store.responsiveModel
}))(observer(Pickup))
