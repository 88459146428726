import _ from 'lodash'

export const dadataAddressMap = r => {
  const kladrID = _.get(r.data, 'kladr_id', '')

  return {
    id: _.get(r, 'data.fias_id', null),
    value: _.get(r, 'value', ''),
    unrestrictedValue: _.get(r, 'unrestricted_value', ''),
    fiasCode: _.get(r.data, 'fias_code', null),
    postCode: _.get(r.data, 'postal_code', null),
    country: _.get(r.data, 'country', ''),
    region: _.get(r.data, 'region', ''),
    regionType: _.get(r.data, 'region_type', ''),
    regionCode: kladrID ? kladrID.slice(0, 2) : null,
    city: _.get(r.data, 'city', ''),
    cityType: _.get(r.data, 'city_type', ''),
    cityDistrict: _.get(r.data, 'city_district', null),
    cityDistrictType: _.get(r.data, 'city_district_type', null),
    district: _.get(r.data, 'area', null),
    districtType: _.get(r.data, 'area_type', null),
    locality: _.get(r.data, 'settlement', null),
    localityType: _.get(r.data, 'settlement_type', null),
    street: _.get(r.data, 'street', ''),
    streetType: _.get(r.data, 'street_type', ''),
    blockNumber: _.get(r.data, 'block', null),
    blockType: _.get(r.data, 'block_type', null),
    apartmentNumber: _.get(r.data, 'flat', null),
    apartmentType: _.get(r.data, 'flat_type', null),
    house: _.get(r.data, 'house', ''),
    houseType: _.get(r.data, 'house_type', ''),
    kladrID
  }
}

export const dadataFmsMap = r => ({
  value: r.value,
  code: _.get(r.data, 'code'),
  name: _.get(r.data, 'name'),
  regionCode: _.get(r.data, 'region_code'),
  type: _.get(r.data, 'type')
})

export const dadataFioMap = r => ({
  value: r.value,
  source: _.get(r.data, 'source', ''),
  result: _.get(r.data, 'result', ''),
  resultGenitive: _.get(r.data, 'result_genitive', ''),
  resultDative: _.get(r.data, 'result_dative', ''),
  resultAblative: _.get(r.data, 'result_ablative', ''),
  surname: _.get(r.data, 'surname', ''),
  name: _.get(r.data, 'name', ''),
  patronymic: _.get(r.data, 'patronymic', ''),
  gender: _.get(r.data, 'gender', ''),
  qc: _.get(r.data, 'qc', '')
})
