import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { Input, Block, DatePicker, SelectPicker, namesNormalizer } from 'uikit'
import { OTHER_DATA_HTML_ID } from 'utils/htmlID'
import FioInput from 'components/FioInput'

const Family = ({ questionaryModel, dictModel, responsiveModel }) => {
  const {
    familyStatus,
    familyFirstName, familyLastName, familyPatronymic,
    birthdayFamily, registrationDateFamily,
    childrenCount, weeklyCount,

    isExistOtherDataStep, familyValidationEnabled
  } = questionaryModel
  const { isTabletAndPhone } = responsiveModel

  return (
    <Fragment>
      <Block
        width={!isTabletAndPhone ? 16 : '100%'}
        marginTop={-1}
      >
        <SelectPicker
          key="familyStatus"
          label="Семейное положение"
          value={familyStatus}
          options={dictModel.dict.marital_statuses}
          onChange={questionaryModel.setField('familyStatus')}
          error={questionaryModel.validFamilyStatus}
          tabIndex={1}
          isSearch={false}
          isShowForceValidate={isExistOtherDataStep || familyValidationEnabled}
          onBlur={questionaryModel.enableIsChangeOtherDataTwo}
          htmlID={`${OTHER_DATA_HTML_ID}-familyStatus`}
        />
      </Block>
      {questionaryModel.isMarriedOtherData &&
      <Fragment>
        <Block
          width={!isTabletAndPhone ? 16 : '100%'}
        >
          <FioInput
            key="familyLastName"
            isForm
            type="text"
            value={familyLastName}
            label="Фамилия супруга"
            onChange={questionaryModel.setField('familyLastName')}
            error={questionaryModel.validFamilyLastName}
            tabIndex={1}
            normalizer={namesNormalizer}
            htmlID={`${OTHER_DATA_HTML_ID}-familyLastName`}
            isShowForceValidate={isExistOtherDataStep || familyValidationEnabled}
            onBlur={() => {
              questionaryModel.enableIsChangeOtherDataTwo()
              questionaryModel.trimField('familyLastName')()
            }}
          />
        </Block>
        <Block
          width={!isTabletAndPhone ? 16 : '100%'}
          marginTop={1.5}
        >
          <FioInput
            key="familyFirstName"
            isForm
            type="text"
            value={familyFirstName}
            label="Имя супруга"
            onChange={questionaryModel.setField('familyFirstName')}
            error={questionaryModel.validFamilyFirstName}
            tabIndex={1}
            normalizer={namesNormalizer}
            htmlID={`${OTHER_DATA_HTML_ID}-familyFirstName`}
            isShowForceValidate={isExistOtherDataStep || familyValidationEnabled}
            onBlur={() => {
              questionaryModel.enableIsChangeOtherDataTwo()
              questionaryModel.trimField('familyFirstName')()
            }}
          />
        </Block>
        <Block
          width={!isTabletAndPhone ? 16 : '100%'}
          marginTop={1.5}
        >
          <FioInput
            marginBottom={3}
            key="familyPatronymic"
            isForm
            type="text"
            value={familyPatronymic}
            label="Отчество супруга"
            onChange={questionaryModel.setField('familyPatronymic')}
            error={questionaryModel.validFamilyPatronymic}
            tabIndex={1}
            normalizer={namesNormalizer}
            htmlID={`${OTHER_DATA_HTML_ID}-familyPatronymic`}
            isShowForceValidate={isExistOtherDataStep || familyValidationEnabled}
            onBlur={() => {
              questionaryModel.enableIsChangeOtherDataTwo()
              questionaryModel.trimField('familyPatronymic')()
            }}
            help="При наличии обязательно к заполнению"
          />
        </Block>
        <Block width={!isTabletAndPhone ? 16 : '100%'} marginTop={1.5}>
          <DatePicker
            key="birthdayFamily"
            label="Дата рождения супруга"
            tabIndex={1}
            value={birthdayFamily}
            onChange={questionaryModel.setField('birthdayFamily')}
            error={questionaryModel.validBirthdayFamily}
            isShowForceValidate={isExistOtherDataStep || familyValidationEnabled}
            onBlur={questionaryModel.enableIsChangeOtherDataTwo}
            htmlID={`${OTHER_DATA_HTML_ID}-birthdayFamily`}
          />
        </Block>
        <Block width={!isTabletAndPhone ? 16 : '100%'} marginTop={1.5}>
          <DatePicker
            key="registrationDateFamily"
            label="Дата регистрации брака"
            tabIndex={1}
            value={registrationDateFamily}
            onChange={questionaryModel.setField('registrationDateFamily')}
            error={questionaryModel.validRegistrationDateFamily}
            isShowForceValidate={isExistOtherDataStep || familyValidationEnabled}
            onBlur={questionaryModel.enableIsChangeOtherDataTwo}
            htmlID={`${OTHER_DATA_HTML_ID}-registrationDateFamily`}
          />
        </Block>
      </Fragment>
      }
      <Block
        width={!isTabletAndPhone ? 16 : '100%'}
        marginTop={1.5}
      >
        <Input
          key="childrenCount"
          label="Количество детей"
          value={childrenCount}
          onChange={questionaryModel.setField('childrenCount')}
          error={questionaryModel.validChildrenCount}
          type="number"
          tabIndex={1}
          isShowForceValidate={isExistOtherDataStep || familyValidationEnabled}
          min={0}
          max={20}
          onBlur={questionaryModel.enableIsChangeOtherDataTwo}
          htmlID={`${OTHER_DATA_HTML_ID}-childrenCount`}
        />
      </Block>
      <Block
        width={!isTabletAndPhone ? 16 : '100%'}
        marginTop={1.5}
        marginBottom={questionaryModel.isMarriedOtherData ? 0 : 3.2}
        onBlur={questionaryModel.enableIsChangeOtherDataTwo}
      >
        <Input
          key="weeklyCount"
          label="Количество иждивенцев"
          value={weeklyCount}
          onChange={questionaryModel.setField('weeklyCount')}
          error={questionaryModel.validWeeklyCount}
          type="number"
          tabIndex={1}
          isShowForceValidate={isExistOtherDataStep || familyValidationEnabled}
          onBlur={questionaryModel.enableIsChangeOtherDataTwo}
          htmlID={`${OTHER_DATA_HTML_ID}-weeklyCount`}
          min={0}
          max={20}
        />
      </Block>
    </Fragment>
  )
}

export default inject(store => ({
  questionaryModel: store.questionaryModel,
  dictModel: store.dictModel,
  responsiveModel: store.responsiveModel
}))(observer(Family))
