import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Block, Row, Col, Paragraph, Title } from 'uikit'

const Root = styled(props => {
  const resolveProps = { ...props }
  _.unset(resolveProps, 'isActive')
  _.unset(resolveProps, 'isExistSubItems')
  _.unset(resolveProps, 'isTopDivider')
  return <Block {...resolveProps}/>
})`
  user-select: none;
  background: ${props => (props.isActive ? props.theme.colors.gray1 : 'transparent')};
  padding: 1rem ${props => (!props.isExistSubItems ? '24px' : 0)} 1rem 24px;
  border-bottom: ${props => (!props.isExistSubItems ? `.07rem solid ${props.theme.colors.gray3}` : 'none')};
  border-top: ${props => (props.isTopDivider ? `.07rem solid ${props.theme.colors.gray3}` : 'none')};
`

const SubItem = styled(props => {
  const resolveProps = { ...props }
  _.unset(resolveProps, 'isLast')
  return <Block {...resolveProps}/>
})`
  border-bottom: ${props => (!props.isLast ? `.07rem solid ${props.theme.colors.gray3}` : 'none')};
  padding: 1rem 0;  
`

const Item = ({
  htmlID, title, description, isActive, subItems, isTopDivider,

  onClick
}) => (
  <Root
    isActive={isActive}
    isExistSubItems={!_.isEmpty(subItems)}
    isTopDivider={isTopDivider}
    onClick={onClick}
    cursor={!onClick ? 'not-allowed' : 'pointer'}
    htmlID={htmlID}
  >
    <Title level={4} marginBottom={0} type={isActive ? 'text' : 'secondary'}>
      {title}
    </Title>
    <Paragraph
      level={4}
      type="secondary"
      marginBottom={_.isEmpty(subItems) ? 0 : 0.5}
    >
      {description}
    </Paragraph>
    {!_.isEmpty(subItems) &&
      _.map(subItems, (item, i) => (
        <SubItem isLast={i === subItems.length - 1} key={i}>
          <Row isFlex justify="space-between">
            <Col>
              <Paragraph isNativeMargin={false}>
                {item.title}
              </Paragraph>
            </Col>
            <Col marginRight={2}>
              <Paragraph level={4} type="secondary" isNativeMargin={false}>
                {item.description}
              </Paragraph>
            </Col>
          </Row>
        </SubItem>
      ))
    }
  </Root>
)

Item.propTypes = {
  htmlID: PropTypes.string,
  title: PropTypes.any,
  description: PropTypes.any,
  isActive: PropTypes.bool,
  subItems: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.any,
    description: PropTypes.any
  })),
  isTopDivider: PropTypes.bool,

  onClick: PropTypes.func
}

Item.defaultProps = {
  isActive: false,
  subItems: [],
  isTopDivider: false
}

export default Item
