import _ from 'lodash'
import fp from 'lodash/fp'
import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled, { withTheme } from 'styled-components'
import {
  Alert, Text, Title, Row, Col, Icon, Block, Tooltip, ResponsiveDesktop, ResponsiveTabletAndPhone,
  isSafe
} from 'uikit'
import { CREDIT_RESULT_HTML_ID } from 'utils/htmlID'
import Application from './Application'
import AccentMonthlyPayment from './AccentMonthlyPayment'

const Header = styled(Row)`
  cursor: pointer;
  user-select: none;
`

const MonthlyContainer = styled(Text)`
  display: block;
  text-decoration:underline;
  text-decoration-style: dotted;
  cursor: pointer !important;
  z-index: 1;
`

const Applications = ({ applicationModel, responsiveModel }) => {
  const { positiveApplications, applicationsSort } = applicationModel
  const { field, isAsc } = applicationsSort

  const [isOver, setIsOver] = useState(false)
  const [isMonth, setIsMonth] = useState(true)

  const handleSetIsMonth = () => {
    setIsMonth(!isMonth)
  }

  const handleSort = (f, a) => () => {
    applicationModel.sortApplications(f, a)
  }

  return (
    <Block>
      <Title
          level={3}
          marginTop={!responsiveModel.isPhone ? 3 : 1}
          marginBottom={2}
      >
        Одобрено

      </Title>
       <Alert
          type="success"
          message="Завершите оформление, выбрав одно из следующих предложений"
          marginBottom={2}
       />
      <Block>
        <ResponsiveDesktop>
          <Header isFlex marginBottom={1} onMouseOver={() => setIsOver(true)} onMouseLeave={() => setIsOver(false)}>
            <Col md={3}/>
            <Col md={3}>
              <Text type={isOver ? 'text' : 'secondary'}>
                Дополнительные услуги
              </Text>
            </Col>
            <Col md={2}>
              <Text type={isOver ? 'text' : 'secondary'}>
                Срок кредита
              </Text>
              <Block onClick={handleSort('term', !isAsc)} htmlID={`${CREDIT_RESULT_HTML_ID}-sort-term-${isAsc ? 'desc' : 'asc'}`}>
                <Icon
                  icon={field === 'term' ? !isSafe(isAsc) ? 'sort' : 'sort-up' : 'sort'}
                  rotate={isAsc ? 180 : 0}
                  iconType="design"
                  color={isOver ? 'text' : 'secondary'}
                />
              </Block>
            </Col>
            <Col md={2}>
              <Text type={isOver ? 'text' : 'secondary'}>
                Первонач. взнос
              </Text>
              <Block onClick={handleSort('initialPayment', !isAsc)} htmlID={`${CREDIT_RESULT_HTML_ID}-sort-initialPayment-${isAsc ? 'desc' : 'asc'}`}>
                <Icon
                  icon={field === 'initialPayment' ? !isSafe(isAsc) ? 'sort' : 'sort-up' : 'sort'}
                  rotate={isAsc ? 180 : 0}
                  iconType="design"
                  color={isOver ? 'text' : 'secondary'}
                />
              </Block>
            </Col>
            <Col md={2} style={{ zIndex: 1 }}>
              <Tooltip
                tooltip={isMonth ? 'Нажмите для переключения к сумме выплат' :
                  'Нажмите для переключения к ежемесячным платежам'}
                placement="top"
              >
                <Block onClick={handleSetIsMonth}>
                  {isMonth ?
                    <MonthlyContainer type="primary" width={3}>
                      Ежемесячный платеж
                    </MonthlyContainer> :
                    <MonthlyContainer type="primary" width={3}>
                      Сумма выплат
                    </MonthlyContainer>
                  }
                </Block>
              </Tooltip>
              <Block onClick={handleSort('monthlyPayment', !isAsc)} htmlID={`${CREDIT_RESULT_HTML_ID}-sort-month-${isAsc ? 'desc' : 'asc'}`}>
                <Icon
                  icon={field === 'monthlyPayment' ? !isSafe(isAsc) ? 'sort' : 'sort-up' : 'sort'}
                  rotate={isAsc ? 180 : 0}
                  iconType="design"
                  color={isOver ? 'text' : 'secondary'}
                />
              </Block>
            </Col>
          </Header>
          {fp.map(application => (
            <Application
              application={application}
              key={application.id}
              isMonth={isMonth}
            />
          ))(positiveApplications)}
          <AccentMonthlyPayment count={positiveApplications.length}/>
        </ResponsiveDesktop>
        <ResponsiveTabletAndPhone>
          {fp.map(application => (
            <Application
              application={application}
              key={application.id}
            />
          ))(positiveApplications)}
        </ResponsiveTabletAndPhone>
      </Block>
    </Block>
  )
}

export default withTheme(inject(store => ({
  applicationModel: store.applicationModel,
  responsiveModel: store.responsiveModel
}))(observer(Applications)))
