import _ from 'lodash'
import fp from 'lodash/fp'
import ApplicationProvider from 'providers/api-provider/application-provider'
import { action, computed, observable, toJS } from 'mobx'
import React from 'react'
import history, { orderHistory } from 'utils/history'
import { notifyError, notifySuccess } from 'uikit'
import deepMerge from 'deepmerge'
import BaseModel from '../baseModel'
import pickupModel from '../pickupModel'
import questionaryModel from '../questionaryModel'

class ApplicationModel extends BaseModel {
  @observable applications = []
  @observable isInitApplications = false
  @observable current = null
  @observable banks = []
  @observable isLoading
  @observable applicationsSort = {
    field: null,
    isAsc: null
  }

  @computed get isExist() {
    return !_.isEmpty(this.banks)
  }

  @computed get isExistPositive() {
    return !_.isEmpty(_.filter(this.applications, a => a.isPositive))
  }

  @computed get positiveApplications() {
    return _.filter(this.applications, a => a.isPositive)
  }

  @computed get isExistNegative() {
    return !_.isEmpty(_.filter(this.banks, a => a.isNegative))
  }

  @computed get negativeApplications() {
    return _.filter(this.banks, a => a.isNegative)
  }

  @computed get isExistProgress() {
    return !_.isEmpty(_.filter(this.banks, a => a.isProgress))
  }

  @computed get progressApplications() {
    return _.filter(this.banks, a => a.isProgress)
  }

  @action setCurrent(data) {
    this.current = {
      ...this.current,
      ...data
    }
  }

  @action getApplications = async orderID => {
    try {
      this.applyData({ isLoading: false })
      const { banks, applications } = await ApplicationProvider.getApplications(orderID)

      if (this.isInitApplications) {
        _.each(banks, b => {
          if (b.isPositive &&
            (!_.get(_.find(this.banks, bb => bb.id === b.id), 'isPositive', false) || _.findIndex(this.banks, bb => bb.id === b.id) === -1) &&
            !_.isEmpty(applications)
          ) {
            notifySuccess(`Вы только что получили одобрение ${b.bankName}!`,
              <span
                onClick={() => history.push(orderHistory('/credit_result', pickupModel.order.id, false))}
                style={{ cursor: 'pointer' }}
              >
              Нажмите, чтобы посмотреть
            </span>)
            return false
          }
        })
      }

      this.applyData({
        applications: fp.map(application => {
          const resolveOffer = _.find(pickupModel.offers, offer => offer.id === application.calculationResultID && offer.code === application.code)
          return {
            ...application,
            alternate: resolveOffer && resolveOffer.alternate ? deepMerge({}, toJS(resolveOffer.alternate)) : null
          }
        })(applications),
        banks,
        isInitApplications: true
      })
    } finally {
      this.applyData({ isLoading: false })
    }
  }

  @action sortApplications = (field, isAsc) => {
    this.applyData({
      applications: toJS(this.applications).sort((a, b) => (a[field] > b[field] ? (isAsc ? 1 : -1) : (isAsc ? -1 : 1))),
      applicationsSort: {
        field,
        isAsc
      }
    })
  }

  @action sendApplication = async applicationID => {
    try {
      const { selectedAt, issueID } = await ApplicationProvider.sendCreditApplication(pickupModel.order.id, applicationID)
      const app = _.find(this.applications, a => a.id === applicationID)

      if (app) {
        this.setCurrent({
          ...app,
          selectedAt,
          issueID,
          email: questionaryModel.contactEmail
        })
        history.push(`/credit_result_send/${pickupModel.order.id}`)
      }
    } catch (e) {
      notifyError('Ошибка оформления кредита')
    }
  }
}

export { ApplicationModel }
export default new ApplicationModel()
