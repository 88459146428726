import _ from 'lodash'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Input, Button, Block, Icon, Col, Row, Text, Title, Paragraph, Alert,
  mountHook, namesNormalizer
} from 'uikit'
import { inject, observer } from 'mobx-react'
import styled, { withTheme } from 'styled-components'
import { MAIN_DATA_HTML_ID } from 'utils/htmlID'
import FioInput from 'components/FioInput'
import PersonalDataAccess from '../MainData/PersonalDataAccess'

const ConfirmCodeContainerPhone = styled(Block)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const SmsCode = styled(Input)`
  & input {
    padding-left: 0 !important;
    padding-right: 0 !important;
  } 
`

const MainDataCallCenter = ({ isNew, menuModel, pickupModel, questionaryModel, responsiveModel, theme, ...props }) => {
  const {
    firstName, lastName, patronymic, mobilePhone, confirmCode,

    isConfirmPhone, isSendConfirmCode, isGetConfirmCodeLoading, isErrorConfirmCode, isTrySendConfirmCode, confirmCodeTime,

    isCallMeLoading, mainDataValidationEnabled
  } = questionaryModel
  const { isTabletAndPhone, isPhone, isDesktop, isTablet } = responsiveModel

  mountHook(async () => {
    menuModel.changeStep(1, null, false)

    const orderID = _.get(props, 'match.params.orderID', null)
    if (orderID) {
      if (!questionaryModel.id) {
        await pickupModel.getOrder(orderID, true)
      }
    }
  })

  const isForceError = questionaryModel.isExistMainDataStep || mainDataValidationEnabled

  const handleChangeFio = item => {
    switch (item.gender) {
      case 'MALE':
        questionaryModel.setField('sex')('1')
        break
      case 'FEMALE':
        questionaryModel.setField('sex')('2')
        break
    }
  }

  return (
    <Block>
      {!isPhone &&
      <Title level={3} marginTop={3}>
        Основные данные
      </Title>
      }
      {responsiveModel.isPhone && (questionaryModel.mainDataErrorCount + questionaryModel.mainDataEmptyCount) &&
        isForceError ?
        <Alert
          message="Исправьте ошибки в полях формы"
          type="error"
          isClosable
          marginTop={1}
          marginBottom={2}
        /> : null
      }
      <Row isFlex justify="space-between" marginTop={1}>
        <Col width={!isTabletAndPhone ? '50%' : '100%'}>
          <FioInput
            isForm
            type="text"
            value={lastName}
            label="Фамилия"
            onChange={questionaryModel.setFio('lastName')}
            error={questionaryModel.validLastName}
            tabIndex={1}
            htmlID={`${MAIN_DATA_HTML_ID}-lastName`}
            onChangeItem={handleChangeFio}
            isShowForceValidate={isForceError}
            onBlur={() => {
              questionaryModel.enableIsChangeMainData()
              questionaryModel.trimField('lastName')()
            }}
            width={!isTabletAndPhone ? 16 : '100%'}
          />
          <FioInput
            isForm
            type="text"
            value={firstName}
            label="Имя"
            onChange={questionaryModel.setFio('firstName')}
            error={questionaryModel.validFirstName}
            marginTop={1.5}
            tabIndex={1}
            htmlID={`${MAIN_DATA_HTML_ID}-firstName`}
            onChangeItem={handleChangeFio}
            isShowForceValidate={isForceError}
            onBlur={() => {
              questionaryModel.enableIsChangeMainData()
              questionaryModel.trimField('firstName')()
            }}
            width={!isTabletAndPhone ? 16 : '100%'}
          />
          <FioInput
            isForm
            type="text"
            value={patronymic}
            label="Отчество"
            onChange={questionaryModel.setFio('patronymic')}
            error={questionaryModel.validPatronymic}
            marginTop={1.5}
            marginBottom={3}
            tabIndex={1}
            normalizer={namesNormalizer}
            htmlID={`${MAIN_DATA_HTML_ID}-patronymic`}
            onChangeItem={handleChangeFio}
            isShowForceValidate={isForceError}
            help="При наличии обязательно к заполнению"
            onBlur={() => {
              questionaryModel.enableIsChangeMainData()
              questionaryModel.trimField('patronymic')()
            }}
            width={!isTabletAndPhone ? 16 : '100%'}
          />
        </Col>
      </Row>
      <Block
          marginTop={1.5}
          horizontalPlacement={!isTabletAndPhone ? 'left' : 'none'}
      >
        <Input
          width={!isTabletAndPhone ? 16 : '100%'}
          label="Мобильный телефон"
          value={mobilePhone}
          error={questionaryModel.validMobilePhone}
          onChange={questionaryModel.setMobilePhone}
          prefix={<Text size={16} isRem={false} type="text">+7 </Text>}
          mask="(999) 999-9999"
          unmask={v => v.replace(/[\s()-]*/gi, '')}
          postfix={!questionaryModel.validMobilePhone && mobilePhone !== '' ?
            <Icon iconType="design" icon="check" color="success" marginTop={0.4}/> :
            null
          }
          tabIndex={isDesktop ? 2 : 3}
          marginRight={1}
          isShowForceValidate={isForceError}
          onBlur={questionaryModel.enableIsChangeMainData}
          htmlID={`${MAIN_DATA_HTML_ID}-mobilePhone`}
          type="number"
        />
        {(() => {
          if (isConfirmPhone) {
            return (
              <Text
                type="success"
                marginTop={3.5}
              >
                Номер подтвержден!
              </Text>
            )
          }

          if (confirmCode || isSendConfirmCode) {
            return (
              (() => {
                const ContainerConfirm = !isTabletAndPhone ? Fragment : ConfirmCodeContainerPhone
                return (
                  <ContainerConfirm>
                    <SmsCode
                      width={7}
                      label="Код из СМС"
                      isSmsCode
                      value={confirmCode}
                      onChange={v => questionaryModel.setConfirmCode(v)}
                      textAlign="center"
                      tabIndex={2}
                      marginRight={!isTabletAndPhone ? 1 : 0}
                      marginTop={isTabletAndPhone ? 1.5 : 0}
                      error={(isErrorConfirmCode || (isForceError && !confirmCode)) ? 'Неверный код' : null}
                      isShowForceValidate={isForceError}
                      onBlur={questionaryModel.enableIsChangeMainData}
                      htmlID={`${MAIN_DATA_HTML_ID}-confirmCode`}
                      type="number"
                    />
                    <Button
                      isDisable={confirmCode.length !== 5 || /-+/gi.test(confirmCode)}
                      onClick={() => questionaryModel.sendConfirmCode()}
                      marginRight={!isTabletAndPhone ? 1 : 0}
                      marginTop={!isTabletAndPhone ? 2.8 : isTablet ? 4.1 : 3.5}
                      tabIndex={2}
                      isLoading={isGetConfirmCodeLoading}
                      onBlur={questionaryModel.enableIsChangeMainData}
                      htmlID={`${MAIN_DATA_HTML_ID}-confirmCode-accept`}
                    >
                      Подтвердить
                    </Button>
                    <Block
                      isCenter={!isTabletAndPhone}
                      height={2.7}
                      marginTop={!isTabletAndPhone ? 2.85 : 1}
                    >
                      <Text
                        type={isTrySendConfirmCode ? 'darkBlue' : 'secondary'}
                        style={{ cursor: isTrySendConfirmCode ? 'pointer' : 'default', userSelect: 'none' }}
                        onClick={isTrySendConfirmCode ? questionaryModel.trySendConfirmPhone : null}
                        htmlID={`${MAIN_DATA_HTML_ID}-confirmCode-try`}
                      >
                        {(() => {
                          if (isTrySendConfirmCode) return 'Отправить код повторно'

                          const minutes = parseInt(confirmCodeTime / 60)
                          const seconds = confirmCodeTime % 60
                          return `Отправить код повторно можно через ${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
                        })()}
                      </Text>
                    </Block>
                  </ContainerConfirm>
                )
              })()
            )
          }

          return (
            <Button
              marginRight={1}
              marginTop={!isTabletAndPhone ? 2.8 : 1.5}
              tabIndex={2}
              isDisable={!!questionaryModel.validMobilePhone}
              onClick={() => questionaryModel.sendConfirmPhone()}
              isLoading={isGetConfirmCodeLoading}
              htmlID={`${MAIN_DATA_HTML_ID}-confirmCode-send`}
              style={{ width: !isTabletAndPhone ? 'auto' : '100%' }}
            >
              Отправить
            </Button>
          )
        })()}
      </Block>
      {(confirmCode || isSendConfirmCode) && !isConfirmPhone &&
      <Paragraph
        marginTop={!isTabletAndPhone ? 2 : 1.5}
        isNativeMargin={false}
      >
        Вводом СМС-кода подтверждения Вы выражаете согласие на обработку персональных данных и взаимодействие с бюро кредитных историй в соответствии
        <PersonalDataAccess/>
      </Paragraph>
      }
      <Row isFlex justify="space-between" align="middle" marginTop={2.5}>
        <Col>
          <Button
            type="primary"
            tabIndex={4}
            onClick={() => {
              if (questionaryModel.isCanCallMe) {
                questionaryModel.sendCallMe()
              } else {
                questionaryModel.enableMainDataValidation()
              }
            }}
            isLoading={isCallMeLoading}
            htmlID={`${MAIN_DATA_HTML_ID}-next`}
          >
            Отправить заявку
          </Button>
        </Col>
      </Row>
    </Block>
  )
}

MainDataCallCenter.propTypes = {
  isNew: PropTypes.bool
}

MainDataCallCenter.defaultProps = {
  isNew: true
}

export default withTheme(inject(store => ({
  menuModel: store.menuModel,
  questionaryModel: store.questionaryModel,
  pickupModel: store.pickupModel,
  responsiveModel: store.responsiveModel
}))(observer(MainDataCallCenter)))
