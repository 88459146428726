import brsLogo from 'resources/bank_logo/brs-credit.svg'
import expressLogo from 'resources/bank_logo/east-express-bank.png'
import alphaLogo from 'resources/bank_logo/logo-alfa-bank.png'
import creditLogo from 'resources/bank_logo/logo-credit-europe-bank.png'
import letoLogo from 'resources/bank_logo/logo-leto-bank.png'
import otpLogo from 'resources/bank_logo/logo-otp-bank.png'
import rencredLogo from 'resources/bank_logo/logo-rencred-bank.png'
import tinkoffLogo from 'resources/bank_logo/logo-tinkoff-bank.png'
import rsfbLogo from 'resources/bank_logo/rsfb-credit.png'
import smsLogo from 'resources/bank_logo/sms-finance.png'
import mtsLogo from 'resources/bank_logo/mts-logo.png'
import kvikuLogo from 'resources/bank_logo/logo-kviku-bank.png'

export const bankLogoMap = systemName => {
  switch (systemName) {
    case 'tcs': return tinkoffLogo
    case 'alpha': return alphaLogo
    case 'brs': return brsLogo
    case 'otp': return otpLogo
    case 'rsfb': return rsfbLogo
    case 'leto': return letoLogo
    case 'renessans': return rencredLogo
    case 'eastexpress': return expressLogo
    case 'ceb': return creditLogo
    case 'sms_finance': return smsLogo
    case 'mts_online': return mtsLogo
    case 'kviku': return kvikuLogo
    default: return ''
  }
}

export const bankNameMap = systemName => {
  switch (systemName) {
    case 'tcs': return 'Тинькофф Банк'
    case 'alpha': return 'Альфа Банк'
    case 'brs': return 'Русский Стадарт Банк'
    case 'otp': return 'ОТП Банк'
    case 'rsfb': return 'Русфинанс Банк'
    case 'leto': return 'Почта Банк'
    case 'renessans': return 'Ренессанс Кредит'
    case 'eastexpress': return 'Восточный Экспресс Банк'
    case 'ceb': return 'Credit Europe Bank'
    case 'sms_finance': return 'СМС Финанс Банк'
    case 'mts_online': return 'ПАО «МТС-Банк»'
    case 'kviku': return 'Квику'
    default: return ''
  }
}
