import _ from 'lodash'
import React, { Fragment } from 'react'
import {
  Title, Input, CheckBlock, Label, Slider, Row, Col, ResponsiveDesktop, ResponsiveTabletAndPhone, Block, SelectPicker,
  applyPolyfillCss,
  moneyRubNormalizer, moneyRubDenormalizer, num2str,
  safeObject
} from 'uikit'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { PICKUP_HTML_ID } from 'utils/htmlID'
import Services from './Services'

const InputContainer = styled(props => {
  const resolveProps = { ...props }
  _.unset(resolveProps, 'isBold')
  return <Input {...resolveProps}/>
})`
  ${applyPolyfillCss('width', 'calc(100% - 7.6rem)')}
  margin-right: .5rem !important;
  
  & input {
    font-weight: ${props => (props.isBold ? 500 : 400)};
    color: ${props => (props.isBold ? 'inherit' : props.theme.colors.secondary)};
  }
`

const InputTerm = styled(Input)`
  & input {
    font-weight: 500;
  }
`

const TouchSliderContainer = styled(Block)`
  ${applyPolyfillCss('width', 'calc(100% - 7.5rem)')}
  
  margin-top: 2.5rem;
`

const Prepare = ({ pickupModel, responsiveModel }) => {
  const {
    initialPayment, initialPaymentPercent, term
  } = pickupModel.pickupInfo
  const { payment } = pickupModel.order

  const maxInitialPayment = parseInt(payment * 0.9)

  const MainPrepare = (
    <Label label="Первоначальный взнос" marginBottom={0}>
      <Block horizontalPlacement="left">
        <InputContainer
          key="initialPayment-desktop"
          type="number"
          value={initialPayment}
          onChange={pickupModel.setInitialPayment}
          min={0}
          max={initialPaymentPercent ? 90 : maxInitialPayment}
          step={!initialPaymentPercent ? 100 : 1}
          normalizer={v => (v !== '' ? (Number(v) ? Number(v) > 0 ? moneyRubNormalizer(parseInt(v)) : 0 : parseInt(v)) : v)}
          denormalizer={moneyRubDenormalizer}
          isHint
          tabIndex={1}
          isForm
          isFullWidth={false}
          isForceChange={initialPayment === ''}
          isBold={Number(initialPayment) !== 0}
          onFocus={() => {
            if (Number(initialPayment) === 0) pickupModel.setField('initialPayment')('')
          }}
          onBlur={() => {
            if (initialPayment === '') pickupModel.setField('initialPayment')(0)
          }}
          {...safeObject({
            width: responsiveModel.isTabletAndPhone ? 7.5 : null
          })}
          htmlID={`${PICKUP_HTML_ID}-initialPayment`}
        />
        <Block width="auto">
          <CheckBlock
            options={[
              { value: 'rub', label: <Block width={1} isCenter>₽</Block> },
              { value: 'percent', label: <Block width={1} isCenter>%</Block> }
            ]}
            value={initialPaymentPercent ? 'percent' : 'rub'}
            onChange={pickupModel.setInitialPaymentPercent}
            tabIndex={1}
            width={7.5}
            htmlID={`${PICKUP_HTML_ID}-initialPayment-mode`}
          />
        </Block>
      </Block>
    </Label>
  )

  return (
    <Fragment>
      <Title
        level={3}
        marginTop={!responsiveModel.isPhone ? 3 : 1}
        marginBottom={!responsiveModel.isPhone ? 0 : 2}
      >
        Параметры расчёта кредита
      </Title>
      <Row
        marginTop={!responsiveModel.isPhone ? 3 : 0}
        gutter={0}
      >
        <ResponsiveDesktop>
          <Col md={4} marginRight={2}>
            {MainPrepare}
          </Col>
        </ResponsiveDesktop>
        <ResponsiveTabletAndPhone>
          <Col md={12}>
            {MainPrepare}
          </Col>
        </ResponsiveTabletAndPhone>
        <ResponsiveDesktop>
          <Col md={2} marginRight={1}>
            <InputTerm
              type="number"
              label="Срок кредита"
              postfix={num2str(term, ['месяц', 'месяца', 'месяцев'])}
              min={6}
              max={36}
              value={term}
              onChange={pickupModel.setField('term')}
              tabIndex={1}
              isForm
              normalizer={v => (Number(v) ? parseInt(v) > 0 ? parseInt(v) : 6 : v)}
              onBlur={() => {
                if (term === '') pickupModel.setField('term')(6)
              }}
              htmlID={`${PICKUP_HTML_ID}-term`}
            />
          </Col>
          <Col
            md={6}
            marginTop={1.6}
            marginRight={-3}
          >
            <Slider
              value={Number(term) < 6 ? 6 : Number(term)}
              onChange={pickupModel.setField('term')}
              min={6}
              max={36}
              tabIndex={1}
              style={{ paddingRight: '3rem' }}
              htmlID={`${PICKUP_HTML_ID}-term-slider`}
            />
          </Col>
        </ResponsiveDesktop>
        <ResponsiveTabletAndPhone>
          <Col md={12}>
            <Block horizontalPlacement="left">
              <Input
                type="number"
                label="Срок кредита"
                postfix={num2str(term, ['месяц', 'месяца', 'месяцев'])}
                min={6}
                max={36}
                value={term}
                onChange={pickupModel.setField('term')}
                tabIndex={1}
                normalizer={v => (Number(v) ? parseInt(v) > 0 ? parseInt(v) : 6 : v)}
                onBlur={() => {
                  if (term === '') pickupModel.setField('term')(6)
                }}
                htmlID={`${PICKUP_HTML_ID}-term`}
                marginTop={1}
                options={_.map(_.times(31), o => ({
                  value: `${o + 6}`,
                  label: `${(o + 6).toString()} мес.`
                }))}
                width={7.5}
                marginRight={0.5}
              />
              <TouchSliderContainer>
                <Slider
                  value={Number(term) < 6 ? 6 : Number(term)}
                  onChange={pickupModel.setField('term')}
                  min={6}
                  max={36}
                  tabIndex={1}
                  htmlID={`${PICKUP_HTML_ID}-term-slider`}
                />
              </TouchSliderContainer>
            </Block>
          </Col>
        </ResponsiveTabletAndPhone>
      </Row>
      <Services/>
    </Fragment>
  )
}

export default inject(store => ({
  responsiveModel: store.responsiveModel,
  pickupModel: store.pickupModel
}))(observer(Prepare))
