import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { Block, SelectPicker } from 'uikit'
import { OTHER_DATA_HTML_ID } from 'utils/htmlID'

const Education = ({ questionaryModel, dictModel, responsiveModel }) => {
  const {
    educationType,

    isExistOtherDataStep, educationValidationEnabled
  } = questionaryModel
  const { isTabletAndPhone } = responsiveModel

  return (
    <Fragment>
      <Block
        width={!isTabletAndPhone ? 22 : '100%'}
        marginTop={-1}
        marginBottom={17}
      >
        <SelectPicker
          key="familyStatus"
          label="Тип образования"
          value={educationType}
          options={dictModel.dict.education_types}
          onChange={questionaryModel.setField('educationType')}
          error={questionaryModel.validEducationType}
          tabIndex={1}
          isSearch={false}
          isShowForceValidate={isExistOtherDataStep || educationValidationEnabled}
          onBlur={questionaryModel.enableIsChangeOtherDataEducation}
          htmlID={`${OTHER_DATA_HTML_ID}-familyStatus`}
        />
      </Block>
    </Fragment>
  )
}

export default inject(store => ({
  questionaryModel: store.questionaryModel,
  dictModel: store.dictModel,
  responsiveModel: store.responsiveModel
}))(observer(Education))
