import _ from 'lodash'
import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { mountHook } from 'uikit'
import history, { orderHistory } from 'utils/history'
import PropTypes from 'prop-types'
import Prepare from './Prepare'
import Offers from './Offers'

const Pickup = ({ isNew, menuModel, pickupModel, questionaryModel, ...props }) => {
  mountHook(async () => {
    menuModel.changeStep(0)

    const orderID = _.get(props, 'match.params.orderID', null)

    if (orderID) {
      if (isNew) {
        await pickupModel.getOrder(orderID, true)
      } else if (!questionaryModel.id) {
        await questionaryModel.getQuestionary(orderID)
      }

      if (questionaryModel.isFill) {
        history.push(orderHistory('/credit_result'))
      }
      if (pickupModel.isCallCenterCall) {
        history.push(`/form/${orderID}/call_center`)
      }
    } else {
      history.push('/error_order')
    }
  })

  return (
    <Fragment>
      <Prepare/>
      <Offers/>
    </Fragment>
  )
}

Pickup.propTypes = {
  isNew: PropTypes.bool
}

Pickup.defaultProps = {
  isNew: true
}

export default inject(store => ({
  menuModel: store.menuModel,
  questionaryModel: store.questionaryModel,
  pickupModel: store.pickupModel
}))(observer(Pickup))
