import React from 'react'
import PropTypes from 'prop-types'
import { Block, Paragraph, Title } from 'uikit'

const Item = ({
  htmlID, title, description
}) => (
  <Block htmlID={htmlID} paddingRight={1.7}>
    <Title level={4} marginBottom={0} type="text">
      {title}
    </Title>
    <Paragraph
      level={4}
      type="secondary"
      isNativeMargin={false}
    >
      {description}
    </Paragraph>
  </Block>
)

Item.propTypes = {
  htmlID: PropTypes.string,
  title: PropTypes.any,
  description: PropTypes.any
}

export default Item
