import { getAge, getDateByAge, isSafe } from 'uikit'

export const validPassportDate = (passportDate, birthDate) => {
  if (!passportDate || !birthDate) return 'Паспорт недействителен'
  if (+passportDate > +new Date()) return 'Паспорт недействителен'
  if (+passportDate > +new Date()) return 'Паспорт недействителен'
  if (!isSafe(birthDate)) return 'Обязательное поле'
  if (+birthDate > +getDateByAge(18) ||
    +birthDate < +getDateByAge(100) ||
    +birthDate >= +new Date()) return 'Паспорт недействителен'


  const age = getAge({ date: birthDate })

  if (age >= 14 && age < 20) {
    const passportDateOne = new Date(birthDate)
    passportDateOne.setFullYear(passportDateOne.getFullYear() + 14)
    const passportDateTwo = new Date(birthDate)
    passportDateTwo.setFullYear(passportDateTwo.getFullYear() + 20)
    if (!(passportDate >= passportDateOne && passportDate < passportDateTwo)) return 'Паспорт недействителен'
  }

  if (age >= 20 && age < 45) {
    const passportDateOne = new Date(birthDate)
    passportDateOne.setFullYear(passportDateOne.getFullYear() + 20)
    const passportDateTwo = new Date(birthDate)
    passportDateTwo.setFullYear(passportDateTwo.getFullYear() + 45)
    if (!(passportDate >= passportDateOne && passportDate < passportDateTwo)) return 'Паспорт недействителен'
  }

  if (age >= 45) {
    const passportDateOne = new Date(birthDate)
    passportDateOne.setFullYear(passportDateOne.getFullYear() + 45)
    if (passportDate < passportDateOne) return 'Паспорт недействителен'
  }

  return null
}
