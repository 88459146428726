import { observable, action } from 'mobx'
import history from 'utils/history'
import { scrollToTop } from 'uikit'
import BaseModel from '../baseModel'
import pickupModel from '../pickupModel'
import questionaryModel from '../questionaryModel'

class MenuModel extends BaseModel {
  @observable step = 0;
  @observable isOpenMenuMobile = false;

  @action changeStep(step, path = null, isChangeSubStep = true) {
    this.isOpenMenuMobile = false
    if (isChangeSubStep) {
      if (step === this.step) return

      switch (this.step) {
        case 1:
          if (questionaryModel.isChangeMainData) {
            questionaryModel.addToExistMainDataStep()
          }
          break
        case 2:
          if (questionaryModel.isChangedAdditionalData) {
            questionaryModel.addToExistAdditionalDataSteps(questionaryModel.activeAdditionalPanel)
          }
          break
        case 3:
          if (questionaryModel.isChangedOtherData) {
            questionaryModel.addToExistOtherDataSteps(questionaryModel.activeOtherPanel)
          }
          break
      }
    }

    this.step = step

    scrollToTop()
    if (pickupModel.isCallCenterCall && path) {
      history.push(`/form/${pickupModel.order.id}/call_center`)
      return
    }
    if (path) {
      history.push(path)
    }
  }

  @action setOpenMenuMobile = v => {
    this.isOpenMenuMobile = v
  }
}

export { MenuModel }
export default new MenuModel()
