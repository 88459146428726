import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Title, Divider, Block, hexToRgba, Popover, safeObject } from 'uikit'
import { withTheme } from 'styled-components'
import { inject, observer } from 'mobx-react'
import ReactMarkdown from 'react-markdown'

const Service = ({
  icon, isDisable, theme, iconProps, isMarginRight, name, description,
  onClick, htmlID, responsiveModel
}) => {
  const size = _.get(iconProps, 'size', 1.15)

  const handleClick = () => {
    if (onClick) onClick(!isDisable)
  }

  return (
    <Popover
        content={!responsiveModel.isTouch ?
          <Block width={30} padding={0.5}>
            {name &&
            <Title level={2}>
              {name}
            </Title>
            }
            {description &&
            <>
              {name && <Divider isMarginBottom marginBottom={0.5}/>}
              <ReactMarkdown source={description}/>
            </>
            }
          </Block> : null
        }
        trigger="hover"
        placement="right"
    >
      <Block
        width={2}
        height={2}
        isCenter
        borderRadius="50%"
        marginRight={isMarginRight ? 0.57 : 0}
        onClick={onClick ? handleClick : null}
        htmlID={htmlID}
        cursor="pointer"
        {...safeObject({
          background: !isDisable ? hexToRgba(theme.colors.primary, 0.15) : null,
          borderWidth: isDisable ? 0.1 : null,
          borderColor: isDisable ? theme.colors.gray3 : null
        })}
      >
        <Icon
            iconType="design"
            icon={icon}
            size={size}
            color={!isDisable ? 'primary' : 'gray3'}
            {...iconProps}
        />
      </Block>
    </Popover>
  )
}

Service.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  isDisable: PropTypes.bool,
  iconProps: PropTypes.object,
  isMarginRight: PropTypes.bool,
  htmlID: PropTypes.any,
  onClick: PropTypes.func
}

Service.defaultProps = {
  isDisable: false,
  iconProps: null,
  isMarginRight: false
}

export default withTheme(inject('responsiveModel')(observer(Service)))
