import _ from 'lodash'
import { isSafe } from 'uikit'
import deepMerge from 'deepmerge'

export const fieldsMap = ({ entity, mapping = {}, map = {}, removeBackPath = [], isForApi = false }) => {
  if (!isForApi) {
    const resolve = {}

    _.each(mapping, item => {
      const frontPath = item.frontPath
      const backPath = item.backPath
      const forFront = item.forFront

      let value = null
      _.each(backPath, p => {
        value = _.get(entity, p, null)
        if (isSafe(value)) return false
      })

      const resolveValue = !forFront ? value : isSafe(value) ? forFront(value) : null
      if (isSafe(resolveValue)) {
        _.set(resolve, frontPath, resolveValue)
      }
    })

    return deepMerge(resolve, map || {})
  }

  const resolve = {}

  _.each(mapping, item => {
    const frontPath = item.frontPath
    const backPath = [...item.backPath]
    const forBack = item.forBack

    const value = _.get(entity, frontPath, null)
    const resolveValue = !forBack ? value : isSafe(value) ? forBack(value) : null

    if (isSafe(resolveValue)) {
      _.each(removeBackPath, bp => {
        const index = _.findIndex(backPath, b => b === bp)
        if (index !== -1) {
          backPath.splice(index, 1)
        }
      })

      const path = _.get(backPath, '0', null)
      if (path) {
        _.set(resolve, _.get(backPath, '0'), resolveValue)
      }
    }
  })
  return deepMerge(resolve, map || {})
}
