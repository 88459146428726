import _ from 'lodash'
import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import {
  Input, Block, Icon, Text,
  hexToRgba, moneyRubDenormalizer, moneyRubNormalizer, SelectPicker,
  isSafe
} from 'uikit'
import { OTHER_DATA_HTML_ID } from 'utils/htmlID'

const Close = styled(Block)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: none;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
`

const Item = styled(Block)`
  position: relative;
  width: 100%;
  padding: .5rem 2rem 2rem 2rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background .175s ease-in-out;
  margin-bottom: .5rem;
  
  &:hover {
    background: ${props => hexToRgba(props.theme.colors.darkSkyBlue, 0.15)};
  }
  
  &:hover ${Close} {
    display: flex;
  }
`

const ItemAdd = styled(Block)`
  width: 18rem;
  margin-left: -2rem;
  padding-left: 2rem;
  cursor: pointer;
`

const Incomes = ({ questionaryModel, dictModel, responsiveModel }) => {
  const {
    incomes,

    isExistOtherDataStep, incomesValidationEnabled
  } = questionaryModel
  const { isTabletAndPhone } = responsiveModel

  return (
    <Fragment>
      {_.map(incomes, (income, index) => (
        <Item
          key={index}
          marginLeft={!isTabletAndPhone ? -2 : 0}
        >
          <SelectPicker
            label="Тип дохода"
            value={income.incomeType}
            options={dictModel.dict.income_categories}
            onChange={questionaryModel.setIncomes('incomeType', index)}
            error={!isSafe(income.incomeType) ? 'Обязательное поле' : null}
            tabIndex={1}
            isShowForceValidate={isExistOtherDataStep || incomesValidationEnabled}
            onBlur={questionaryModel.enableIsChangeOtherDataFour}
            htmlID={`${OTHER_DATA_HTML_ID}-incomeType-${index}`}
          />
          <Input
            width={!isTabletAndPhone ? '50%' : '100%'}
            marginTop={1.5}
            label="Сумма дохода"
            normalizer={moneyRubNormalizer}
            denormalizer={moneyRubDenormalizer}
            type="number"
            postfix="₽"
            value={income.sumIncome}
            onChange={questionaryModel.setIncomes('sumIncome', index)}
            error={income.sumIncome === '' ? 'Обязательное поле' :
              income.sumIncome <= 0 ? 'Должно быть больше 0' : null
            }
            tabIndex={1}
            isShowForceValidate={isExistOtherDataStep || incomesValidationEnabled}
            onBlur={questionaryModel.enableIsChangeOtherDataFour}
            htmlID={`${OTHER_DATA_HTML_ID}-sumIncome-${index}`}
          />
          <Close onClick={questionaryModel.removeIncomes(index)} htmlID={`${OTHER_DATA_HTML_ID}-incomes-remove-${index}`}>
            <Icon iconType="design" icon="close" color="secondary"/>
          </Close>
        </Item>
      ))}
      <ItemAdd
        marginTop={incomes.length > 0 ? 2 : 0}
        marginBottom={incomes.length > 0 ? 3.1 : 20.32}
      >
        <Text type="darkBlue" onClick={questionaryModel.addIncome} htmlID={`${OTHER_DATA_HTML_ID}-incomes-add`}>
          + Добавить доход
        </Text>
      </ItemAdd>
    </Fragment>
  )
}

export default inject(store => ({
  questionaryModel: store.questionaryModel,
  dictModel: store.dictModel,
  responsiveModel: store.responsiveModel
}))(observer(Incomes))
