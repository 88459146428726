import _ from 'lodash'
import fp from 'lodash/fp'
import ActionCable from 'actioncable'
import uuid from 'uuid/v4'
import ConfigProvider from 'providers/config-provider'
import channelsManifest from './channels-manifest'

class ActionCableProvider {
  run(orderID) {
    this.host = `${ConfigProvider.config.ws.uri || '/ws'}?order_unique_id=${orderID}`
    this.subscriptions = []
    this.ws = ActionCable.createConsumer(this.host)
  }

  disconnect() {
    fp.forEach(this.subscriptions, sub => {
      this.ws.subscriptions.remove(sub.connection)
      this.ws.disconnect()
    })
  }

  addSubscription(options, meta = null) {
    const channel = _.get(options, 'channel', null) || null
    if (!channel) return
    if (this.getSubscriptionIndex(options) !== -1) return

    const subID = uuid()
    const connection = this.ws.subscriptions.create(options,
      {
        connected: data => {
          this.subscriptions.push({ id: subID, connection, options, meta })
          const handler = _.get(channelsManifest, `${channel}.onConnected`, null)
          if (handler) {
            handler({
              data,
              options,
              meta,
              close: this.removeSubscription(subID)
            })
          }
        },
        disconnected: data => {
          const subIndex = this.getSubscriptionIndex({ subID })
          if (subIndex) this.subscriptions.splice(subIndex, 1)

          const handler = _.get(channelsManifest, `${channel}.onDisconnected`, null)
          if (handler) {
            handler({
              data,
              options,
              meta
            })
          }
        },
        received: data => {
          const handler = _.get(channelsManifest, `${channel}.onReceived`, null)
          if (handler) {
            handler({ data, options, meta, close: this.removeSubscription(subID) })
          }
        }
      })
  }

  getSubscriptionIndex({ subID, options }) {
    return fp.findIndex(s => (subID ? s.id === subID : _.isEqual(options, s.options)))(this.subscriptions)
  }

  removeSubscription = subID => () => {
    const subIndex = this.getSubscriptionIndex({ subID })
    if (subIndex !== -1) {
      this.ws.subscriptions.remove(this.subscriptions[subIndex].connection)
      this.subscriptions.splice(subIndex, 1)
    }
  }

  removeSubscriptionByOptions = options => {
    const subIndex = fp.findIndex(s => _.isEqual(options, s.options))(this.subscriptions)
    if (subIndex !== -1) {
      this.ws.subscriptions.remove(this.subscriptions[subIndex].connection)
      this.subscriptions.splice(subIndex, 1)
    }
  }
}

export { ActionCableProvider }
export default new ActionCableProvider()
