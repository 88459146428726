import { action, observable } from 'mobx'
import React from 'react'
import BaseModel from '../baseModel'

class TemplatesModel extends BaseModel {
  @observable credit_result_step_1 = ''
  @observable credit_result_step_2 = ''

  setTemplates = action(({ creditResultStep1, creditResultStep2 }) => {
    this.creditResultStep1 = creditResultStep1
    this.creditResultStep2 = creditResultStep2
  })
}

export { TemplatesModel }
export default new TemplatesModel()
