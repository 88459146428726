import _ from 'lodash'
import fp from 'lodash/fp'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Block, Col, Row, Divider, Paragraph, Text, Image, Icon, Tooltip,
  ResponsiveDesktop, ResponsiveTabletAndPhone, moneyRubNormalizer, num2str
} from 'uikit'
import { inject, observer } from 'mobx-react'
import { PICKUP_HTML_ID } from 'utils/htmlID'
import Service from 'components/Service'

const Offer = ({ offer, isMonth, pickupModel }) => {
  const slots = fp.flow(
    fp.filter(s => _.findIndex(offer.disabledSlots, f => f === s.id) === -1),
    fp.map(s => ({
      ...s,
      isSelect: _.findIndex(offer.slots, f => f === s.id) !== -1 || _.findIndex(offer.forcedSlots, f => f === s.id) !== -1,
      isNotClick: _.findIndex(offer.forcedSlots, f => f === s.id) !== -1
    }))
  )(pickupModel.order.slots)

  const { updatingOfferID } = pickupModel
  const termAlternateLetter = num2str(_.get(offer, 'alternate.term', []), ['месяц', 'месяца', 'месяцев'])

  const serviceContainers = _.reduce(slots, (s, slot) => {
    const currrentService = (
      <Service
        key={slot.id}
        icon={slot.icon}
        iconProps={{ size: 1 }}
        isDisable={!slot.isSelect}
        onClick={!slot.isNotClick ? () => pickupModel.applySingleOffer(slot.id, !slot.isSelect, offer.id) : null}
        htmlID={`${PICKUP_HTML_ID}-service-${offer.id}`}
        name={slot.service}
        description={slot.description}
        isMarginRight
      />
    )

    if (!s.list[s.index]) {
      s.list.push([currrentService])
      s.index += 1
      return s
    }

    if (s.list[s.index].length < 3) {
      s.list[s.index].push(currrentService)
    } else {
      s.list.push([currrentService])
      s.index += 1
    }
    return s
  }, { list: [], index: -1 })
  const services = _.map(serviceContainers.list, (s, i) => (
    <Block
      horizontalPlacement="left"
      marginBottom={i !== serviceContainers.list.length - 1 ? 0.57 : 0}
      key={i}
    >
      {s}
    </Block>
  ))

  return (
    <Fragment>
      <ResponsiveDesktop>
        <Row
          isFlex
          align="middle"
          height={101}
          isRem={false}
        >
          <Col md={3}>
            <Image src={offer.bankLogo} width="8rem"/>
          </Col>
          <Col md={3}>
            {slots.length ? services : <Text>—</Text>}
          </Col>
          <Col md={2}>
            <Paragraph marginBottom={offer.alternate ? 0.1 : 0}>
              {offer.term} мес.
            </Paragraph>
            {offer.alternate &&
            <Fragment>
              <Paragraph marginBottom={0.1} isStrong>или</Paragraph>
              <Paragraph isNativeMargin={false} isStrong>
                рассрочка {offer.alternate.term} мес.
                <Tooltip
                  isInline
                  tooltip={`Продукт с льготным периодом ${offer.alternate.term} ${termAlternateLetter}. При погашении за ${offer.alternate.term} ${termAlternateLetter} переплата за товар отсутствует.`}
                >
                  <Icon marginLeft={0.3} icon="question" iconType="design" color="secondary"/>
                </Tooltip>
              </Paragraph>
            </Fragment>
            }
          </Col>
          <Col md={2}>
            {moneyRubNormalizer(!pickupModel.pickupInfo.initialPaymentPercent ? offer.initialPayment : Math.floor(offer.initialPayment * 100 / pickupModel.order.payment))} {!pickupModel.pickupInfo.initialPaymentPercent ? '₽' : '%'}
          </Col>
          <Col md={2}>
            <Paragraph level={4} size={1.2} marginBottom={offer.alternate ? 1 : 0}>
              {moneyRubNormalizer(Math.floor(isMonth ? offer.monthlyPayment : offer.paymentsSum))} <Text type="secondary">₽</Text>
            </Paragraph>
            {offer.alternate &&
            <Paragraph level={4} isNativeMargin={false} size={1.2} isStrong>
              {moneyRubNormalizer(Math.floor(isMonth ? offer.alternate.monthlyPayment : offer.alternate.paymentsSum))} <Text type="secondary">₽</Text>
            </Paragraph>
            }
          </Col>
          <Col md={12}>
            <Divider/>
          </Col>
        </Row>
      </ResponsiveDesktop>
      <ResponsiveTabletAndPhone>
        <Block
          background="gray1"
          marginBottom={0.7}
          padding={1}
          isLoading={updatingOfferID === offer.id}
          borderRadius={0.57}
        >
          <Image src={offer.bankLogo} width="10rem" height={5} marginBottom={1}/>

          {slots.length > 0 && <Row isFlex justify="space-between">
            <Col width="30%">
              <Paragraph isNativeMargin={false} isStrong>
                Дополнительные услуги
              </Paragraph>
            </Col>
            <Col width="40%">
              <Block>
                {services}
              </Block>
            </Col>
          </Row>}

          <Divider/>

          <Row isFlex justify="space-between">
            <Col width="30%">
              <Paragraph isNativeMargin={false} isStrong>
                Срок кредита
              </Paragraph>
            </Col>
            <Col width="40%">
              <Paragraph marginBottom={offer.alternate ? 0.1 : 0}>
                {offer.term} мес.
              </Paragraph>
              {offer.alternate &&
              <Fragment>
                <Paragraph marginBottom={0.1} isStrong>или</Paragraph>
                <Paragraph marginBottom={0} isStrong>
                  рассрочка {offer.alternate.term} мес.
                  <Tooltip
                    isInline
                    tooltip={`Продукт с льготным периодом ${offer.alternate.term} ${termAlternateLetter}. При погашении за ${offer.alternate.term} ${termAlternateLetter} переплата за товар отсутствует.`}
                  >
                    <Icon marginLeft={0.3} icon="question" iconType="design" color="secondary"/>
                  </Tooltip>
                </Paragraph>
              </Fragment>
              }
            </Col>
          </Row>

          <Divider/>

          <Row isFlex justify="space-between">
            <Col width="30%">
              <Paragraph isNativeMargin={false} isStrong>
                Первонач. взнос
              </Paragraph>
            </Col>
            <Col width="40%">
              <Paragraph isNativeMargin={false}>
                {moneyRubNormalizer(offer.initialPayment)} ₽
              </Paragraph>
            </Col>
          </Row>

          <Divider/>

          <Row isFlex justify="space-between">
            <Col width="30%">
              <Paragraph isNativeMargin={false} isStrong>
                Ежемесячный платеж
              </Paragraph>
            </Col>
            <Col width="40%">
              <Paragraph level={4} size={1.2} marginBottom={offer.alternate ? 1 : 0}>
                {moneyRubNormalizer(Math.floor(offer.monthlyPayment))} <Text type="secondary">₽</Text>
              </Paragraph>
              {offer.alternate &&
              <Paragraph level={4} marginBottom={0} size={1.2} isStrong>
                {moneyRubNormalizer(Math.floor(offer.alternate.monthlyPayment))} <Text type="secondary">₽</Text>
              </Paragraph>
              }
            </Col>
          </Row>
          </Block>
      </ResponsiveTabletAndPhone>
    </Fragment>
  )
}

Offer.propTypes = {
  offer: PropTypes.object,
  isMonth: PropTypes.bool
}

export default inject('pickupModel')(observer(Offer))
