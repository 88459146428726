import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { Block, DatePicker, Switch, SelectPicker } from 'uikit'
import AddressInput from 'components/AddressInput'
import { ADDITIONAL_DATA_HTML_ID } from 'utils/htmlID'
import styled from 'styled-components'

const SwitchLabel = styled(Block)`
  line-height: 2rem;
`

const Living = ({ questionaryModel, dictModel, responsiveModel }) => {
  const {
    addressHome, statusHome, homeDate, homeRepeat,
    addressRegistration, registrationDate,

    isExistAdditionalDataStep,
    livingValidationEnabled
  } = questionaryModel
  const { isTabletAndPhone } = responsiveModel

  return (
    <Fragment>
      <Block width="100%">
        <Switch
          isChecked={homeRepeat}
          onChange={questionaryModel.setAddressHomeRepeat}
          tabIndex={1}
          isDisable={!registrationDate || !addressRegistration}
          isShowForceValidate={isExistAdditionalDataStep || livingValidationEnabled}
          htmlID={`${ADDITIONAL_DATA_HTML_ID}-homeRepeat`}
        >
          <SwitchLabel>Адрес проживания совпадает с адресом регистрации</SwitchLabel>
        </Switch>
      </Block>
      <Block width="100%" marginTop={1.5}>
        <AddressInput
          key="addressHome"
          label="Адрес проживания"
          value={addressHome}
          onChange={questionaryModel.setField('addressHome')}
          error={questionaryModel.validAddressHome}
          tabIndex={1}
          isDisable={homeRepeat}
          isShowForceValidate={isExistAdditionalDataStep || livingValidationEnabled}
          onBlur={questionaryModel.enableIsChangeAdditionalDataTwo}
          htmlID={`${ADDITIONAL_DATA_HTML_ID}-addressHome`}
        />
      </Block>
      <Block width="100%" marginTop={1.5}>
        <SelectPicker
          key="statusHome"
          label="Статус проживания"
          value={statusHome}
          onChange={questionaryModel.setField('statusHome')}
          error={questionaryModel.validStatusHome}
          options={dictModel.dict.residence_statuses}
          tabIndex={1}
          isShowForceValidate={isExistAdditionalDataStep || livingValidationEnabled}
          onBlur={questionaryModel.enableIsChangeAdditionalDataTwo}
          htmlID={`${ADDITIONAL_DATA_HTML_ID}-statusHome`}
        />
      </Block>
      <Block width={!isTabletAndPhone ? 16 : '100%'} marginTop={1.5}>
        <DatePicker
          key="homeDate"
          label="Проживаю с"
          tabIndex={1}
          value={homeDate}
          onChange={questionaryModel.setField('homeDate')}
          error={questionaryModel.validHomeDate}
          isDisable={homeRepeat}
          isShowForceValidate={isExistAdditionalDataStep || livingValidationEnabled}
          onBlur={questionaryModel.enableIsChangeAdditionalDataTwo}
          htmlID={`${ADDITIONAL_DATA_HTML_ID}-homeDate`}
        />
      </Block>
    </Fragment>
  )
}

export default inject(store => ({
  questionaryModel: store.questionaryModel,
  dictModel: store.dictModel,
  responsiveModel: store.responsiveModel
}))(observer(Living))
