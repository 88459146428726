import { createBrowserHistory } from 'history'
import ConfigProvider from 'providers/config-provider'
import { isSafe } from 'uikit'
import models from 'models'

const basename = ConfigProvider.config.publicPath
const history = createBrowserHistory({ basename })

export const orderHistory = (path, orderID = null, isNew = null) => {
  const isNewQuestionary = isSafe(isNew) ? isNew : !models.questionaryModel.id
  return `/${isNewQuestionary ? 'orders' : 'form'}/${orderID || models.pickupModel.order.id}${path}`
}

export default history
