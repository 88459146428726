import React from 'react'
import { Router as DomRouter, Route, Redirect, Switch } from 'react-router-dom'
import history from 'utils/history'
import OrderEndPoint from 'pages/OrderEndPoint'
import FormEndPoint from 'pages/FormEndPoint'
import Pickup from 'pages/Pickup'
import MainData from 'pages/MainData'
import MainDataCallCenter from 'pages/MainDataCallCenter'
import AdditionalData from 'pages/AdditionalData'
import OtherData from 'pages/OtherData'
import CreditResult from 'pages/CreditResult'
import CreditSuccessSend from 'pages/CreditSuccessSend'
import ErrorOrder from 'pages/ErrorOrder'
import CallCenterErrorOrder from 'pages/CallCenterErrorOrder'
import Home from 'pages/Home'

const Router = () => (
    <DomRouter history={history}>
      <Switch>
        <Route path="/orders/:orderID" component={OrderEndPoint} exact/>
        <Route path="/form/:orderID" component={FormEndPoint} exact/>
        <Route path="/orders/:orderID/pickup" component={Pickup} exact/>
        <Route path="/form/:orderID/pickup" render={props => <Pickup isNew={false} {...props}/>} exact/>
        <Route path="/orders/:orderID/main_data" component={MainData} exact/>
        <Route path="/orders/:orderID/main_data_call_center" component={MainDataCallCenter} exact/>
        <Route path="/form/:orderID/main_data" render={props => <MainData isNew={false} {...props}/>} exact/>
        <Route path="/orders/:orderID/additional_data" component={AdditionalData} exact/>
        <Route path="/form/:orderID/additional_data" render={props => <AdditionalData isNew={false} {...props}/>} exact/>
        <Route path="/orders/:orderID/other_data" component={OtherData} exact/>
        <Route path="/form/:orderID/other_data" render={props => <OtherData isNew={false} {...props}/>} exact/>
        <Route path="/form/:orderID/credit_result" render={props => <CreditResult {...props} isNew={false}/>} exact/>
        <Route path="/orders/:orderID/credit_result" render={props => <CreditResult {...props} isNew/>} exact/>
        <Route path="/credit_result_send/:orderID" component={CreditSuccessSend} exact/>
        <Route path="/error_order" component={ErrorOrder} exact/>
        <Route path="/form/:orderID/call_center" component={CallCenterErrorOrder} exact/>
        <Route path="/" component={Home} exact/>
        <Route path="/orders" component={Home} exact/>
        <Route path="/form" component={Home} exact/>
        <Redirect to="/error_order"/>
      </Switch>
    </DomRouter>
)

export default Router
