import _ from 'lodash'
import fp from 'lodash/fp'
import { notNull } from 'providers/helpers/null'
import { bankLogoMap, bankNameMap } from 'providers/helpers/bankLogo'
import BaseHttpProvider from '../base-http-provider'

class ApiProvider extends BaseHttpProvider {
  defaultApiPrefix = '/api/v1'

  async getOrder(orderID) {
    const { data } = await this.execute({ uri: `/orders/${orderID}` })

    return {
      order: {
        pointName: _.get(data, 'point.name', ''),
        pointID: _.get(data, 'point.global_id', ''),
        addressPointName: _.get(data, 'point.address', ''),
        phone: _.get(data, 'point.phone', ''),
        logo: _.get(data, 'partner.logo_url', ''),
        ownerLogo: _.get(data, 'owner.logo_url', ''),
        calculationID: _.get(data, 'calculation_id', null),
        numberOrder: _.get(data, 'number', ''),
        orderUrl: _.get(data, 'order_url', ''),
        payment: Number(_.get(data, 'cost', 0)),
        orders: fp.map(or => ({
          name: `${_.get(or, 'manufacturer', '')} ${_.get(or, 'model', '')}`,
          manufacturer: _.get(or, 'manufacturer', ''),
          category: _.get(or, 'goods_category.name', ''),
          cost: Number(_.get(or, 'price', 0)),
          count: Number(_.get(or, 'count', 0))
        }))(_.get(data, 'goods', [])),
        slots: fp.map(service => ({
          id: _.get(service, 'id', ''),
          name: _.get(service, 'name', ''),
          description: _.get(service, 'description', ''),
          isSelect: _.get(service, 'checked', false),
          icon: _.get(service, 'icon', '')
        }))(_.get(data, 'slots', []).slice(0, 6)),
        state: _.get(data, 'state', ''),
        token: _.get(data, 'token', ''),
        target: _.get(data, 'target', '')
      },
      client: {
        firstName: _.get(data, 'client_firstname', ''),
        lastName: _.get(data, 'client_lastname', ''),
        patronymic: _.get(data, 'client_patronymic', ''),
        mobilePhone: _.get(data, 'mobile_phone', '') || _.get(data, 'client_mobile_phone', '') || '',
        confirmKey: _.get(data, 'confirm_key', ''),
        confirmCodeID: _.get(data, 'confirmation_code_id', '')
      },
      term: _.get(data, 'term', ''),
      initialPayment: _.get(data, 'initial_payment', ''),
      selectOffer: notNull(_.get(data, 'selected_offer', null), o => ({
        issueID: _.get(o, 'issue_id', ''),
        selectedAt: new Date(_.get(o, 'selected_at', '') || new Date()),
        bankName: bankNameMap(_.get(o, 'bank_name', '')),
        email: _.get(data, 'email', '')
      })),
      templates: {
        creditResultStep1: _.get(data, 'templates.credit_result_step_1', {}),
        creditResultStep2: _.get(data, 'templates.credit_result_step_2', {})
      }
    }
  }

  async applySingleSlot({
    slots, orderID, calculationID, offerID
  }) {
    const { data } = await this.execute({
      uri: `/orders/${orderID}/calculations/${calculationID}/results/${offerID}`,
      method: 'PUT',
      data: {
        partner_slot_ids: slots
      }
    })
    return {
      id: _.get(data, 'id', ''),
      bankLogo: bankLogoMap(_.get(data, 'credit_product.bank.name', '')),
      initialPayment: Number(_.get(data, 'initial_payment', 0)),
      initialPaymentPercent: Number(_.get(data, 'initial_payment_percent', 0)),
      term: Number(_.get(data, 'term', 0)),
      monthlyPayment: Number(_.get(data, 'monthly_payment', 0)),
      paymentsSum: Number(_.get(data, 'payments_sum', 0)),
      slots: _.get(data, 'enabled_slot_ids', []).slice(0, 6),
      disabledSlots: _.get(data, 'disabled_slot_ids', []).slice(0, 6),
      forcedSlots: _.get(data, 'forced_slot_ids', []).slice(0, 6),
      state: _.get(data, 'state')
    }
  }

  async applyOffersParams({
    orderID, initialPayment, term, slots
  }) {
    const { data } = await this.execute({
      uri: `/orders/${orderID}/calculations`,
      method: 'POST',
      data: {
        term: Number(term),
        initial_payment: Number(initialPayment),
        partner_slot_ids: fp.flow(
          fp.filter(s => s.isSelect),
          fp.map(s => s.id)
        )(slots)
      }
    })
    return { id: _.get(data, 'id', null) }
  }

  async getMyOffers(orderID, calculationID) {
    const { data } = await this.execute({
      uri: `/orders/${orderID}/calculations/${calculationID}`
    })
    return {
      offers: _.sortBy(fp.map(offer => ({
        id: _.get(offer, 'id', ''),
        bankName: _.get(offer, 'credit_product.bank.name', ''),
        bankLogo: bankLogoMap(_.get(offer, 'credit_product.bank.name', '')),
        code: _.get(offer, 'credit_product.code', null),
        initialPayment: Number(_.get(offer, 'initial_payment', 0)),
        initialPaymentPercent: Number(_.get(offer, 'initial_payment_percent', 0)),
        term: Number(_.get(offer, 'term', 0)),
        monthlyPayment: Number(_.get(offer, 'monthly_payment', 0)),
        paymentsSum: Number(_.get(offer, 'payments_sum', 0)),
        slots: _.get(offer, 'enabled_slot_ids', []).slice(0, 6),
        disabledSlots: _.get(offer, 'disabled_slot_ids', []).slice(0, 6),
        forcedSlots: _.get(offer, 'forced_slot_ids', []).slice(0, 6),
        state: _.get(offer, 'state'),
        alternate: notNull(offer.alternative, alternate => ({
          monthlyPayment: _.get(alternate, 'monthly_payment', 0),
          paymentsSum: Number(_.get(alternate, 'payments_sum', 0)),
          term: Number(_.get(alternate, 'term', 0))
        }))
      }))(_.get(data, 'result', [])), 'monthlyPayment'),
      isFinished: _.get(data, 'finished', false),
      initialPayment: _.get(data, 'initial_payment', 0),
      term: _.get(data, 'term', 6)
    }
  }

  async selectOffer(orderID, calculationID, resultsID) {
    return await this.execute({
      uri: `/orders/${orderID}/calculations/${calculationID}/results/select`,
      method: 'PUT',
      data: {
        calculation_result_ids: resultsID
      }
    })
  }
}

export { ApiProvider }
export default new ApiProvider()
