import _ from 'lodash'
import React, { Fragment } from 'react'
import styled, { css, keyframes, withTheme } from 'styled-components'
import { inject, observer } from 'mobx-react'
import { Block, Icon, Badge, Text, Loader, num2str, safeObject } from 'uikit'
import Item from './Item'
import Progress from './Progress'

const Root = styled(Block)`
  min-height: 105px;
  z-index: 998;
  left: 0;
  right: 0;
  display: flex;
`

const Content = styled(Block)`
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const animationFunc = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const animation = css`
  animation: ${animationFunc} 0.3s ease-in-out;
`

const MenuMobile = styled(Block)`
  ${animation};
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 0.5rem;
`

const MenuMobileCount = styled(Badge)`
  position: absolute;
  right: -5px;
  top: -8px;
  padding: 0 .3rem;
  border-radius: 19px;
`

const MenuTitle = ({ menuModel, pickupModel, questionaryModel, applicationModel, responsiveModel, theme }) => {
  const { step } = menuModel
  const { isExistHeader } = responsiveModel

  const creditLetter = num2str(pickupModel.offers.length, ['предложение', 'предложения', 'предложений'])

  const emptyField = (count, key = 'empty') => (
    <Text key={key} marginRight={0.2} type="danger">
      {count} не заполнено
    </Text>
  )

  const handleClickMenuMobile = () => {
    menuModel.setOpenMenuMobile(true)
  }

  const countPositiveMenu = _.filter(applicationModel.applications, a => a.isPositive).length

  const MainContent = (
    <Content>
      <Block horizontalPlacement="left" verticalPlacement="center">
        <Progress step={menuModel.step}/>
        {{
          0: (
            <Item
              title="Расчёт кредита"
              description={(() => {
                if (pickupModel.pickupInfo.isLoading) return <Text type="secondary"><Loader isColor={false}/> Расчёт...</Text>
                if (!_.isEmpty(pickupModel.offers)) return `Подобрано ${pickupModel.offers.length} ${creditLetter}`

                return 'Расчёт не выполнен'
              })()
              }
              htmlID="menu-pickup"
            />
          ),
          1: (
            <Item
              title="Основные данные"
              description={(() => {
                if (questionaryModel.isNextMainDataLoading) {
                  return <Text type="secondary"><Loader isColor={false}/> Сохранение...</Text>
                }

                if (questionaryModel.isChangeMainData) {
                  const errors = []
                  if (questionaryModel.mainDataEmptyCount) errors.push(emptyField(questionaryModel.mainDataEmptyCount))

                  if (!_.isEmpty(errors)) {
                    return errors
                  }

                  return !questionaryModel.mainDataErrorCount ? (
                    <Text type="secondary">
                      Указаны верно <Icon iconType="design" icon="check-circle"color="successLight"/>
                    </Text>
                  ) : null
                }

                if (step !== 1 && questionaryModel.isChangeMainData && (questionaryModel.mainDataEmptyCount + questionaryModel.mainDataErrorCount) === 0) {
                  return (
                    <Text type="secondary">
                      Полностью заполнено <Icon iconType="design" icon="check-circle" color="successLight"/>
                    </Text>
                  )
                }

                return 'Не указаны'
              })()}
              htmlID="menu-main-data"
            />
          ),
          2: (
            <Item
              title="Дополнительные данные"
              description={(() => {
                if (questionaryModel.isNextAdditionalDataLoading) {
                  return <Text type="secondary"><Loader isColor={false}/> Сохранение...</Text>
                }

                if (questionaryModel.isChangedAdditionalData && step === 2) {
                  const errors = []
                  if (questionaryModel.additionalDataEmptyCount) errors.push(emptyField(questionaryModel.additionalDataEmptyCount))

                  if (!_.isEmpty(errors)) {
                    return errors
                  }

                  return !questionaryModel.additionalDataErrorCount ? (
                    <Text type="secondary">
                      Указаны верно <Icon iconType="design" icon="check-circle" color="successLight"/>
                    </Text>
                  ) : null
                }

                if (step !== 2 &&
                  (questionaryModel.isChangeAdditionalDataOne || questionaryModel.isChangeAdditionalDataTwo) &&
                  questionaryModel.additionalDataProgress > 0
                ) {
                  return questionaryModel.additionalDataProgress < 100 ? (
                    <Text type="secondary">
                      Заполнено {questionaryModel.additionalDataProgress}%
                    </Text>
                  ) : (
                    <Text type="secondary">
                      Полностью заполнено <Icon iconType="design" icon="check-circle" color="successLight"/>
                    </Text>
                  )
                }

                return 'Не указаны'
              })()}
              htmlID="menu-additional-data"
            />
          ),
          3: (
            <Item
              title="Прочие сведения"
              description={(() => {
                if (questionaryModel.isNextOtherDataLoading) {
                  return <Text type="secondary"><Loader isColor={false}/> Сохранение...</Text>
                }

                if (questionaryModel.isChangedOtherData && step === 3) {
                  const errors = []
                  if (questionaryModel.otherDataEmptyCount) errors.push(emptyField(questionaryModel.otherDataEmptyCount))

                  if (!_.isEmpty(errors)) {
                    return errors
                  }

                  return !questionaryModel.otherDataErrorCount ? (
                    <Text type="secondary">
                      Указаны верно <Icon iconType="design" icon="check-circle" color="successLight"/>
                    </Text>
                  ) : null
                }

                if (step !== 3 && (questionaryModel.isChangeOtherDataTwo ||
                    questionaryModel.isChangeOtherDataThree || questionaryModel.isChangeOtherDataFour ||
                    questionaryModel.isChangeOtherDataFive || questionaryModel.isChangeOtherDataSix
                ) &&
                  questionaryModel.otherDataProgress > 0
                ) {
                  return questionaryModel.otherDataProgress < 100 ? (
                    <Text type="secondary">
                      Заполнено {questionaryModel.otherDataProgress}%
                    </Text>
                  ) : (
                    <Text type="secondary">
                      Полностью заполнено <Icon iconType="design" icon="check-circle" color="successLight"/>
                    </Text>
                  )
                }

                return 'Не указаны'
              })()}
              htmlID="menu-other-data"
            />
          ),
          4: (
            <Item
              title="Результаты рассмотрения"
              description={(() => {
                if (_.isEmpty(applicationModel.banks)) return <Text type="secondary">Отсутствуют</Text>

                const countPositive = _.filter(applicationModel.applications, a => a.isPositive)
                if (countPositive > 0) {
                  return (
                    <Fragment>
                      <Text type="secondary">Вы получили </Text>
                      <Block isInline>
                        <Badge label={countPositive} background="successLight"/>
                      </Block>
                      <Text type="secondary"> {num2str(countPositive, ['одобрение', 'одобрения', 'одобрений'])}!</Text>
                    </Fragment>
                  )
                }

                return null
              })()}
              subItems={_.map(applicationModel.banks, bank => ({
                title: bank.bankName,
                description: (
                  <Text
                    type={(() => {
                      if (bank.isPositive) return 'successBase'
                      if (bank.isNegative) return 'danger'

                      return 'secondary'
                    })()}
                  >
                    {(() => {
                      if (bank.isPositive) return 'Одобрено!'
                      if (bank.isNegative) return 'Отказ'

                      return 'Ожидание'
                    })()}
                  </Text>
                )
              }))}
            />
          )
        }[menuModel.step]}
      </Block>
      {!responsiveModel.isExistHeader &&
      <MenuMobile
        isCenter
        onClick={handleClickMenuMobile}
      >
        <Icon icon="menu" size={1.5} color="darkBlue"/>
        {countPositiveMenu > 0 && menuModel.step !== 4 &&
        <MenuMobileCount
          label={countPositiveMenu}
          background="successLight"
          style={{ fontSize: '10px' }}
        />
        }
      </MenuMobile>
      }
    </Content>
  )

  return (
    <Fragment>
    {!isExistHeader &&
    <Root
      style={{
        marginLeft: '-2rem',
        marginRight: '-2rem',
        position: 'relative',
        visibility: 'hidden'
      }}
    >
      {MainContent}
    </Root>
    }
    <Root
      background="gray1"
      style={safeObject({
        position: !isExistHeader ? 'fixed' : 'relative',
        top: 0,
        marginLeft: !isExistHeader ? 0 : '-2rem',
        marginRight: !isExistHeader ? 0 : '-2rem'
      })}
    >
      {MainContent}
    </Root>
    </Fragment>
  )
}

export default withTheme(inject(store => ({
  menuModel: store.menuModel,
  pickupModel: store.pickupModel,
  questionaryModel: store.questionaryModel,
  applicationModel: store.applicationModel,
  responsiveModel: store.responsiveModel
}))(observer(MenuTitle)))
