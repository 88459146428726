import _ from 'lodash'
import fp from 'lodash/fp'
import React, { Fragment, useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled, { withTheme } from 'styled-components'
import {
  Text, Title, Row, Col, Icon, Loader, Block, Button, Alert, Tooltip, ResponsiveDesktop, ResponsiveTabletAndPhone,
  isSafe
} from 'uikit'
import { PICKUP_HTML_ID } from 'utils/htmlID'
import Offer from './Offer'
import Placeholder from './Placeholder'
import AccentMonthlyPayment from './AccentMonthlyPayment'
import MobileTitleOffer from './MobileTitleOffer'

const Root = styled(Block)`
  position: relative;
`

const Header = styled(Row)`
  cursor: pointer;
  user-select: none;
`

const MonthlyContainer = styled(Text)`
  display: block;
  text-decoration:underline;
  text-decoration-style: dotted;
  cursor: pointer !important;
  z-index: 1;
`

const Offers = ({ pickupModel, responsiveModel, theme }) => {
  const { isLoading, isChanged, isInitChanged } = pickupModel.pickupInfo
  const { offers, updatingOfferID, offersSort, isNext } = pickupModel
  const { field, isAsc } = offersSort

  const [isOver, setIsOver] = useState(false)
  const [isOverAll, setIsOverAll] = useState(false)
  const [isOverMonth, setIsOverMonth] = useState(false)
  const [isMonth, setIsMonth] = useState(true)
  const [isInitMonth, setIsInitMonth] = useState(false)

  const handleSetIsMonth = () => {
    setIsInitMonth(true)
    setIsMonth(!isMonth)
  }

  const handleSort = (f, a) => () => {
    pickupModel.sortOffers(f, a)
  }

  const isMaxDesktopContent = responsiveModel.width >= theme.breakPoints.tablet
  const isVisibleTooltip = isOverMonth || (!isInitMonth && (isOver || isOverAll))

  return (
    <Fragment>
      {!_.isEmpty(offers) && !isLoading && isChanged ?
        <Fragment>
          <ResponsiveDesktop>
            <Block onMouseOver={() => setIsOverAll(true)} onMouseLeave={() => setIsOverAll(false)}>
              <Root isLoading={!!updatingOfferID}>
                <Title level={3} marginTop={2} marginBottom={2}>
                  Результат подбора кредитных продуктов
                </Title>
                <Header isFlex marginBottom={1} onMouseOver={() => setIsOver(true)} onMouseLeave={() => setIsOver(false)}>
                  <Col md={3}/>
                  <Col md={3}>
                    <Text type={isOver ? 'text' : 'secondary'}>
                      Дополнительные услуги
                    </Text>
                  </Col>
                  <Col md={2}>
                    <Text type={isOver ? 'text' : 'secondary'}>
                      Срок кредита
                    </Text>
                    <Block onClick={handleSort('term', !isAsc)} htmlID={`${PICKUP_HTML_ID}-sort-term-${isAsc ? 'desc' : 'asc'}`}>
                      <Icon
                        icon={field === 'term' ? !isSafe(isAsc) ? 'sort' : 'sort-up' : 'sort'}
                        rotate={isAsc ? 180 : 0}
                        iconType="design"
                        color={isOver ? 'text' : 'secondary'}
                      />
                    </Block>
                  </Col>
                  <Col md={2}>
                    <Text type={isOver ? 'text' : 'secondary'}>
                      Первонач. взнос
                    </Text>
                    <Block onClick={handleSort('initialPayment', !isAsc)} htmlID={`${PICKUP_HTML_ID}-sort-initialPayment-${isAsc ? 'desc' : 'asc'}`}>
                      <Icon
                        icon={field === 'initialPayment' ? !isSafe(isAsc) ? 'sort' : 'sort-up' : 'sort'}
                        rotate={isAsc ? 180 : 0}
                        iconType="design"
                        color={isOver ? 'text' : 'secondary'}
                      />
                    </Block>
                  </Col>
                  <Col
                    md={2}
                    style={{ zIndex: 1 }}
                    isRem={false}
                  >
                    <Tooltip
                      tooltip={isMonth ? 'Нажмите для переключения к сумме выплат' :
                        'Нажмите для переключения к ежемесячным платежам'}
                      placement="top"
                      isVisible={isVisibleTooltip}
                    >
                      <Block onMouseOver={() => setIsOverMonth(true)} onMouseLeave={() => setIsOverMonth(false)}>
                        <Text
                          marginBottom={0}
                        >
                          {isMonth ?
                            <MonthlyContainer width={5} type="primary">
                              <Text
                                onClick={handleSetIsMonth}
                                htmlID={`${PICKUP_HTML_ID}-offers-${isMonth ? 'month' : 'payment'}`}
                                type="primary"
                              >
                                Ежемесячный платеж
                                </Text>
                              <Icon
                                icon={field === 'monthlyPayment' ? !isSafe(isAsc) ? 'sort' : 'sort-up' : 'sort'}
                                rotate={isAsc ? 180 : 0}
                                iconType="design"
                                color={isOver ? 'text' : 'secondary'}
                                onClick={handleSort('monthlyPayment', !isAsc)} htmlID={`${PICKUP_HTML_ID}-sort-${isMonth ? 'month' : 'payment'}-${isAsc ? 'desc' : 'asc'}`}
                                marginLeft={0.5}
                              />
                            </MonthlyContainer> :
                            <MonthlyContainer type="primary" width={5}>
                              <Text
                                onClick={handleSetIsMonth}
                                htmlID={`${PICKUP_HTML_ID}-offers-${isMonth ? 'month' : 'payment'}`}
                                type="primary"
                              >
                                Сумма выплат
                              </Text>
                              <Icon
                                icon={field === 'paymentsSum' ? !isSafe(isAsc) ? 'sort' : 'sort-up' : 'sort'}
                                rotate={isAsc ? 180 : 0}
                                iconType="design"
                                color={isOver ? 'text' : 'secondary'}
                                onClick={handleSort('paymentsSum', !isAsc)} htmlID={`${PICKUP_HTML_ID}-sort-${isMonth ? 'month' : 'payment'}-${isAsc ? 'desc' : 'asc'}`}
                                marginLeft={0.5}
                              />
                            </MonthlyContainer>
                          }
                        </Text>
                      </Block>
                    </Tooltip>
                  </Col>
                </Header>
                {fp.map(offer => (
                  <Offer
                    offer={offer}
                    key={offer.id}
                    isMonth={isMonth}
                    isVisibleTooltip={isVisibleTooltip}
                  />
                ))(offers)}
                <AccentMonthlyPayment count={offers.length}/>
              </Root>
            </Block>
          </ResponsiveDesktop>
          <ResponsiveTabletAndPhone>
            <MobileTitleOffer/>
            <Block>
              {fp.map(offer => (
                <Offer
                  offer={offer}
                  key={offer.id}
                  isMonth={isMonth}
                  isVisibleTooltip={isVisibleTooltip}
                />
              ))(offers)}
            </Block>
          </ResponsiveTabletAndPhone>
        </Fragment> :
        <Fragment>
          {(isLoading || !_.isEmpty(offers)) &&
          <Title level={3} marginTop={2} marginBottom={3}>
            {!isLoading ? 'Результат подбора кредитных продуктов' :
              <Text>
                <Loader isColor={false}/>
                <Text type={!isLoading ? 'text' : 'secondary'}> Расчёт...</Text>
              </Text>
            }
          </Title>
          }
          {isLoading &&
            <Fragment>
              <Placeholder isMaxDesktopContent={isMaxDesktopContent} isAnimate={isLoading}/>
              <Placeholder isMaxDesktopContent={isMaxDesktopContent} isAnimate={isLoading}/>
              <Placeholder isMaxDesktopContent={isMaxDesktopContent} isAnimate={isLoading}/>
            </Fragment>
          }
          {pickupModel.isPreloading ?
          <Alert
            marginTop={2}
            message={!isInitChanged ? 'Попробуйте изменить параметры' :
              pickupModel.isEmptyOffers ?
                'К сожалению, ни один кредитный продукт не подходит под заданные параметры. Измените параметры и попробуйте еще раз' :
                null
            }
            type="warning"
            isShowIcon
          /> :
          <Fragment>
            <Placeholder isMaxDesktopContent={isMaxDesktopContent} isAnimate={isLoading}/>
            <Placeholder isMaxDesktopContent={isMaxDesktopContent} isAnimate={isLoading}/>
            <Placeholder isMaxDesktopContent={isMaxDesktopContent} isAnimate={isLoading}/>
          </Fragment>
          }
        </Fragment>
      }
      <Row
        isFlex
        justify="space-between"
        align="middle"
        marginTop={2.5}
      >
        <Col>
          <Button
            tabIndex={3}
            onClick={() => pickupModel.back()}
            htmlID={`${PICKUP_HTML_ID}-back`}
          >
            Назад
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            tabIndex={2}
            onClick={() => pickupModel.next()}
            htmlID={`${PICKUP_HTML_ID}-next`}
            isDisable={!isNext}
            isLoading={pickupModel.pickupInfo.isLoading}
          >
            Продолжить
          </Button>
        </Col>
      </Row>
    </Fragment>
  )
}

export default withTheme(inject(store => ({
  pickupModel: store.pickupModel,
  responsiveModel: store.responsiveModel
}))(observer(Offers)))
