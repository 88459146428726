import _ from 'lodash'
import fp from 'lodash/fp'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { InputSelect, withMargin, mountHook } from 'uikit'
import DadataProvider from 'providers/dadata-provider'

const handleSearch = _.debounce(async (v, onChangeOptions, onChangeIsLoading) => {
  if (v) {
    try {
      onChangeIsLoading(true)
      const fio = await DadataProvider.getFio(v)
      onChangeOptions(fio)
    } finally {
      onChangeIsLoading(false)
    }
  }
}, 450)

const FioInput = ({
  className, style, htmlID,

  tabIndex, size, isDisable, placeholder,
  onFocus, onBlur, onChangeItem,

  value, onChange, onMount, normalizer,

  error = null, warn = null, label = null, help = null, isShowForceValidate = false
}) => {
  const [fio, setFio] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const handleChange = v => {
    if (onChange) onChange(v)
    if (onChangeItem) {
      const item = _.find(fio, f => f.value === v)
      if (item) onChangeItem(item)
    }
  }

  mountHook(() => {
    if (onMount) {
      onMount({
        searchFio: v => handleSearch(v, setFio, setIsLoading)
      })
    }
  })

  return (
    <InputSelect
      className={className}
      style={style}
      isSelect={false}
      options={fp.map(a => ({
        value: a.value,
        label: a.value
      }))(fio)}
      onChange={handleChange}
      onChangeText={v => {
        if (onChange) onChange(v)
        handleSearch(v, setFio, setIsLoading)
      }}
      isFilterOption={false}
      tabIndex={tabIndex}
      size={size}
      isDisable={isDisable}
      placeholder={placeholder}
      onFocus={onFocus}
      onBlur={onBlur}
      isLoading={isLoading}
      htmlID={htmlID}
      value={value}
      label={label}
      error={error}
      warn={warn}
      help={help}
      isShowForceValidate={isShowForceValidate}
      normalizer={normalizer}
      dropdownStyle={{
        minWidth: '800px'
      }}
    />
  )
}

FioInput.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  htmlID: PropTypes.string,

  value: PropTypes.any,
  lastName: PropTypes.string,
  firstName: PropTypes.string,
  patronymic: PropTypes.string,
  gender: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOf(['xs', 'sm', 'lg']),
  tabIndex: PropTypes.any,
  isDisable: PropTypes.bool,
  placeholder: PropTypes.string,

  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
}

FioInput.defaultProps = {
  value: '',
  lastName: '',
  firstName: '',
  patronymic: '',
  gender: '',
  isDisable: false,
  size: 'lg',
  tabIndex: null
}

export default withMargin({ displayName: 'FioInput' })(FioInput)
