import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Block, Skeleton, Divider, ResponsiveDesktop, ResponsiveTabletAndPhone } from 'uikit'

const Placeholder = ({ isMaxDesktopContent, color, isAnimate }) => (
  <Fragment>
    <ResponsiveDesktop>
      <Row isFlex align="middle" height={101} isRem={false}>
        <Col md={3}>
          <Block verticalPlacement="center">
            <Skeleton width="100%" height={3} isAnimate={isAnimate} color={color}/>
          </Block>
        </Col>
        <Col md={3}>
          <Block isCenter>
            <Block verticalPlacement="center">
              {isMaxDesktopContent ?
                <Fragment>
                  <Col>
                    <Skeleton width={2} isCircle marginRight={0.57} marginBottom={0.57} color={color} isAnimate={isAnimate}/>
                    <Skeleton width={2} isCircle marginRight={0.57} marginBottom={0.57} color={color} isAnimate={isAnimate}/>
                  </Col>
                  <Col>
                    <Skeleton width={2} isCircle marginRight={0.57} marginBottom={0.57} color={color} isAnimate={isAnimate}/>
                    <Skeleton width={2} isCircle marginRight={0.57} marginBottom={0.57} color={color} isAnimate={isAnimate}/>
                  </Col>
                  <Col>
                    <Skeleton width={2} isCircle marginBottom={0.57} color={color} isAnimate={isAnimate}/>
                    <Skeleton width={2} isCircle marginBottom={0.57} color={color} isAnimate={isAnimate}/>
                  </Col>
                </Fragment> :
                <Fragment>
                  <Col md={6}>
                    <Skeleton width={2} isCircle marginRight={0.57} marginBottom={0.57} color={color} isAnimate={isAnimate}/>
                    <Skeleton width={2} isCircle marginRight={0.57} marginBottom={0.57} color={color} isAnimate={isAnimate}/>
                    <Skeleton width={2} isCircle marginRight={0.57} marginBottom={0.57} color={color} isAnimate={isAnimate}/>
                  </Col>
                  <Col
                    md={6}
                    marginLeft={3.5}
                    marginRight={-3.5}
                  >
                    <Skeleton width={2} isCircle marginRight={0.57} marginBottom={0.57} color={color} isAnimate={isAnimate}/>
                    <Skeleton width={2} isCircle marginRight={0.57} marginBottom={0.57} color={color} isAnimate={isAnimate}/>
                    <Skeleton width={2} isCircle marginRight={0.57} marginBottom={0.57} color={color} isAnimate={isAnimate}/>
                  </Col>
                </Fragment>
              }
            </Block>
          </Block>
        </Col>
        <Col md={2}>
          <Block isCenter>
            <Skeleton width="60%" isAnimate={isAnimate} color={color}/>
          </Block>
        </Col>
        <Col md={2}>
          <Block isCenter>
            <Skeleton width="60%" isAnimate={isAnimate} color={color}/>
          </Block>
        </Col>
        <Col md={2} style={{ zIndex: 1 }}>
          <Block isCenter>
            <Skeleton width="100%" height={3} isAnimate={isAnimate} color={color}/>
          </Block>
        </Col>
        <Col md={12}>
          <Divider/>
        </Col>
      </Row>
    </ResponsiveDesktop>
    <ResponsiveTabletAndPhone>
      <Skeleton width="100%" height={18} isAnimate={isAnimate} color={color} marginBottom={0.7}/>
    </ResponsiveTabletAndPhone>
  </Fragment>
)

Placeholder.propTypes = {
  isMaxDesktopContent: PropTypes.bool,
  color: PropTypes.string,
  isAnimate: PropTypes.bool
}

Placeholder.defaultProps = {
  isAnimate: false
}

export default Placeholder
