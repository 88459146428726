import React from 'react'
import { Block, Title, Text, mountHook, Icon } from 'uikit'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

const Root = styled(Block)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const ErrorOrder = ({ menuModel, globalPreloaderModel }) => {
  mountHook(async () => {
    menuModel.changeStep(-1)
    globalPreloaderModel.setVisible(false)
  })

  return (
    <Root
      isCenter
    >
      <Title align="center">
        <Icon icon="meh" color="danger"/>
        <Text type="danger"> Товары не найдены...</Text>
      </Title>
    </Root>
  )
}

export default inject(store => ({
  menuModel: store.menuModel,
  globalPreloaderModel: store.globalPreloaderModel
}))(observer(ErrorOrder))
