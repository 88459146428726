import _ from 'lodash'
import { fieldsMap } from 'providers/helpers/mapping'
import { safeObject, isSafe } from 'uikit'
import { mainDataMap, questionaryMap } from 'providers/helpers/questionary'
import { addressMap } from 'providers/helpers/address'
import { notNull } from 'providers/helpers/null'
import BaseHttpProvider from '../base-http-provider'

class ApiProvider extends BaseHttpProvider {
  defaultApiPrefix = '/api/v1'

  async sendConfirmPhone(phone, orderID) {
    const { data } = await this.execute({
      uri: `/orders/${orderID}/confirmation_codes`,
      method: 'POST',
      data: {
        confirmation_code: {
          phone: `8${phone}`
        }
      }
    })

    return { confirmCodeID: _.get(data, 'id', '') }
  }

  async sendConfirmCode(code, phone, orderID, confirmCodeID) {
    const { data } = await this.execute({
      uri: `/orders/${orderID}/confirmation_codes/${confirmCodeID}/confirm`,
      method: 'PUT',
      data: {
        phone,
        code
      }
    })
    return _.get(data, 'confirm_key', '')
  }

  async createQuestionary({
    lastName, firstName, patronymic,
    sex, birthDay, mobilePhone, passport, passportDay, income,
    confirmCodeID, orderID, contactEmail
  }) {
    const { data } = await this.execute({
      uri: `/orders/${orderID}/form`,
      method: 'POST',
      data: {
        form: safeObject(fieldsMap({
          entity: {
            lastName,
            firstName,
            patronymic,
            sex,
            birthDay,
            mobilePhone,
            passportDay,
            income,
            passport,
            contactEmail
          },
          mapping: mainDataMap,
          isForApi: true,
          map: {
            confirmation_code_id: confirmCodeID,
            sub_step: 'main_data'
          },
          removeBackPath: [
            'passport.issued_on',
            'passport.series_number',
            'birth_place_address.city'
          ]
        }))
      }
    })

    return { id: _.get(data, 'id', '') }
  }

  async updateQuestionary({
    orderID,
    questionary,
    subStep
  }) {
    await this.execute({
      uri: `/orders/${orderID}/form`,
      method: 'PUT',
      data: {
        form: safeObject(fieldsMap({
          entity: questionary,
          mapping: questionaryMap,
          isForApi: true,
          map: safeObject({
            sub_step: subStep,
            registration_address_attributes: questionary.addressRegistration ?
              fieldsMap({ entity: questionary.addressRegistration, mapping: addressMap, isForApi: true }) : null,
            residence_address_attributes: questionary.addressHome ?
              fieldsMap({ entity: questionary.addressHome, mapping: addressMap, isForApi: true }) : null,
            employment_attributes: questionary.organizationAddress ? {
              organization_address_attributes: questionary.organizationAddress ?
                fieldsMap({ entity: questionary.organizationAddress, mapping: addressMap, isForApi: true }) : null
            } : null,
            married_on: questionary.familyStatus !== 'civil_marriage' ? questionary.registrationDateFamily :
              questionary.registrationDateFamily || null
          }),
          removeBackPath: [
            'passport.issuer_code',
            'passport.issued_by',
            'passport.issued_on',
            'passport.series_number',
            'registration_address.starts_on',
            'residence_address.starts_on',
            'employment.common_experience_months',
            'employment.employment_type',
            'employment.organization_name',
            'employment.ownership_type',
            'employment.employment_activity_id',
            'employment.employment_activity_line_code',
            'employment.position',
            'employment.phone',
            'employment.starts_on',
            'employment.experience_years',
            'contact_person.phone',
            'contact_person.relation_type',
            'employment.common_experience_months'
          ]
        }))
      }
    })
  }

  async getQuestionary(orderID) {
    const { data } = await this.execute({
      uri: `/orders/${orderID}/form`
    })
    const form = _.get(data, 'form', {})

    const addressRegistration = notNull(_.get(form, 'registration_address'), a => fieldsMap({ mapping: addressMap, entity: a }))
    const addressHome = notNull(_.get(form, 'residence_address'), a => fieldsMap({ mapping: addressMap, entity: a }))
    const organizationAddress = notNull(_.get(form, 'employment.organization_address'), a => fieldsMap({ mapping: addressMap, entity: a }))
    const totalExperience = _.get(form, 'employment.common_experience_months', null)
    return safeObject(fieldsMap({
      mapping: questionaryMap,
      entity: form,
      map: safeObject({
        addressRegistration,
        addressHome,
        homeRepeat: addressRegistration && addressHome && addressRegistration.id === addressHome.id,
        organizationAddress,
        totalExperienceYear: isSafe(totalExperience) ? Math.floor(totalExperience / 12) : null,
        totalExperienceMonth: isSafe(totalExperience) ? totalExperience % 12 : null
      })
    }))
  }

  async sendCallMe({ orderID, params }) {
    if (!params) {
      const { data } = await this.execute({
        uri: `/orders/${orderID}/form/call_me`,
        method: 'PUT'
      })

      return data
    }
    const form = {
      lastname: params.lastName,
      firstname: params.firstName,
      patronymic: params.patronymic,
      mobile_phone: `8${params.mobilePhone}`,
      confirmation_code_id: params.confirmCodeID
    }

    const { data } = await this.execute({
      uri: `/orders/${orderID}/form/call_me`,
      method: 'PUT',
      data: {
        form
      }
    })

    return data
  }
}

export { ApiProvider }
export default new ApiProvider()
