import _ from 'lodash'
import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  Block, mountHook, Paragraph, Text, Title, Col, Row, Button, Image, Icon, moment,
  safeObject
} from 'uikit'
import styled, { withTheme } from 'styled-components'
import { Redirect } from 'react-router-dom'
import creditSuccessSendManImg from 'resources/images/creditSuccessSendMan.png'
import history from 'utils/history'

const Root = styled(props => {
  const resolveProps = { ...props }
  _.unset(resolveProps, 'isTouch')
  return <Block {...resolveProps}/>
})`
  width: ${props => (!props.isTouch ? `${props.theme.breakPoints.tablet}px` : '100%')};
  margin: ${props => (!props.isTouch ? 'auto' : 0)};
  padding: ${props => (!props.isTouch ? '3rem' : '0')};
`

const InfoContainer = styled(props => {
  const resolveProps = { ...props }
  _.unset(resolveProps, 'isTouch')
  return <Block {...resolveProps}/>
})`
  background: ${props => props.theme.colors.gray1};
  width: ${props => (!props.isTouch ? '30.5rem' : '100%')};
  margin-bottom: 2rem;
  border-radius: 0.57rem;
  padding: ${props => (!props.isTouch ? '2rem 1.2rem' : '1.2rem')};
`

const Footer = styled(Block)`
  position: ${props => (!props.isTouch ? 'fixed' : 'static')};
  bottom: 2rem;
  margin-top: ${props => (props.isTouch ? '2rem' : 0)};
`

const CreditSuccessSend = ({ pickupModel, menuModel, applicationModel, responsiveModel, templatesModel, ...props }) => {
  mountHook(async () => {
    const orderID = _.get(props, 'match.params.orderID', null)

    menuModel.changeStep(-1)

    if (orderID && !pickupModel.order.id) {
      await pickupModel.getOrder(orderID)
    } else if (!orderID) {
      history.push('/error_order')
    }
  })

  const { current } = applicationModel
  if (!current) return <Redirect to="/"/>

  return (
    <Root isTouch={responsiveModel.isTabletAndPhone}>
      <Block horizontalPlacement="left">
        <Block width={responsiveModel.isDesktop ? '54%' : '100%'} marginRight={responsiveModel.isDesktop ? 3 : 0}>
          <Block>
            <Block
              marginBottom={3}
              {...safeObject({
                verticalPlacement: responsiveModel.isDesktop ? 'center' : null
              })}
            >
              <Block
                isCenter={responsiveModel.isTabletAndPhone}
                marginRight={responsiveModel.isDesktop ? 2 : 0}
                marginBottom={responsiveModel.isTabletAndPhone ? 1 : 0}
              >
                <Icon iconType="design" icon="circle-done" size={4.5}/>
              </Block>
              <Title
                level={responsiveModel.isDesktop ? 3 : 4}
                marginBottom={0}
                align={responsiveModel.isDesktop ? 'left' : 'center'}
              >
                Кредит успешно оформлен!
              </Title>
            </Block>

            <InfoContainer isTouch={responsiveModel.isTabletAndPhone}>
              <Col>
                <Row isFlex justify="space-between">
                  <Paragraph isStrong marginBottom={0.5}>
                    Номер заявки
                  </Paragraph>
                  <Paragraph isNativeMargin={false}>
                    {current.issueID}
                  </Paragraph>
                </Row>
                <Row isFlex justify="space-between">
                  <Paragraph isStrong marginBottom={0.5}>
                    Дата оформления
                  </Paragraph>
                  <Paragraph isNativeMargin={false}>
                    {current.selectedAt ? moment(current.selectedAt, 'DD.MM.YYYY').format('DD.MM.YYYY') : 'Отсутствует'}
                  </Paragraph>
                </Row>
                <Row isFlex justify="space-between">
                  <Paragraph isStrong isNativeMargin={false}>
                    Банк
                  </Paragraph>
                  <Paragraph isNativeMargin={false}>
                    {current.bankName}
                  </Paragraph>
                </Row>
              </Col>
            </InfoContainer>

            <Paragraph marginBottom={responsiveModel.isDesktop ? 5 : 3}>
              {current.email ? `Информация об оформленном кредите отправлена на ${current.email}` : null}
            </Paragraph>
            <Paragraph size={1.15} marginBottom={2} isStrong>
              Всего пара шагов до цели:
            </Paragraph>

            <Block horizontalPlacement="left" marginBottom={2}>
              <Title isStrong marginRight={2}>1.</Title>
              { templatesModel.creditResultStep1 && <div dangerouslySetInnerHTML={{ __html: templatesModel.creditResultStep1 }}></div> }
            </Block>

            <Block horizontalPlacement="left" marginBottom={5}>
              <Title isStrong marginRight={2}>2.</Title>
              { templatesModel.creditResultStep2 && <div dangerouslySetInnerHTML={{ __html: templatesModel.creditResultStep2 }}></div> }
            </Block>

            <Button
              onClick={() => {
                window.location = pickupModel.order.orderUrl
              }}
              type="primary"
              style={{ width: responsiveModel.isDesktop ? 'auto' : '100%' }}
            >
              Вернуться на сайт магазина
            </Button>
          </Block>
        </Block>

        {responsiveModel.isDesktop && <Image src={creditSuccessSendManImg} height={37.85} width={27.17} isBlock/>}
      </Block>
    </Root>
  )
}

export default withTheme(inject(store => ({
  menuModel: store.menuModel,
  pickupModel: store.pickupModel,
  applicationModel: store.applicationModel,
  responsiveModel: store.responsiveModel,
  templatesModel: store.templatesModel
}))(observer(CreditSuccessSend)))
