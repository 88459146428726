import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import {
  Input,
  Block,
  DatePicker,
  Text,
  num2str,
  phoneNumberNormalizer,
  Col,
  Row,
  SelectPicker,
  InputSelect
} from 'uikit'
import AddressInput from 'components/AddressInput'
import { OTHER_DATA_HTML_ID } from 'utils/htmlID'

const Employment = ({ questionaryModel, dictModel, responsiveModel }) => {
  const {
    totalExperienceYear, totalExperienceMonth, employmentType,
    organizationName, organizationAddress, havingsType, activityType, activityVector,
    position, workPhone, workEmail, workBeginDate, contactPhone,

    isExistOtherDataStep, employmentValidationEnabled
  } = questionaryModel
  const { isTabletAndPhone } = responsiveModel
  const totalExperienceLetterYear = num2str(totalExperienceYear, ['год', 'года', 'лет'])
  const totalExperienceLetterMonth = num2str(totalExperienceMonth, ['месяц', 'месяца', 'месяцев'])

  return (
    <Fragment>
      <Block width={!isTabletAndPhone ? 25 : '100%'} marginTop={-1}>
        <Row isFlex justify="space-between">
          <Col
            xs={6}
            sm={6}
            md={6}
            lg={6}
          >
            <Input
              key="totalExperienceYear"
              label="Общий стаж работы"
              value={totalExperienceYear}
              onChange={questionaryModel.setField('totalExperienceYear')}
              error={questionaryModel.validTotalExperienceYears}
              errorWidth={350}
              type="number"
              max={80}
              postfix={totalExperienceLetterYear}
              tabIndex={1}
              isShowForceValidate={!!questionaryModel.validTotalExperienceYears || employmentValidationEnabled}
              onBlur={questionaryModel.enableIsChangeOtherDataThree}
              htmlID={`${OTHER_DATA_HTML_ID}-totalExperienceYear`}
            />
          </Col>
          <Col
            xs={6}
            sm={6}
            md={6}
            lg={6}
          >
            <Input
              key="totalExperienceMonth"
              label="&nbsp;"
              value={totalExperienceMonth}
              onChange={questionaryModel.setField('totalExperienceMonth')}
              error={questionaryModel.totalExperienceMonths}
              type="number"
              max={12}
              postfix={totalExperienceLetterMonth}
              tabIndex={1}
              isShowForceValidate={!!questionaryModel.totalExperienceMonths || employmentValidationEnabled}
              onBlur={questionaryModel.enableIsChangeOtherDataThree}
              htmlID={`${OTHER_DATA_HTML_ID}-totalExperienceMonth`}
            />
          </Col>
        </Row>
      </Block>
      <Block
        marginTop={1.5}
      >
        <SelectPicker
          key="employmentType"
          label="Тип занятости"
          value={employmentType}
          options={dictModel.dict.employment_types}
          onChange={questionaryModel.setField('employmentType')}
          error={questionaryModel.validEmploymentType}
          tabIndex={1}
          isShowForceValidate={isExistOtherDataStep || employmentValidationEnabled}
          onBlur={questionaryModel.enableIsChangeOtherDataThree}
          htmlID={`${OTHER_DATA_HTML_ID}-employmentType`}
        />
      </Block>
      {questionaryModel.isWorkingEmploymentOtherData ?
      <Fragment>
        <Block
          marginTop={1.5}
        >
          <Input
            key="organizationName"
            label="Наименование организации"
            value={organizationName}
            onChange={questionaryModel.setTrimField('organizationName')}
            error={questionaryModel.validOrganizationName}
            tabIndex={1}
            isShowForceValidate={isExistOtherDataStep || employmentValidationEnabled}
            onBlur={questionaryModel.enableIsChangeOtherDataThree}
            htmlID={`${OTHER_DATA_HTML_ID}-organizationName`}
          />
        </Block>
        <Block
          marginTop={1.5}
        >
          <AddressInput
            key="organizationAddress"
            label="Адрес организации"
            value={organizationAddress}
            onChange={questionaryModel.setField('organizationAddress')}
            error={questionaryModel.validOrganizationAddress}
            tabIndex={1}
            isShowForceValidate={isExistOtherDataStep || employmentValidationEnabled}
            onBlur={questionaryModel.enableIsChangeOtherDataThree}
            htmlID={`${OTHER_DATA_HTML_ID}-organizationAddress`}
          />
        </Block>
        <Block
          marginTop={1.5}
        >
          <SelectPicker
            key="havingsType"
            label="Вид собственности"
            value={havingsType}
            options={dictModel.dict.ownership_types}
            onChange={questionaryModel.setField('havingsType')}
            error={questionaryModel.validHavingsType}
            tabIndex={1}
            isShowForceValidate={isExistOtherDataStep || employmentValidationEnabled}
            onBlur={questionaryModel.enableIsChangeOtherDataThree}
            htmlID={`${OTHER_DATA_HTML_ID}-havingsType`}
          />
        </Block>
        <Block
          marginTop={1.5}
        >
          <InputSelect
            key="activityType"
            label="Вид деятельности"
            value={activityType}
            options={dictModel.dict.employment_activities}
            onChange={questionaryModel.setField('activityType')}
            error={questionaryModel.validActivityType}
            tabIndex={1}
            isShowForceValidate={isExistOtherDataStep || employmentValidationEnabled}
            onBlur={questionaryModel.enableIsChangeOtherDataThree}
            htmlID={`${OTHER_DATA_HTML_ID}-activityType`}
            dropdownStyle={{
              minWidth: '800px'
            }}
          />
        </Block>
        <Block
          marginTop={1.5}
        >
          <InputSelect
            key="activityVector"
            label="Направление деятельности"
            value={activityVector}
            options={dictModel.dict.employment_activity_lines}
            onChange={questionaryModel.setField('activityVector')}
            error={questionaryModel.validActivityVector}
            tabIndex={1}
            isShowForceValidate={isExistOtherDataStep || employmentValidationEnabled}
            onBlur={questionaryModel.enableIsChangeOtherDataThree}
            htmlID={`${OTHER_DATA_HTML_ID}-activityVector`}
            dropdownStyle={{
              minWidth: '800px'
            }}
          />
        </Block>
        <Block
          width={!isTabletAndPhone ? 16 : '100%'}
          marginTop={1.5}
        >
          <Input
            key="position"
            label="Должность"
            value={position}
            onChange={questionaryModel.setTrimField('position')}
            error={questionaryModel.validPosition}
            tabIndex={1}
            isShowForceValidate={isExistOtherDataStep || employmentValidationEnabled}
            onBlur={questionaryModel.enableIsChangeOtherDataThree}
            htmlID={`${OTHER_DATA_HTML_ID}-position`}
          />
        </Block>
        <Block
          width={!isTabletAndPhone ? 16 : '100%'}
          marginTop={1.5}
        >
          <Input
            key="workPhone"
            label="Рабочий телефон"
            value={workPhone}
            onChange={questionaryModel.setField('workPhone')}
            error={questionaryModel.validWorkPhone}
            help={<Fragment>
              <div>Должен отличаться от</div>
              <div>+7 {phoneNumberNormalizer(questionaryModel.mobilePhone)}</div>
            </Fragment>}
            prefix={<Text size={16} isRem={false} type="text">+7 </Text>}
            mask="(999) 999-9999"
            unmask={v => v.replace(/[\s()-]*/gi, '')}
            tabIndex={1}
            isShowForceValidate={isExistOtherDataStep || employmentValidationEnabled}
            onBlur={questionaryModel.enableIsChangeOtherDataThree}
            htmlID={`${OTHER_DATA_HTML_ID}-workPhone`}
            type="number"
          />
        </Block>
        <Block
          width={!isTabletAndPhone ? 16 : '100%'}
          marginTop={3}
        >
          <Input
            key="workEmail"
            label="Рабочий e-mail"
            value={workEmail}
            onChange={questionaryModel.setField('workEmail')}
            error={questionaryModel.validWorkEmail}
            tabIndex={1}
            isShowForceValidate={isExistOtherDataStep || employmentValidationEnabled}
            onBlur={questionaryModel.enableIsChangeOtherDataThree}
            htmlID={`${OTHER_DATA_HTML_ID}-workEmail`}
            type="email"
          />
        </Block>
        <Block width={!isTabletAndPhone ? 16 : '100%'} marginTop={1.5}>
          <DatePicker
            key="workBeginDate"
            label="Дата приема на работу"
            tabIndex={1}
            value={workBeginDate}
            onChange={questionaryModel.setField('workBeginDate')}
            error={questionaryModel.validWorkBeginDate}
            isShowForceValidate={isExistOtherDataStep || employmentValidationEnabled}
            onBlur={questionaryModel.enableIsChangeOtherDataThree}
            htmlID={`${OTHER_DATA_HTML_ID}-workBeginDate`}
            mode="month"
            isReverseYear
          />
        </Block>
      </Fragment> : (employmentType && <Fragment>
          <Block
            width={!isTabletAndPhone ? 16 : '100%'}
            marginTop={1.5}
          >
            <Input
              key="contactPhone"
              label="Домашний телефон"
              value={contactPhone}
              onChange={questionaryModel.setField('contactPhone')}
              error={questionaryModel.validContactPhone}
              help={<Fragment>
                <div>Должен отличаться от</div>
                <div>+7 {phoneNumberNormalizer(questionaryModel.mobilePhone)}</div>
              </Fragment>}
              prefix={<Text size={16} isRem={false} type="text">+7 </Text>}
              mask="(999) 999-9999"
              unmask={v => v.replace(/[\s()-]*/gi, '')}
              tabIndex={1}
              isShowForceValidate={isExistOtherDataStep || employmentValidationEnabled}
              onBlur={questionaryModel.enableIsChangeOtherDataThree}
              htmlID={`${OTHER_DATA_HTML_ID}-workPhone`}
              type="number"
            />
          </Block>
        </Fragment>)
      }
    </Fragment>
  )
}

export default inject(store => ({
  questionaryModel: store.questionaryModel,
  dictModel: store.dictModel,
  responsiveModel: store.responsiveModel
}))(observer(Employment))
