import { observable, action } from 'mobx'
import BaseModel from '../baseModel'

class GlobalPreloaderModel extends BaseModel {
  @observable isVisible = true
  @observable isOverflow = true
  @observable title = 'Загрузка информации о товарах'

  @action setVisible = (isVisible, isOverflow = true) => {
    this.isVisible = isVisible
    this.isOverflow = !isVisible ? false : isOverflow
  }

  @action setTitle = title => {
    this.title = title
    this.isOverflow = false
  }
}

export { GlobalPreloaderModel }
export default new GlobalPreloaderModel()
