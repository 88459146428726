export const addressMap = [
  {
    frontPath: 'id',
    backPath: ['fias_id']
  },
  {
    frontPath: 'value',
    backPath: ['full']
  },
  {
    frontPath: 'fiasCode',
    backPath: ['fias_code']
  },
  {
    frontPath: 'postCode',
    backPath: ['post_code']
  },
  {
    frontPath: 'country',
    backPath: ['country']
  },
  {
    frontPath: 'region',
    backPath: ['region']
  },
  {
    frontPath: 'regionType',
    backPath: ['region_type']
  },
  {
    frontPath: 'regionCode',
    backPath: ['region_code']
  },
  {
    frontPath: 'city',
    backPath: ['city']
  },
  {
    frontPath: 'cityType',
    backPath: ['city_type']
  },
  {
    frontPath: 'cityDistrict',
    backPath: ['city_district']
  },
  {
    frontPath: 'cityDistrictType',
    backPath: ['city_district_type']
  },
  {
    frontPath: 'district',
    backPath: ['district']
  },
  {
    frontPath: 'districtType',
    backPath: ['district_type']
  },
  {
    frontPath: 'locality',
    backPath: ['locality']
  },
  {
    frontPath: 'localityType',
    backPath: ['locality_type']
  },
  {
    frontPath: 'street',
    backPath: ['street']
  },
  {
    frontPath: 'streetType',
    backPath: ['street_type']
  },
  {
    frontPath: 'blockNumber',
    backPath: ['block_number']
  },
  {
    frontPath: 'blockType',
    backPath: ['block_type']
  },
  {
    frontPath: 'apartmentNumber',
    backPath: ['apartment_number']
  },
  {
    frontPath: 'apartmentType',
    backPath: ['apartment_type']
  },
  {
    frontPath: 'house',
    backPath: ['house']
  },
  {
    frontPath: 'houseType',
    backPath: ['house_type']
  },

  {
    frontPath: 'kladrID',
    backPath: ['kladr_id']
  }
]
