import React from 'react'
import { Block, Modal, Title, Button, Text, Input, Paragraph, Icon } from 'uikit'
import { inject, observer } from 'mobx-react'
import sfinanceLogo from 'resources/logo/logo-sfinance.png'
import styled from 'styled-components'
import ConfirmCodeForm from './ConfirmCodeForm'

const Logo = styled.img`
  width: 10rem !important;
  margin-bottom: 9px;
`

const SmsCode = styled(Input)`
  & input {
    padding-left: 0 !important;
    padding-right: 0 !important;
  } 
`

const HelpModal = ({ helpModalModel, pickupModel }) => {
  const handleCloseMainModal = () => {
    helpModalModel.setIsOpen(false)
  }

  const handleShowDetailsModal = () => {
    helpModalModel.setIsShowDetails(true)
  }

  const handleCloseDetailsModal = () => {
    helpModalModel.setIsShowDetails(false)
  }

  const {
    firstName,
    lastName,
    patronymic,
    mobilePhone,
    confirmCode,

    title,
    subTitle,

    isOpen,
    isSendPhoneCode,
    isConfirmCode,
    confirmKey,
    isSendConfirmKey,
    isSend,
    isLoading,
    isLoadingConfirmCode,
    isShowDetails
  } = helpModalModel

  return (
    <>
      {!isSend &&
      <Modal
        title={(
          <Block verticalPlacement="center" width="100%">
            <Logo src={sfinanceLogo}/>
            <Title level={4}>{title}</Title>
          </Block>
        )}
        isOpen={isOpen}
        onCancel={handleCloseMainModal}
        width="500px"
        footer={
          <Block horizontalPlacement={!isSendPhoneCode ? 'between' : 'center'} verticalPlacement="center" padding={1}>
            {!isSendPhoneCode && !isConfirmCode ?
              <>
                <Button
                  type="primary"
                  onClick={helpModalModel.handleSendConfirmCode}
                  isDisable={mobilePhone.length < 10}
                  isLoading={isSendConfirmKey}
                >
                  Продолжить
                </Button>
                <Text
                  type="primary"
                  onClick={handleShowDetailsModal}
                  style={{
                    borderBottom: '1px dashed',
                    lineHeight: '15px'
                  }}
                >
                  Узнать подробнее
                </Text>
              </> :
              !isConfirmCode ?
                <Block horizontalPlacement="left" marginTop={1}>
                  <Block marginRight={1}>
                    <SmsCode
                      label="Код из СМС"
                      isSmsCode
                      textAlign="center"
                      value={confirmCode}
                      onChange={helpModalModel.setConfirmCode}
                      width={100} isRem={false}
                      isDisable={isLoadingConfirmCode}
                    />
                  </Block>
                  <Button
                    marginTop={1.6}
                    type="primary"
                    onClick={helpModalModel.handleConfirm}
                    isDisable={confirmCode.length < 5 || mobilePhone.length < 10}
                    isLoading={isLoadingConfirmCode}
                  >
                    {!lastName || !firstName ? 'Подтвердить' : 'Отправить заявку'}
                  </Button>
                </Block> :
                <Block isCenter width="100%">
                  <Button
                    type="primary"
                    isDisable={!isConfirmCode || !lastName || !firstName || !mobilePhone}
                    onClick={helpModalModel.handleSend}
                    isLoading={isLoading}
                  >
                    Отправить заявку
                  </Button>
                </Block>
            }
          </Block>
        }
      >
        <Paragraph level={4} marginBottom={2}>
          {subTitle}
        </Paragraph>
        <Block horizontalPlacement="between" marginBottom={1}>
          <Block width="49%">
            <Input
              label="Фамилия"
              value={lastName}
              onChange={helpModalModel.setLastName}
              isDisable={isLoading || isLoadingConfirmCode}
            />
          </Block>
          <Block width="49%">
            <Input
              label="Имя"
              value={firstName}
              onChange={helpModalModel.setFirstName}
              isDisable={isLoading || isLoadingConfirmCode}
            />
           </Block>
        </Block>
        <Input
          label="Отчество"
          value={patronymic}
          onChange={helpModalModel.setPatronymic}
          isDisable={isLoading || isLoadingConfirmCode}
          marginBottom={1}
        />
        <Input
          label="Телефон"
          value={mobilePhone}
          onChange={helpModalModel.setMobilePhone}
          mask="(999) 999-99-99"
          unmask={v => v.replace(/[\s()-]*/gi, '')}
          prefix={<Text size={16} isRem={false} type="text">+7 </Text>}
          postfix={isConfirmCode ? <Icon icon="check" color="success"/> : null}
          isDisable={isLoading || isLoadingConfirmCode}
        />
        {!isConfirmCode ?
          <ConfirmCodeForm
            phone={mobilePhone}
            isSendPhoneCode={isSendPhoneCode}
          /> :
          <Block marginBottom={3} marginTop="-16px">
            <Text>
              Телефонный номер подтвержден.
            </Text>
          </Block>
        }
      </Modal>
      }
      <Modal
        isOpen={isShowDetails}
        onCancel={handleCloseDetailsModal}
        width="500px"
        footer={
          <Block isCenter>
            <Button
              type="primary"
              onClick={handleCloseDetailsModal}
            >
              Закрыть
            </Button>
          </Block>
        }
      >
        <Paragraph level={4} marginTop={2} marginBottom={2}>
          Заявка на кредит/рассрочку рассматривается сразу несколькими кредитными организациями (Почта Банк, Кредит Европа Банк, МТС, Тинькофф, Альфа банк, Совком банк, ОТП, Ренессанс, Быстрые покупки, ЛОКО-банк, МигКредит). После рассмотрения заявки вы сможете выбрать наиболее подходящее для вас предложение.
        </Paragraph>
        <Paragraph level={4} marginBottom={2}>
          Требования к заемщику и параметры кредита:
          <ul>
            <li>Возраст заемщика: от 18 лет</li>
            <li>Сумма кредита: от 3 000 рублей до 1 000 000 рублей</li>
            <li>Срок кредита: от 3 до 36 месяцев</li>
            <li>Переплата по кредиту/рассрочке: рассчитывается индивидуально</li>
          </ul>
        </Paragraph>
        <Paragraph level={4} marginBottom={2}>
          Узнать более подробную информацию по условиям кредитования, а также получить предварительный расчет по кредиту, вы сможете после отправки заявки. В течение 5 минут с вами свяжется кредитный специалист.
        </Paragraph>
      </Modal>
    </>
  )
}

HelpModal.propTypes = {
}

HelpModal.defaultProps = {
}

export default inject(store => ({
  helpModalModel: store.helpModalModel,
  pickupModel: store.pickupModel
}))(observer(HelpModal))
