import React from 'react'
import Menu from 'components/Menu'
import MenuTitle from 'components/MenuTitle'
import GlobalPreloader from 'components/GlobalPreloader'
import {
  Image, Block, Badge, Icon, mountHook, ResponsivePhone, Row, Col, Text,
  ResponsiveDesktop, ResponsiveTablet
} from 'uikit'
import SdkProvider from 'providers/sdk-provider'
import styled, { withTheme } from 'styled-components'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'
import { breakPoints, GlobalStyles } from 'styles'
import ActionCableProvider from 'providers/action-cable-provider'
import HelpModal from 'components/HelpModal'
import Router from '../Router'

const RootContainer = styled(props => {
  const resolveProps = { ...props }
  _.unset(resolveProps, 'isExistMenu')
  return <Block {...props}/>
})`
  min-width: ${props => (props.isExistMenu ? `${breakPoints.tablet}px` : '100%')};
  max-width: ${props => (props.isExistMenu ? `${breakPoints.desktop}px` : '100%')};
  height: 100%;
  position: relative;
`

const RootContainerMobile = styled(props => {
  const resolveProps = { ...props }
  _.unset(resolveProps, 'isExistMenu')
  return <Block {...props}/>
})`
  width: 100%;
  height: 100%;
  position: relative;
`

const ContentContainer = styled(props => {
  const resolveProps = { ...props }
  _.unset(resolveProps, 'isExistMenu')
  _.unset(resolveProps, 'isSelectedOffer')
  return <Block {...props}/>
})`
  margin: ${props => (props.isExistMenu ? '0 0 3rem 288px' : '0 auto')};
  padding: ${props => (props.isExistMenu ? '2rem' : 0)};
  height: ${props => (!props.isExistMenu ? '100%' : 'auto')};
`

const ContentContainerMobile = styled(props => {
  const resolveProps = { ...props }
  _.unset(resolveProps, 'isExistMenu')
  _.unset(resolveProps, 'isSelectedOffer')
  return <Block {...props}/>
})`
  margin: 0;
  width: 100%;
  position: relative;
  padding: ${props => (!props.isSelectedOffer ? '2rem' : '1rem')};
`

const ContentMobileOverflow = styled(Block)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  opacity: 0.35;
  filter: blur(.1rem);
  z-index: 999;
  cursor: default !important;
`

const MenuMobile = styled(Block)`
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 1.5rem;
  height: 1.5rem;
  z-index: 999;
`

const MenuMobileCount = styled(Badge)`
  position: absolute;
  right: -5px;
  top: -8px;
  padding: 0 .3rem;
  border-radius: 19px;
`

const HelpLink = styled(Block)`
  user-select: none;
  
  & * {
    user-select: none;
  }
`

const Container = ({ menuModel, responsiveModel, globalPreloaderModel, applicationModel, pickupModel, helpModalModel }) => {
  mountHook(async () => {
    window.addEventListener('resize', () => {
      responsiveModel.setWidth(window.innerWidth)
    })

    window.addEventListener('scroll', () => {
      responsiveModel.setScrollY(window.scrollY)
    })

    window.addEventListener('orientationchange', () => {
      responsiveModel.setWidth(window.innerWidth)
    })

    SdkProvider.init()
  }, () => {
    ActionCableProvider.disconnect()
  })

  const handleClickMenuMobile = () => {
    menuModel.setOpenMenuMobile(true)
  }

  const handleClickContent = () => {
    menuModel.setOpenMenuMobile(false)
  }

  const { step } = menuModel
  const { isPhone } = responsiveModel
  const { ownerLogo } = pickupModel.order

  const RootComponent = !isPhone ?
    RootContainer : RootContainerMobile
  const ContentComponent = !isPhone && (applicationModel.current ? !responsiveModel.isTabletAndPhone : true) ?
    ContentContainer : ContentContainerMobile

  const countPositive = _.filter(applicationModel.applications, a => a.isPositive).length

  const HelpSdkLink = !(pickupModel.isTargetCallCenter || pickupModel.isCallCenterCall) ? (
      <HelpLink onClick={helpModalModel.applyHelpModalModel}>
        <Icon icon="call" iconType="design" color="darkBlue" marginRight={0.5}/>
        <Text type="darkBlue" isStrong>Отправить заявку в контактный центр</Text>
      </HelpLink>
  ) : null

  return (
    <Block height="100%">
      <GlobalStyles/>
      <GlobalPreloader/>
      <RootComponent
        isExistMenu={step >= 0 && !responsiveModel.isTabletAndPhone}
        style={{
          filter: globalPreloaderModel.isVisible ? 'blur(4px)' : 'none',
          opacity: globalPreloaderModel.isVisible ? !globalPreloaderModel.isOverflow ? 0.4 : 0 : 1,
          overflow: menuModel.isOpenMenuMobile ? 'hidden' : 'initial'
        }}
      >
        {(() => {
          if (step >= 0 && !isPhone) {
            return <Menu/>
          }

          if (step >= 0 && isPhone && menuModel.isOpenMenuMobile) {
            return <Menu isOffCanvas/>
          }
        })()}

        {isPhone && step >= 0 &&
        <MenuMobile
          isCenter
          onClick={handleClickMenuMobile}
          style={{
            visibility: isPhone && !responsiveModel.isExistHeader ? 'hidden' : 'visible'
          }}
        >
          <Icon icon="menu" size={1.5} color="darkBlue"/>
          {countPositive > 0 && menuModel.step !== 4 &&
          <MenuMobileCount
            label={countPositive}
            background="successLight"
            style={{ fontSize: '10px' }}
          />
          }
        </MenuMobile>
        }

        {menuModel.isOpenMenuMobile &&
        <ContentMobileOverflow onClick={handleClickContent}/>
        }
        <ContentComponent
          isExistMenu={step >= 0}
          isOpenMenuMobile={menuModel.isOpenMenuMobile}
          isSelectedOffer={!!applicationModel.current}
        >
          {step >= 0 &&
          <Row isFlex justify={!isPhone ? 'space-between' : 'start'} align="middle">
            <Col>
              <Image src={ownerLogo} width={!responsiveModel.isPhone ? 20 : 8.7} isBlock/>
              <ResponsivePhone>
                <Block marginBottom={1} marginTop={0.5}>
                  {HelpSdkLink}
                </Block>
              </ResponsivePhone>
            </Col>
            <ResponsiveDesktop>
              <Col>
                {HelpSdkLink}
              </Col>
            </ResponsiveDesktop>
            <ResponsiveTablet>
              <Col>
                {HelpSdkLink}
              </Col>
            </ResponsiveTablet>
          </Row>
          }
          {step >= 0 &&
          <ResponsivePhone>
            <MenuTitle/>
          </ResponsivePhone>
          }
          <Block>
            <Router/>
            {helpModalModel.isOpen && <HelpModal/>}
          </Block>
        </ContentComponent>
      </RootComponent>
    </Block>
  )
}

export default withTheme(inject(store => ({
  menuModel: store.menuModel,
  responsiveModel: store.responsiveModel,
  globalPreloaderModel: store.globalPreloaderModel,
  applicationModel: store.applicationModel,
  pickupModel: store.pickupModel,
  helpModalModel: store.helpModalModel
}))(observer(Container)))
