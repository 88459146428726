import 'uikit/lib/app.css'
import { colors, viewPortsResolve } from 'uikit'
import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    font-size: 14px;
    width: 100%;
    height: 100%;
    color: ${colors.text};
    background: #fff;
    font-family: 'Plumb', sans-serif;
    -webkit-touch-callout: none !important;
  }

  * {
    box-sizing: border-box;
  }

  #app {
    width: 100%;
    height: 100%;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
  
  a:hover, &:visited, &:active, &:focus {
    color: inherit;
    text-decoration: none;
  }
`

const breakPoints = {
  mobile: 768,
  tablet: 968,
  desktop: 1280,
  largeDesktop: 1280
}

const viewPorts = viewPortsResolve(breakPoints)

const theme = {
  colors,
  breakPoints,
  viewPorts
}

export { colors, breakPoints, viewPorts, theme, GlobalStyles }
