import _ from 'lodash'
import fp from 'lodash/fp'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { InputSelect, withMargin, safeObject, isSafe } from 'uikit'
import DadataProvider from 'providers/dadata-provider'
import validator from 'validator'

const handleSearch = _.debounce(async (v, onChangeOptions, onChangeIsLoading) => {
  if (v) {
    try {
      onChangeIsLoading(true)
      const addresses = await DadataProvider.getAddress(v)
      onChangeOptions(addresses)
    } finally {
      onChangeIsLoading(false)
    }
  }
}, 450)

const AddressInput = ({
  className, style, htmlID,

  tabIndex, size, isDisable, placeholder,
  onFocus, onBlur, onChangeIsInput, onChangeIsFocus,

  error = null, warn = null, label = null, help = null, isShowForceValidate = false,

  value, onChange
}) => {
  const [currentValue, setCurrentValue] = useState(null)
  const [inputText, setInputText] = useState('')
  const [addresses, setAddresses] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    (async () => {
      if (value !== '' && isSafe(value)) {
        setCurrentValue(value)

        try {
          if ((isSafe(value) && value.id !== _.get(currentValue, 'id', '') &&
            value.id !== currentValue) ||
            _.isEmpty(addresses)) {
            setIsLoading(true)
            const data = await DadataProvider.getAddress(value.value)
            setAddresses(data)
          }
        } finally {
          setInputText(value.value)
          setIsLoading(false)
        }
      } else {
        setInputText('')
        setCurrentValue(null)
      }
    })()
  }, [value])

  const handleChange = v => {
    setCurrentValue(v)
    const resolveValue = _.find(addresses, a => a.id === v)
    if (onChange) onChange(resolveValue)
  }

  const handleChangeText = (v) => {
    setInputText(v)
    if (inputText !== v && !!v) {
      handleSearch(v, setAddresses, setIsLoading)
    }
  }

  return (
    <InputSelect
        className={className}
        style={style}
        options={fp.map(a => ({
          value: a.id,
          label: a.value
        }))(addresses)}
        onChangeText={handleChangeText}
        onChange={handleChange}
        isForceOpen
        isFilterOption={false}
        tabIndex={tabIndex}
        size={size}
        isDisable={isDisable}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        onChangeIsInput={onChangeIsInput}
        onChangeIsFocus={onChangeIsFocus}
        isLoading={isLoading}
        normalizer={v => (validator.isUUID(v) ? '' : v)}
        htmlID={htmlID}
        label={label}
        error={error}
        warn={warn}
        help={help}
        isShowForceValidate={isShowForceValidate}
        {...safeObject({
          value: _.get(currentValue, 'id', null),
          inputValue: inputText || null
        })}
        dropdownStyle={{
          minWidth: '800px'
        }}
    />
  )
}

AddressInput.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  htmlID: PropTypes.string,

  value: PropTypes.object,
  size: PropTypes.oneOf(['xs', 'sm', 'lg']),
  tabIndex: PropTypes.any,
  isDisable: PropTypes.bool,
  placeholder: PropTypes.string,

  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,

  // withFormProps
  onChangeIsInput: PropTypes.func,
  onChangeIsFocus: PropTypes.func
}

AddressInput.defaultProps = {
  value: null,
  placeholder: '',
  isDisable: false,
  size: 'lg',
  tabIndex: null
}

export default withMargin({ displayName: 'AddressInput' })(AddressInput)
