import _ from 'lodash'
import StorageProvider from 'providers/storage-provider'
import pickupModel from 'models/pickupModel'
import ConfigProvider from '../config-provider'

class SdkProvider {
  sdk = null
  token = ConfigProvider.config.sdkToken

  async init() {
    window.onLoadposbrokerSDK = () => {
      this.sdk = window.posbrokerSDK
      this.sdk.init({
        token: this.token,
        pointID: pickupModel.order.pointID,
        goods: _.map(pickupModel.order.orders, or => ({
          name: or.name,
          count: or.count,
          price: or.cost,
          category: or.category,
          manufacturer: or.manufacturer
        })),
        isCloseCallBack: true,
        poslineCallFlag: true
      })
    }

    const sdkJsScript = document.createElement('script')
    sdkJsScript.src = ConfigProvider.config.sdkURI
    document.body.appendChild(sdkJsScript)
  }

  updatePointID(pointID) {
    if (!this.sdk) return

    this.sdk.updateConfig({ point_id: pointID })
  }

  updateGoods(goods) {
    if (!this.sdk) return

    this.sdk.updateConfig({ goods })
  }

  updateLastName(lastName) {
    if (!this.sdk) return

    this.sdk.updateConfig({ lastName })
  }

  updateFirstName(firstName) {
    if (!this.sdk) return

    this.sdk.updateConfig({ firstName })
  }

  updatePatronymic(patronymic) {
    if (!this.sdk) return

    this.sdk.updateConfig({ patronymic })
  }

  updatePhone(phone) {
    if (!this.sdk) return

    this.sdk.updateConfig({ phone })
  }

  setConfirm(confirmKey) {
    if (!this.sdk) return

    this.sdk.updateConfig({ confirmKey })
  }

  openHelp(orderId) {
    if (!this.sdk) return
    this.sdk.updateConfig({
      onClose: () => { pickupModel.setCallCenterCall() },
      poslineCallFlag: true,
      poslineOrderId: orderId
    })
    this.sdk.openHelpModal()
  }
}

export default new SdkProvider()
